import React from "react";

function Card(props) {
  return (
    <div className="card mb-4">
      {props.title ? (
        <div className="card-header">
          <h6 className="b mb-0">{props.title}</h6>
        </div>
      ) : (
        ""
      )}
      <div className="card-body">{props.children}</div>
    </div>
  );
}
export default Card;
