import {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAIL
} from '../constants/countriesConstants';

export const countriesReducer = (state = [], action) => {
  switch (action.type) {
    case COUNTRIES_REQUEST:
      return {
        loading: true
      };
    case COUNTRIES_SUCCESS:
      return {
        loading: false,
        countries: action.payload
      };
    case COUNTRIES_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
