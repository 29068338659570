const iState = {
    name: "ramesh",
    wishes: ['eat', 'code']
}

const reducer = (state = iState, action) => {
    switch (action.type) {
        case 'CATEGORIES_ADD':
            {
                return !state;
            }
        default:
            return state;
    }
}

export default reducer;
