import React, { useState } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic } from '@fortawesome/free-solid-svg-icons'
import { EVENT_CATEGORIES_API } from '../../APIConfig'
import { Link } from 'react-router-dom'

function CategoryManu(props) {
  const [categoryData, setcategoryData] = useState([])
  React.useEffect(() => getcategory(), [])

  const getcategory = async () => {
    try {
      await axios({
        url: EVENT_CATEGORIES_API,
        method: 'GET'
      }).then(function (res) {
        setcategoryData(res.data.data)
        // useDispatch(props.BindCategory)
        props.BindCategory.FillCategory.ADD_CATEGORY(res.data.data)
      })
    } catch {}
  }

  return props.BindCategory.FillCategory.StoreInfo.CategoryData.map(
    (cat, index) => (
      <Link
        key={cat.id}
        to={`/event-list/${cat.slug}`}
        className={
          props.active === 'dashboard' ? 'dropdown-item active' : 'dropdown-item'
        }
      >
        <FontAwesomeIcon icon={faMusic} className='fa-fw' />
        {cat.name}
      </Link>
    )
  )
}
export default CategoryManu
