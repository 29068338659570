import {
  faFolderOpen,
  faHeart,
  faShoppingCart,
  faStar,
  faTicketAlt,
  faUserCog
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Collapsible from '../../../ui/Collapsible';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const userInfo = localStorage.getItem('user');
const userData = JSON.parse(userInfo);
function Sidebar(props) {
const cartItems = useSelector(state => state.cart.cartItems);
const getdata=()=>{
  let sum=0;
if(cartItems) 
{
cartItems.forEach(element => {
  sum+=element.ticket_qty;
});
}
return sum;
} 
const {t, i18n} = useTranslation('common')
  return (
    <aside className='col-lg-3 pt-3 pt-lg-0  d-lg-block d-xl-block'>
      <div className='sticky-top sticky-sidebar dashboard-sidebar'>
        <ul
          id='dashboard-menu'
          className='nav nav-pills nav-pills-vertical-styled overflow-auto'>
          {userInfo && (
            <li className='nav-item'>
              <Link
                to='/dashboard/attendee/my-tickets'
                className={
                  props.active === 'my-tickets' ? 'nav-link active' : 'nav-link'
                }>
                <FontAwesomeIcon icon={faTicketAlt} className='fa-fw mr-2' />
                {t('AttendeeDashoboardPage.MyTickets')}
              </Link>
            </li>
          )}
          <li className='nav-item'>
            <Link
              to='/dashboard/attendee/cart'
              className={
                props.active === 'my-cart' ? 'nav-link active' : 'nav-link'
              }>
              <FontAwesomeIcon icon={faShoppingCart} className='fa-fw mr-2' />
              {t('AttendeeDashoboardPage.MyCart')}
              {cartItems ? (
                <span className='badge badge-info float-right'>
                  {getdata()}
                </span>
              ) : (
                ''
              )}
            </Link>
          </li>
          {userInfo && (
            <li className='nav-item'>
              <Link
                to='/dashboard/attendee/my-favorites'
                className={
                  props.active === 'my-favorites'
                    ? 'nav-link active'
                    : 'nav-link'
                }>
                <FontAwesomeIcon icon={faHeart} className='fa-fw mr-2' />
                {t('AttendeeDashoboardPage.MyFavorites')}
              </Link>
            </li>
          )}
          {userInfo && (
            <li className='nav-item'>
              <Link
                to='/dashboard/attendee/my-reviews'
                className={
                  props.active === 'my-reviews' ? 'nav-link active' : 'nav-link'
                }>
                <FontAwesomeIcon icon={faStar} className='fa-fw mr-2' />
                {t('AttendeeDashoboardPage.MyReviews')}
              </Link>
            </li>
          )}
          {/* <li className='nav-item'>
            <Link
              to='/dashboard/attendee/following'
              className={
                props.active === 'following' ? 'nav-link active' : 'nav-link'
              }
            >
              <FontAwesomeIcon icon={faFolderOpen} className='fa-fw mr-2' />
              Following
            </Link>
          </li> */}
          {userData &&
            userData.user_detail &&
            userData.user_detail.role_id === 2 && (
              <li className='nav-item'>
                <Collapsible
                  openDefault={
                    props.active === 'account-settings' ||
                    props.active === 'change-password'
                  }
                  className='nav-link'
                  title={
                    <>
                      <FontAwesomeIcon
                        icon={faUserCog}
                        className='fa-fw mr-2'
                      />
                      {t('Account')}
                    </>
                  }>
                  <ul className={`nav flex-column subitems`}>
                    <li className='nav-item'>
                      <Link
                        to='/dashboard/attendee/settings'
                        className={
                          props.active === 'account-settings'
                            ? 'nav-link active'
                            : 'nav-link'
                        }>
                        {t('Settings')}
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        to='/dashboard/attendee/change-password'
                        className={
                          props.active === 'change-password'
                            ? 'nav-link active'
                            : 'nav-link'
                        }>
                        {t('ChangePassword')}
                      </Link>
                    </li>
                  </ul>
                </Collapsible>
              </li>
            )}
        </ul>
      </div>
    </aside>
  );
}

export default Sidebar;
