import {
  faCalendar,
  faIdCard,
  faQrcode,
  faTachometerAlt,
  faUserCog,
  faChartLine,
  faList,
  faTags,
  faTicketAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import Collapsible from '../../../ui/Collapsible';
import { useTranslation } from 'react-i18next';

function Sidebar(props) {
  const {t, i18n} = useTranslation('common');
  return (
    <aside className='col-lg-3 pt-3 pt-lg-0 d-lg-block d-xl-block'>
      <div className='sticky-top sticky-sidebar dashboard-sidebar'>
        <ul
          id='dashboard-menu'
          className='nav nav-pills nav-pills-vertical-styled overflow-auto'
          style={{ maxHeight: '45rem' }}>
          <li className='nav-item'>
            <Link
              to='/dashboard/organizer/'
              className={
                props.active === 'dashboard' ? 'nav-link active' : 'nav-link'
              }>
              <FontAwesomeIcon icon={faTachometerAlt} className='fa-fw mr-2' />
              {t('DashboardPage.DashBoard')}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/dashboard/organizer/bookings'
              className={
                props.active === 'bookings' ? 'nav-link active' : 'nav-link'
              }>
              <FontAwesomeIcon icon={faTicketAlt} className='fa-fw mr-2' />
              {t('DashboardPage.Bookings')}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/dashboard/organizer/profile'
              className={
                props.active === 'edit-your-organizer-profile'
                  ? 'nav-link active'
                  : 'nav-link'
              }>
              <FontAwesomeIcon icon={faIdCard} className='fa-fw mr-2' />
              {t('DashboardPage.MyOrgProfile')}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/dashboard/organizer/top-events'
              className={
                props.active === 'top-events' ? 'nav-link active' : 'nav-link'
              }>
              <FontAwesomeIcon icon={faCalendar} className='fa-fw mr-2' />
              {t('DashboardPage.Top10Events')}
            </Link>
          </li>

          <li className='nav-item'>
            <Link
              to='/dashboard/organizer/free-ticket'
              className={
                props.active === 'free-tickets' ? 'nav-link active' : 'nav-link'
              }>
              <FontAwesomeIcon icon={faTicketAlt} className='fa-fw mr-2' />
              {t('DashboardPage.FreeTickets')}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/dashboard/organizer/my-events'
              className={
                (props.active === 'my-events' ||
                props.active === 'add-a-new-event') && 
                !window.location.pathname.includes("my-events-upcoming")
                  ? 'nav-link active'
                  : 'nav-link'
              }>
              <FontAwesomeIcon icon={faCalendar} className='fa-fw mr-2' />
              {t('DashboardPage.MyEvents')}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/dashboard/organizer/my-upcoming-events'
              className={
                props.active === 'my-upcoming-events' ||
                window.location.pathname.includes("my-events-upcoming")
                  ? 'nav-link active'
                  : 'nav-link'
              }>
              <FontAwesomeIcon icon={faCalendar} className='fa-fw mr-2' />
              {t('DashboardPage.UpcomingEvents')}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/dashboard/organizer/booked-tickets'
              className={
                props.active === 'booked-tickets'
                  ? 'nav-link active'
                  : 'nav-link'
              }>
              <FontAwesomeIcon icon={faCalendar} className='fa-fw mr-2' />
              {t('BookedTickets')}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/dashboard/organizer/coupons'
              className={
                props.active === 'coupons' ? 'nav-link active' : 'nav-link'
              }>
              <FontAwesomeIcon icon={faTags} className='fa-fw mr-2' />
              {t('DashboardPage.Coupons')}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/dashboard/organizer/masscoupons'
              className={
                props.active === 'masscoupons' ? 'nav-link active' : 'nav-link'
              }>
              <FontAwesomeIcon icon={faTags} className='fa-fw mr-2' />
              {t('DashboardPage.MassCoupons')}
            </Link>
          </li>
          
            <li className='nav-item'>
            <Collapsible
              openDefault={props.active === 'general-template' 
              || props.active === 'event-template'}
              className='nav-link'
              title={
                <>
                  <FontAwesomeIcon icon={faList} className='fa-fw mr-2' />
                  {t('DashboardPage.emailtemplates')}
                </>
              }>
              <ul className='nav flex-column subitems'>
                <li className='nav-item'>
                  <Link
                    to='/dashboard/organizer/general-template'
                    className={
                      props.active === 'general-template'
                        ? 'nav-link active'
                        : 'nav-link'
                    }>
                    {t('DashboardPage.generaltemplates')}
                  </Link>
                </li>
              </ul>
              <ul className='nav flex-column subitems'>
                <li className='nav-item'>
                  <Link
                    to='/dashboard/organizer/event-template'
                    className={
                      props.active === 'event-template'
                        ? 'nav-link active'
                        : 'nav-link'
                    }>
                    {t('DashboardPage.eventtemplates')}
                  </Link>
                </li>
              </ul>
            </Collapsible>
          </li>
         
           
          <li className='nav-item'>
            <Link
              to='/dashboard/organizer/ticketsuppliments'
              className={
                props.active === 'ticketsuppliments' ? 'nav-link active' : 'nav-link'
              }>
              <FontAwesomeIcon icon={faTicketAlt} className='fa-fw mr-2' />
              {t('TicketSuppliments')}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/dashboard/organizer/voucher'
              className={
                props.active === 'voucher' ? 'nav-link active' : 'nav-link'
              }>
              <FontAwesomeIcon icon={faTags} className='fa-fw mr-2' />
              {t('DashboardPage.Voucher')}
            </Link>
          </li>
         
          <li className='nav-item'>
            <Collapsible
              openDefault={props.active === 'sales-graph' 
              || props.active === 'donut-graph'
              || props.active === 'region'
              || props.active === 'scan-detail'
              || props.active === 'ticket-detail'}
              className='nav-link'
              title={
                <>
                  <FontAwesomeIcon icon={faChartLine} className='fa-fw mr-2' />
                  {t('DashboardPage.Reporting')}
                </>
              }>
              <ul className='nav flex-column subitems'>
                <li className='nav-item'>
                  <Link
                    to='/dashboard/organizer/sales-graph'
                    className={
                      props.active === 'sales-graph'
                        ? 'nav-link active'
                        : 'nav-link'
                    }>
                    <FontAwesomeIcon icon={faChartLine} className='fa-fw mr-2' />
                    {t('DashboardPage.SalesGraph')}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/dashboard/organizer/donut-graph'
                    className={
                      props.active === 'donut-graph'
                        ? 'nav-link active'
                        : 'nav-link'
                    }>
                      <FontAwesomeIcon icon={faChartLine} className='fa-fw mr-2' />
                    {t('DashboardPage.DonutGraph')}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/dashboard/organizer/region'
                    className={
                      props.active === 'region'
                        ? 'nav-link active'
                        : 'nav-link'
                    }>
                      <FontAwesomeIcon icon={faChartLine} className='fa-fw mr-2' />
                    {t('DashboardPage.Region')}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/dashboard/organizer/scan-detail'
                    className={
                      props.active === 'scan-detail'
                        ? 'nav-link active'
                        : 'nav-link'
                    }>
                      <FontAwesomeIcon icon={faChartLine} className='fa-fw mr-2' />
                    {t('DashboardPage.ScanDetail')}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/dashboard/organizer/ticket-detail'
                    className={
                      props.active === 'ticket-detail'
                        ? 'nav-link active'
                        : 'nav-link'
                    }>
                      <FontAwesomeIcon icon={faChartLine} className='fa-fw mr-2' />
                    {t('DashboardPage.TicketDetail')}
                  </Link>
                </li>
              </ul>
            </Collapsible>
          </li>
          <li className='nav-item'>
            <Collapsible
              openDefault={props.active === 'change-password'}
              className='nav-link'
              title={
                <>
                  <FontAwesomeIcon icon={faUserCog} className='fa-fw mr-2' />
                  {t('DashboardPage.Account')}
                </>
              }>
              <ul className='nav flex-column subitems'>
                <li className='nav-item'>
                  <Link
                    to='/dashboard/organizer/change-password'
                    className={
                      props.active === 'change-password'
                        ? 'nav-link active'
                        : 'nav-link'
                    }>
                    {t('DashboardPage.ChangePassword')}
                  </Link>
                </li>
              </ul>
            </Collapsible>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default Sidebar;
