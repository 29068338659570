import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
function Alert(props) {
  return (
    <p
      className={`alert alert-icon mb-3 ${
        props.type === "error"
          ? "alert-danger"
          : props.type === "success"
          ? "alert-success"
          : props.type === "warning"
          ? "alert-warning"
          : "alert-info"
      } `}
    >
      <FontAwesomeIcon icon={props.icon ? props.icon : faExclamationCircle} />
      {props.children}
    </p>
  );
}

export default Alert;
