import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../sections/ui/Breadcrumbs";
import Card from "../../sections/ui/Card";
import LabeledInput from "../../sections/ui/formfields/LabeledInput";
import axios from 'axios';
import parse from 'html-react-parser';
import { GET_FRONTEND_PAGES } from '../../APIConfig';

const PrivacyPolicy = () => {

    const [policydata, setpolicydata] = useState([]);

    useEffect(() => {
        getdata();
    }, [])

    const getdata = async () => {
        try {
            axios.get(GET_FRONTEND_PAGES).then(response => {
                let obj = response.data.data.find(o => o.slug === 'privacy-policy');
                setpolicydata(obj);
            })
        } catch {

        }
    }

    return (
        <>
            <Breadcrumbs active={"Privacy Policy"} />
            <section className="section-content padding-y bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 order-0 order-lg-1">
                            <div className="card box">
                                <div className="card-body">

                                    {parse(`${policydata.body}`)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}



export default PrivacyPolicy;