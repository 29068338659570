import React from "react";

function DashItem(props) {
  return (
    <>
      <div
        className={`col-md-${
          props.size ? props.size : "3"
        } col-sm-6 col-12 p-1`}
      >
        <div className="bg-primary rounded p-4 text-center text-white position-relative counter-box">
          {props.icon}
          <h4 className="mb-3">{props.val}</h4>
          <h5 className="mb-0">{props.title}</h5>
        </div>
      </div>
    </>
  );
}
export default DashItem;
