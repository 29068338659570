import { faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import TicketCategory from "./TicketCategory";
import Alert from '../../../sections/ui/Alert';

function Ticket(props) {
  const {tickets} = props
  return (
    <div className="ticketDivdr">
      <h6 className="b notranslate">{props.title}</h6>
      <p className="text-sm mb-1 text-muted ">
        <FontAwesomeIcon icon={faClock} className="fa-fw" />
        {props.date}
      </p>

      <p className="text-sm text-muted notranslate">
        <FontAwesomeIcon icon={faMapMarkerAlt} className="fa-fw" />
        {props.venue}
      </p>
      <div className="left-margin">
        {tickets.length > 0 && tickets.map(ticket => (
          <TicketCategory {...props} ticket={ticket} key={ticket.id} />
        ))}
        {tickets.length <= 0 && <Alert type='warning'>Tickets are coming soon for this event</Alert>}
        {/* <TicketCategory {...props} /> */}
      </div>
    </div>
  );
}
export default Ticket;
