import { faCopy, faEdit, faTrash, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { API_DOMAIN } from '../../../../Config';
import { useAlert } from 'react-alert';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

const TicketItem = ({ ticket, editTicketForm, deleteTicket, setReloadTickets, headers }) => {
  const { t, i18n } = useTranslation('common');

  const alert = useAlert();
  const createDuplicateHandler = async id => {
    if (window.confirm(t('DuplicateThisTicket'))) {
      try {
        await axios.post(
          `${API_DOMAIN}api/myevents/duplicate-ticket`,
          {
            ticket_id: id
          },
          { headers }
        );
        alert.success(t('TicketDuplicated'));
        setReloadTickets(true);
      } catch (error) {
        alert.error(t('TryAgain'));
      }
    }
  };

  return (
    <tr>
      <td>
        <span
          className='text-muted'
          style={{ cursor: 'pointer' }}
          onClick={() => editTicketForm(ticket)}
          title={t('EditTicket')}>
          <FontAwesomeIcon icon={faEdit} />
        </span>
        {ticket.booked_ticket==0 ? <span
          className='text-muted ml-2'
          style={{ cursor: 'pointer' }}
          onClick={() => deleteTicket(ticket.event_id, ticket.id)}
          title={t('DeleteTicket')}>
          <FontAwesomeIcon icon={faTrash} color='red' />
        </span>
                :
                ''
              }
        
        <span
          className='text-muted ml-2'
          style={{ cursor: 'pointer' }}
          onClick={e => createDuplicateHandler(ticket.id)}>
          <FontAwesomeIcon icon={faCopy} title={t('DuplicateTicket')} />
        </span>
      </td>
      <td>{ticket.featured ? <span
                className='text-muted'>
                <FontAwesomeIcon icon={faStar} />
              </span>
                :
                ''
              }</td>
      {/* <td>{ticket.featured ? t('Featured') : t('NotFeatured')}</td> */}
      <td>{ticket.title}</td>
      <td>€{ticket.price.replace('.', ',')}</td>
      <td>{ticket.quantity}</td>
      <td>{ticket.booked_ticket}</td>
      <td>{ticket.ticket_limit === null ? 'Not Set' : ticket.ticket_limit}</td>
      {/* <td>{parse(`${ticket.description}`)}</td> */}
      <th>{ticket.order}</th>
      <td>{ticket.status === 0 ? t('Inactive') : t('Active')}</td>

    </tr>
  );
};

export default TicketItem;
