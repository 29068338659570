import MercItemCheckout from '../../../sections/my-account/attendee/cart/MercItemCheckout'
import Card from '../../../sections/ui/Card'

const MercCheckoutInfo = ({ hide }) => {
  if (hide) {
    return <></>
  }
  return (
    <Card title='Merchandise Summary'>
      <div className='pb-3 px-3 pt-1'>
        <div className='row no-gutters'>
          <div className='col-12 col-md-7'>
            <p className='text-muted font-weight-bold m-0'>
              Merchandise &nbsp;&nbsp;&nbsp; Name
            </p>
          </div>
          <div className='col-12 col-md-5 d-none d-md-block'>
            <div className='d-flex justify-content-between'>
              <p className='text-muted font-weight-bold m-0'>Price</p>
              <p className='text-muted font-weight-bold m-0'>Quantity</p>
              <p className='text-muted font-weight-bold m-0'>Size</p>
              <p className='text-muted font-weight-bold m-0'>Subtotal</p>
            </div>
          </div>
        </div>
      </div>
      <MercItemCheckout
        title='DevOps Training Tee'
        image='/assets/uploads/merchandise/dummy-shirt.jpg'
        price='20'
        quantity='3'
        size='xs'
      />
      <MercItemCheckout
        title='N95 Mask'
        image='/assets/uploads/merchandise/mask.jpg'
        price='5'
        quantity='3'
        size='lg'
      />
    </Card>
  )
}

export default MercCheckoutInfo
