import {
  // faCalendar,
  faHome,
  faMailBulk
  // faMapMarkedAlt,
  // faSearch
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useCallback } from 'react';
import Events from '../../../sections/home/UpcomingEvents/UpcomingEvents';
import FilterCollapsible from '../../../sections/ui/FilterCollapsible';
import CustomSelect from '../../../sections/ui/formfields/CustomSelect';
import LabeledInput from '../../../sections/ui/formfields/LabeledInput';
// import countries from '../../../sections/ui/countries'
import ReactTooltip from 'react-tooltip';
// import Nouislider from 'nouislider-react'
import 'nouislider/distribute/nouislider.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateInput from '../../../sections/ui/formfields/LabededDateField';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import axios from 'axios';
import {
  EVENTS_ALL_API
  // CATEGORY_API
} from '../../../APIConfig';
import CustomLoader from '../../../sections/ui/CustomLoader';
import { useSelector } from 'react-redux';
import { API_DOMAIN } from '../../../Config';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import {useTranslation} from "react-i18next"; 

export default function EventList({ match, ...props }) {
  // const [ticketPrice, setTicketPrice] = useState(true)
  // const [sliderValues, setSliderVal] = useState(['0', '10000'])
  const {t, i18n} = useTranslation('common');
  const [eventData, setEventData] = useState([]);
  // const [Category_Id, setCategoryID] = useState(0)
  const [fromDate, setfromDate] = useState('');
  const [toDate, settoDate] = useState('');
  const [loading, setLoading] = useState(false);
  // filters =============
  const [startDate, setStartDate] = useState();
  const [filterSearch, setFilterSearch] = useState('');
  const [ticketType, setTicketType] = useState(false);
  const [category, setCategory] = useState({ label: 'All', value: 0 });
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);

  const [audience, setAudience] = useState();

  const history = useHistory();
  // end filters =========
  // get all the categories from the redux store
  const storeCategories = useSelector(state => state.StoreInfo.CategoryData);
  // setting countries for selectbox
  // const countriesOptions = countries.map(country => ({
  //   ...country,
  //   value: country.code
  // }))
  // categories array for select box
  const categories = storeCategories.map(function (category) {
    return { label: category.name, value: category.id };
  });
  const url = "//xxxx.us13.list-manage.com/subscribe/post?u=zefzefzef&id=fnfgn";
  const getEvents = useCallback(
    async (catId, search, country, ticketType, startDate, audience, endDate) => {
      setLoading(true);
      try {
        const { data } = await axios({
          url: `${EVENTS_ALL_API}?limit=100&event_sort=start_date&category_id=${catId}${search ? `&search=${search}` : ''
            }${country ? `&country_id=${country}` : ''}${ticketType ? `&price=free` : ''
            }${startDate ? `&start_date=${startDate}` : ''}${ticketType ? `&price=free` : ''
            }${audience ? `&audience=${audience}` : ''}${endDate ? `&end_date=${endDate}` : ''}
        `,
          method: 'GET'
        });
        setEventData(data);
      } catch (err) {
      }
      setLoading(false);
    },
    []
  );

  useEffect(() => {
    const getCountries = async () => {
      //setLoading(true);
      try {
        const { data } = await axios.get(`${API_DOMAIN}api/countries`);
        const cont = data.data.map(country => ({
          label: country.country_name,
          value: country.id
        }));

        setCountries(cont);
      } catch (error) {
      }
      //setLoading(false);
    };
    getCountries();
  }, []);

  useEffect(() => {
    if (history.location.state) {
      setFilterSearch(props.location.state.search);
    } else {
      setFilterSearch('');
    }
  }, [history.location.state]);

  useEffect(() => {
    
    if (storeCategories.length > 0 && match.params.category!="All") {
      let paramCategory = storeCategories.find(
        cat => cat.slug === match.params.category
      );
      if (paramCategory !== undefined) {
        setCategory({ label: paramCategory.name, value: paramCategory.id });
      } else {
        setCategory({ label: 'All', value: 0 });
      }
    }
  }, [storeCategories.length, match.params.category]);

  let countryId = country && country.value;
  useEffect(() => {
    if(match.params.category=="All"||(match.params.category!="All" && category.value!=0))
    {
    const formattedStartDate =
      startDate && moment(startDate).format('YYYY-MM-DD');
    getEvents(
      category ? category.value : 0,
      filterSearch,
      countryId,
      ticketType,
      fromDate !== '' ? moment(fromDate).format('YYYY-MM-DD'): '',
      audience,
      toDate !== '' ? moment(toDate).format('YYYY-MM-DD'): '',
    );
    }
    // if (category) {
    //   getEvents(
    //     category.value,
    //     filterSearch,
    //     countryId,
    //     ticketType,
    //     formattedStartDate,
    //     audience
    //   );
    // } else {
    //   getEvents(
    //     0,
    //     filterSearch,
    //     countryId,
    //     ticketType,
    //     formattedStartDate,
    //     audience
    //   );
    // }
  }, [category, filterSearch, country, ticketType, fromDate, audience, toDate]);
  // const ticketPriceHandler = () => {
  //   setTicketPrice(prevState => !prevState)
  // }

  // const onChangeSlide = data => {
  //   setSliderVal(data)
  // }
  // const sliderOnChange = (e, val) => {
  //   const newVal = [...sliderValues]
  //   if (val === 'min') {
  //     newVal[0] = e.target.value
  //   } else if (val === 'max') {
  //     newVal[1] = e.target.value
  //   }
  //   setSliderVal(newVal)
  // }

  // let catOptions = props.store.StoreInfo.CategoryData.map(function (category) {
  //   return { label: category.name, value: category.id }
  // })

  // const handleCategoryIdChange = e => {
  //   setCategory(e)
  // }

  return (
    <>
      <Breadcrumbs active='events' />
      <section className='section-content bg-white padding-y'>
        <div className='container'>
          <div className='row'>
            <aside className='col-lg-3 order-2 order-lg-1'>
              <div className='card card-filter'>
                <FilterCollapsible title={t('CategoryPage.Keywords')}>
                  <LabeledInput
                    type='text'
                    id='for_keywords'
                    name='forKeywords'
                    placeholder={t('CategoryPage.Search')}
                    extext={t('CategoryPage.ExSearch')}
                    value={filterSearch}
                    onChange={e => setFilterSearch(e.target.value)}
                  />
                </FilterCollapsible>
                <FilterCollapsible title={t('CategoryPage.Date')}>
                  <DateInput
                    label={t('CategoryPage.StartDate')}
                    type='text'
                    required
                    id='start_date'
                    setStartDate={date => {
                      setfromDate(date);
                    }}
                    startDate={fromDate}
                    minDate={moment().toDate()}
                  />
                  <DateInput
                    label={t('CategoryPage.EndDate')}
                    type='text'
                    required
                    id='end_date'
                    setStartDate={date => {
                      settoDate(date);
                    }}
                    startDate={toDate}
                    minDate={moment().toDate()}
                  />
                </FilterCollapsible>
                <FilterCollapsible title={t('CategoryPage.Category')}>
                  <CustomSelect
                    className='custom_select-div'
                    id='category_id'
                    name='category_id'
                    label='Category'
                    options={categories}
                    // data={props.store.StoreInfo.CategoryData}
                    handleChange={e => setCategory(e)}
                  />
                </FilterCollapsible>
                <FilterCollapsible title={t('CategoryPage.Location')}>
                  {/* <div className="custom-control custom-checkbox form-check-input">
                    <div className="form-check mb-4 filter-online-container">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="filter-online-only"
                        name="onlineonly"
                        value="1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="filter-online-only"
                      >
                        Online events only
                      </label>
                    </div>
                  </div> */}

                  {/* <div className="location-based-filters">
                    <div className="custom-control custom-checkbox form-check-input">
                      <div className="form-check mb-4">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="filter-local-only"
                          name="localonly"
                          value="1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="filter-local-only"
                        >
                          Local events only
                        </label>
                      </div>
                    </div>
                    <p id="user-country" className="text-muted">
                      Events in India
                      <span className="flag flag-in ml-2"></span>
                    </p>
                  </div> */}
                  {countries.length > 0 && (
                    <CustomSelect
                      className='custom_select-div'
                      name='country'
                      label={t('CategoryPage.Country')}
                      options={countries}
                      handleChange={value => setCountry(value)}
                    />
                  )}
                </FilterCollapsible>
                <FilterCollapsible title={t('CategoryPage.TicketPrice')}>
                  <div className='custom-control custom-checkbox form-check-input ml-0 mb-4'>
                    <input
                      type='checkbox'
                      className='custom-control-input'
                      id='free-events-only'
                      name='freeonly'
                      value={ticketType}
                      onChange={() => setTicketType(prevState => !prevState)}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='free-events-only'>
                      {t('CategoryPage.FreeEventsOnly')}
                    </label>
                  </div>
                  {/* {ticketPrice ? (
                    <>
                      <Nouislider
                        range={{ min: 0, max: 10000 }}
                        start={sliderValues}
                        connect
                        onChange={onChangeSlide}
                      />
                      <div className='row ranger-slider-inputs mt-3'>
                        <div className='col-12 col-sm-6'>
                          <label htmlFor='pricemin'>Min</label>
                          <div className='input-group'>
                            <div className='input-group-prepend'>
                              <span className='input-group-text'>$</span>
                            </div>
                            <input
                              id='pricemin'
                              name='pricemin'
                              type='text'
                              className='form-control range-slider-min-input'
                              placeholder='Min'
                              value={Math.trunc(sliderValues[0])}
                              onChange={e => sliderOnChange(e, 'min')}
                            />
                          </div>
                        </div>
                        <div className='col-12 col-sm-6'>
                          <label htmlFor='pricemax'>Max</label>
                          <div className='input-group'>
                            <div className='input-group-prepend'>
                              <span className='input-group-text'>$</span>
                            </div>
                            <input
                              id='pricemax'
                              name='pricemax'
                              type='text'
                              className='form-control range-slider-max-input'
                              placeholder='Max'
                              value={Math.trunc(sliderValues[1])}
                              onChange={e => sliderOnChange(e, 'max')}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )} */}
                </FilterCollapsible>
                {/* <FilterCollapsible title='Date'>
                  <label>Start Date</label>
                  <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    className='form-control w-100'
                  />
                </FilterCollapsible> */}

                <FilterCollapsible title={t('CategoryPage.Audience')} >
                  <div style={{display:'flex',justifyContent:'center'}}>
                  <div
                    className='btn-group btn-group-toggle'
                    data-toggle='buttons' style={{width:'100%'}}>
                    <label
                      className={`btn btn-light btn-sm py-2 update-padding px-3 ${audience === 'Children' ? 'active' : ''
                        }`}
                      data-tip
                      data-for='detail-icon-1'>
                      <input
                        type='radio'
                        name='audience'
                        id='children'
                        value='children'
                        onClick={e => setAudience('Children')}
                      />
                      <img
                        src='/assets/uploads/audiences/children.png'
                        className='img-15-15'
                        alt='Children'
                      />
                      <ReactTooltip
                        id='detail-icon-1'
                        effect='solid'
                        className='tooltip-inner'
                        backgroundColor='#31c5f4'
                        borderColor='#31c5f4'
                        arrowColor='#31c5f4'>
                        <span style={{ fontSize: '11px' }}>{t('CategoryPage.Children')}</span>
                      </ReactTooltip>
                    </label>
                    <label
                      className={`btn btn-light btn-sm update-padding py-2 px-3 ${audience === 'Youth' ? 'active' : ''
                        }`}
                      data-tip
                      data-for='detail-icon-2'>
                      <input
                        type='radio'
                        name='audience'
                        id='youth'
                        value='youth'
                        onClick={e => setAudience('Youth')}
                      />
                      <img
                        src='/assets/uploads/audiences/youth.png'
                        className='img-15-15'
                        alt='Youth'
                      />
                      <ReactTooltip
                        id='detail-icon-2'
                        effect='solid'
                        className='tooltip-inner'
                        backgroundColor='#31c5f4'
                        borderColor='#31c5f4'
                        arrowColor='#31c5f4'>
                        <span style={{ fontSize: '11px' }}>{t('CategoryPage.Youth')}</span>
                      </ReactTooltip>
                    </label>
                    <label
                      className={`btn btn-light update-padding btn-sm py-2 px-3 ${audience === 'Family' ? 'active' : ''
                        }`}
                      data-tip
                      data-for='detail-icon-3'>
                      <input
                        type='radio'
                        name='audience'
                        id='family'
                        value='family'
                        onClick={e => setAudience('Family')}
                      />
                      <img
                        src='/assets/uploads/audiences/family.png'
                        className='img-15-15'
                        alt='Family'
                      />
                      <ReactTooltip
                        id='detail-icon-3'
                        effect='solid'
                        className='tooltip-inner'
                        backgroundColor='#31c5f4'
                        borderColor='#31c5f4'
                        arrowColor='#31c5f4'>
                        <span style={{ fontSize: '11px' }}>{t('CategoryPage.Family')}</span>
                      </ReactTooltip>
                    </label>
                    <label
                      className={`btn btn-light btn-sm update-padding py-2 px-3 ${audience === 'Adults' ? 'active' : ''
                        }`}
                      data-tip
                      data-for='detail-icon-4'>
                      <input
                        type='radio'
                        name='audience'
                        id='adults'
                        value='adults'
                        onClick={e => setAudience('Adults')}
                      />
                      <img
                        src='/assets/uploads/audiences/adults.png'
                        className='img-15-15'
                        alt='Adults'
                      />
                      <ReactTooltip
                        id='detail-icon-4'
                        effect='solid'
                        className='tooltip-inner'
                        backgroundColor='#31c5f4'
                        borderColor='#31c5f4'
                        arrowColor='#31c5f4'>
                        <span style={{ fontSize: '11px' }}>{t('CategoryPage.Adults')}</span>
                      </ReactTooltip>
                    </label>
                    <label
                      className={`btn btn-light update-padding btn-sm py-2 px-3 ${audience === 'Group' ? 'active' : ''
                        }`}
                      data-tip
                      data-for='detail-icon-5'>
                      <input
                        type='radio'
                        name='audience'
                        id='group'
                        value='group'
                        onClick={e => setAudience('Group')}
                      />
                      <img
                        src='/assets/uploads/audiences/group.png'
                        className='img-15-15'
                        alt='Group'
                      />
                      <ReactTooltip
                        id='detail-icon-5'
                        effect='solid'
                        className='tooltip-inner'
                        backgroundColor='#31c5f4'
                        borderColor='#31c5f4'
                        arrowColor='#31c5f4'>
                        <span style={{ fontSize: '11px' }}>{t('CategoryPage.Group')}</span>
                      </ReactTooltip>
                    </label>
                  </div>
                  </div>
                </FilterCollapsible>
                {/* <article className="card-group-item">
                  <div className="card-body">
                    <button className="btn btn-block btn-outline-primary">
                      <FontAwesomeIcon icon={faSearch} className="mr-2" />
                      Search
                    </button>
                  </div>
                </article> */}
                <article className='card-group-item'>
                  <div className='card-body p-0'>
                    <article className='box bg-primary'>
                      <figure className='itemside mb-4'>
                        <div className='aside align-self-center'>
                          <span className='icon-wrap icon-sm round bg-white'>
                            <FontAwesomeIcon
                              icon={faMailBulk}
                              className='text-primary'
                            />
                          </span>
                        </div>
                        <figcaption className='text-wrap'>
                          <h5 className='title text-white'>
                          {t('CategoryPage.Subscribe')}
                          </h5>
                        </figcaption>
                      </figure>
                      <div className="mailchimp">
                        <MailchimpSubscribe url={url}/>
                      </div>
                      
                      {/* <div className='form-row'>
                        <div className='form-group col mb-0'>
                          <input
                            id='newsletter-email'
                            type='email'
                            className='newsletter-email form-control rounded-0 border-0 line-height-1'
                            placeholder='Email address'
                            required={true}
                          />
                        </div>
                      </div> */}
                      {/* <div className='form-row'>
                        <div className='form-group col mb-0'>
                          <button
                            id='newsletter-subscribe'
                            data-target='/en/newsletter-subscribe'
                            type='button'
                            className='btn btn-dark justify-content-center w-100 rounded-0'>
                            SUBSCRIBE
                          </button>
                        </div>
                      </div> */}
                    </article>
                  </div>
                </article>
              </div>
            </aside>
            {loading && (
              <div className='col-lg-9 order-1 order-lg-2'>
                <CustomLoader style={{ textAlign: 'center' }} />
              </div>
            )}
            {!loading && (
              <div className='col-lg-9 order-1 order-lg-2'>
                <div className='box shadow-none bg-gray mb-4'>
                  <div className='row'>
                    <div className='col-sm-12 col-lg-6 text-center text-lg-left mb-3 mb-lg-0'>
                      <span className='center-lg-y text-muted'>
                        {eventData.length === 0 ? 0 : eventData.events.total}{' '}
                        {t('EventsFound')}
                      </span>
                    </div>
                    {/* <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <a href="#events-map" className="btn btn-primary mr-3">
                        <FontAwesomeIcon
                          icon={faMapMarkedAlt}
                          className="fa-fw mr-2"
                        />
                        <span style={{ fontSize: "11px" }}>view on map</span>
                      </a>
                      <a href="#events-calendar" className="btn btn-primary">
                        <FontAwesomeIcon icon={faCalendar} className="fa-fw mr-2" />
                        <span style={{ fontSize: "11px" }}>view on calendar</span>
                      </a>
                    </div> */}
                  </div>
                </div>
                <div className='row'>
                  {eventData.length !== 0 &&
                    eventData.events.data.map((event, index) => (
                      <div
                        className='col-12 col-sm-6 col-lg-6 mb-3'
                        key={index}>
                        <Events event={event} />
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
