import { faPlus, faTags, faEye, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import {
  DELETE_TICKET_API,
  EVENT_TICKETS_ALL,
  GET_COUPONS_API,
  GET_Supplement_API
} from '../../../../APIConfig';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../../sections/ui/Alert';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
import TicketForm from './TicketForm';
import TicketItem from './TicketItem';
import { useHistory, useParams } from 'react-router';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useAlert } from 'react-alert';
import { API_DOMAIN } from '../../../../Config';
import { useTranslation } from "react-i18next";


const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`
};

const Tickets = () => {
  const { t, i18n } = useTranslation('common');
  const [tickets, setTickets] = useState([]);
  const [event, setEvent] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [ticket, setTicket] = useState();
  const [reloadTickets, setReloadTickets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [supplements, setSupplements] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const alert = useAlert();

  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (authDataString) {
      if (authData.user_detail.role_id !== 3) {
        history.push('/signin');
      }
    } else {
      history.push('/signin');
    }
  }, []);

  useEffect(() => {
    const getTickets = async () => {
      setLoading(true);
      try {
        const { data } = await axios.post(
          EVENT_TICKETS_ALL,
          { event_id: params.slug },
          { headers }
        );
        setTickets([]);
        if (data.status && data.tickets.length > 0) {
          setTickets(data.tickets);
        }
        if(data.status && data.event)
        {
          setEvent(data.event)
        }
        setTimeout(()=>{
          if ( ! $.fn.DataTable.isDataTable('#table1')) {
          $('#table1').DataTable({
            "scrollX": true
        });}
        },5)
        setLoading(false);
        setReloadTickets(false);
      } catch (err) {
      }
    };

    const getSupplements = async () => {
      try {
        setLoading(true);
        const { data } = await axios.post(GET_Supplement_API, {},{ headers });
        if (data.success && data.data.length > 0) {
          setSupplements(data.data);
        }
      } catch (err) {
      }
      setLoading(false);
    }

    const getCoupons = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(GET_COUPONS_API, { headers });
        if (data.success && data.data.length > 0) {
          setCoupons(data.data);
        }
      } catch (err) {
      }
      setLoading(false);
    };
    const getTaxes = async () => {
      setShowForm(false);
      try {
        setLoading(true);
        const { data } = await axios.get(`${API_DOMAIN}api/tickets/taxes`, {
          headers
        });

        if (data.status && data.taxes.length > 0) {
          setTaxes(data.taxes);
        }
      } catch (err) { }
      setLoading(false);
    };
    setTicket();
    getTaxes();
    getCoupons();
    getSupplements();
    getTickets();
  }, [reloadTickets, axios]);

  // set the ticket details for showing the ticket data in edit form
  const editTicketFormHandler = selectedTicket => {
    setTicket(selectedTicket);
    setShowForm(true);
  };

  // delete ticket
  const deleteTicket = async (eventId, ticketId) => {
    if (window.confirm(t('DeleteThisTicket'))) {
      try {
        await axios.post(
          DELETE_TICKET_API,
          { event_id: eventId, ticket_id: ticketId },
          { headers }
        );
        alert.success(t('TicketDelete'));
        setReloadTickets(prevState => !prevState);
      } catch (err) {
        alert.error(t('TicketNotDelete'));
      }
    }
  };
  return (
    <>
      <Breadcrumbs active='events' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='my-events' />
            <div className='col-lg-9'>
              <Card>
                <header className='section-heading mb-3'>
                  <h4 className='title-section'>
                    <FontAwesomeIcon icon={faTags} className='fa-w-14 mr-2' />
                    {t('EventDetailPage.Tickets')}
                  </h4>
                </header>
                <div className='box shadow-none bg-gray mb-4'>
                  <div className='row'>
                    <div className='col-sm-12 col-md-3 mb-3 mb-lg-0'>
                      <span className='center-lg-y text-muted'>
                        {tickets.length} {t('CouponPage.ResultsFound')}
                      </span>
                    </div>
                    <div className='col-sm-12 col-md-3 d-flex align-items-end justify-content-end'>
                      {!showForm && (
                        <button
                          onClick={() => {
                            setShowForm(true);
                          }}
                          className='btn btn-primary ml-3'>
                          <FontAwesomeIcon icon={faPlus} className='mr-2' /> {t('CouponPage.AddNewButton')}
                        </button>
                      )}
                      {showForm && (
                        <button
                          onClick={() => {
                            setTicket();
                            setShowForm(false);
                            setTimeout(()=>{
                              if ( ! $.fn.DataTable.isDataTable('#table1')) {
                              $('#table1').DataTable({
                                "scrollX": true
                            });}
                            },5)
                          }}
                          className='btn btn-success ml-3'>
                          {t('CouponPage.CancelButton')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {showForm && (
                  <div className='box shadow-none bg-gray mb-4'>
                    <div className='row'>
                      <TicketForm
                        ticket={ticket}
                        eventId={params.slug}
                        eventdetail={event}
                        token={authData.access_token}
                        coupons={coupons}
                        supplements={supplements}
                        taxes={taxes}
                        template={ticket ? ticket.template : ''}
                        ticketStat = {ticket ? ticket.status : 0}
                        ticketiframe = {ticket ? ticket.iframe_allow : 0}
                        setReloadTickets={setReloadTickets}
                      />
                    </div>
                  </div>
                )}

                {loading && <CustomLoader style={{ textAlign: 'center' }} />}
                {!loading && tickets.length <= 0 && (
                  <Alert>{t('NoTicketsFound')}</Alert>
                )}
                {!loading && !showForm && tickets.length > 0 && (
                  <div>
 <table id='table1' className='table table-responsive-md table-striped custom-table'>
                    <thead className='thead-light'>
                      <tr>
                        <th><span
                          className='text-muted ml-2'>
                          <FontAwesomeIcon icon={faEye} color='#fff' />
                        </span></th>
                        <th><span
                          className='text-muted'>
                          <FontAwesomeIcon icon={faStar} />
                        </span></th>
                        <th>{t('TicketPage.Title')}</th>
                        <th>{t('TicketPage.Price')}</th>
                        <th>{t('TicketPage.Quantity')}</th>
                        <th>{t('TicketPage.SoldPaid')}</th>
                        <th>{t('TicketPage.Limit')}</th>
                        {/* <th>{t('TicketPage.Description')}</th> */}
                        <th>{t('TicketPage.Order')}</th>
                        <th>{t('TicketPage.Status')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tickets.map(ticket => (
                        <TicketItem
                          editTicketForm={editTicketFormHandler}
                          ticket={ticket}
                          key={ticket.id}
                          deleteTicket={deleteTicket}
                          setReloadTickets={setReloadTickets}
                          headers={headers}
                        />
                      ))}
                    </tbody>
                  </table>
                  </div>
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Tickets;
