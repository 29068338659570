import Card from '../../../../sections/ui/Card';
import CustomFileInput from '../../../../sections/ui/formfields/CustomFileInput';
import CustomSelect from '../../../../sections/ui/formfields/CustomSelect';
import LabeledInput from '../../../../sections/ui/formfields/LabeledInput';
import DatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { CREATE_Supplement_API,BACKEND_URL, } from '../../../../APIConfig';
import axios from 'axios';
import moment from 'moment';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import {useTranslation} from "react-i18next";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomFileInput2 from '../../../../sections/ui/formfields/CustomFIleInput2';
import RadioInput2 from '../../../../sections/ui/formfields/RadioInput2';
import DateInput from '../../../../sections/ui/formfields/LabededDateField';
import { useAlert } from 'react-alert';
import $ from 'jquery';

const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`,
  Accept: 'application/json'
};

const TicketSupplimentForm = ({ edit, showAlert, supplement }) => {
  const {t, i18n} = useTranslation('common');
  const supplementTypeArray = [
    {
      label: t('Single'),
      value: 1
    },
    {
      label: t('Multiple'),
      value: 2
    },
    {
      label: t('Text'),
      value: 3
    }
  ];
  const requiredTypeArray = [
    {
      label: t('No'),
      value: 0
    },
    {
      label: t('Yes'),
      value: 1
    }
  ];
  
  const MultiTypeArray = [
    {
      label: 'Checkbox',
      value: 0
    },
    {
      label: 'Radio',
      value: 1
    }
  ];
  
  const initialFormData = {
    supplement_title:'',
    supplement_type:{
      label: t('Single'),
      value: 1
    },
    supplement_required: {
      label: t('No'),
      value: 0
    },
    supplement_option:[{title:"",price:0}],
    supplement_price:0,
    supplement_applied:{
      label: t('ticketsupplement'),
      value: 1
    },
    supplement_multiple_type: {
      label: 'Checkbox',
      value: 0
    },
    generated_code: {
      label: t('Yes'),
      value: 1
    },
    template_image: null,
    template_type: null,
    start_date:new Date(),
    end_date:new Date(new Date().setDate(new Date().getDate() + 1)),
  };

 
  const applyTypeArray = [
    {
      label: t('ticketsupplement'),
      value: 1
    },
    {
      label: t('ordersupplement'),
      value: 2
    }
  ];
  
  
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [IsCustomTemplate, setCustomTemplate] = useState(true);
  const [IsNew, setIsNew] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const usalert = useAlert();
  const {
    supplement_title,
    supplement_type,
    supplement_required,
    supplement_option,
    supplement_price,
    supplement_applied,
    supplement_multiple_type,
    generated_code,
    template_type,
    start_date,
    end_date
  } = formData;
  
  useEffect(() => {
    if (supplement) {
      setFormData(supplement)
      if (supplement.template_type === 'custom') {
        setTemplateName(supplement.template_image);
        setCustomTemplate(true);
        setIsNew(false);
      }
    };
    if(!supplement)
    {
      setFormData(prevState => ({
        ...prevState,
        template_type: "custom"
      }));
    };
    return () => {
      setFormData(initialFormData);
    };
  }, [supplement]);



  const onChange = e => {

    if (e.target.name === 'template_type') {
      if (e.target.value === 'standard') {
        setCustomTemplate(false);
      }
      else {
        setCustomTemplate(true);
      }
    
    }
    else
    {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }
  };

  const formSubmit = async e => {
    e.preventDefault();
    const userData = localStorage.getItem('user');
    const userDataObj = JSON.parse(userData);

    let token = userDataObj !== null && userDataObj.access_token;

    if (!token) {
      return;
    }

    const data = {
      ...formData,
      supplement_price:supplement_type.value==1?supplement_price:0,
      supplement_type: supplement_type.value,
      supplement_required: supplement_required.value,
      supplement_applied: supplement_applied.value,
      supplement_option: JSON.stringify(supplement_type.value==2?supplement_option:[{title:"",price:0}]),
      generated_code: generated_code.value,
      supplement_multiple_type: supplement_multiple_type.value,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
      template_type: IsCustomTemplate ? 'custom' : 'standard'
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    const newFormData = new FormData();
    let exist = false;
    for (let key in data) {
      if (key === 'template_image') {
        if(data[key])
          exist=true;
      } else {
        newFormData.append(key, data[key]);
      }
    }
    if (data.template_type === 'custom') {
      var file = $('#images')[0].files[0];    
      if(file)
      {
      const filesize = (file.size / (1024*1024)).toFixed(2);
      if(filesize<=2)
      {
      newFormData.append(`template_image`, file);
      exist=true;
      }
      else
      {
       showAlert(
         t('maxlimit'),
         'error'
       );
       usalert.error(t('maxlimit'));
       return;
      } 
     }
     }
    setIsLoading(true);
    try {
      await axios.post(CREATE_Supplement_API, newFormData, { headers });
      showAlert(t('SupplementAddedUpdated'), 'success');
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors !== undefined) {
        showAlert(t('PleaseCheckAllfields'), 'error');
      } else {
        showAlert(
          t('SupplementNotAdded'),
          'error'
        );
      }
    }

    setIsLoading(false);
  };
  const addRow =function()
  { 
    supplement_option.push({title:"",price:0})
    setFormData(prevState => ({
      ...prevState,
      supplement_option: supplement_option
    }));
  }

  const removeRow = function(index)
  {
    supplement_option.splice(index,1);
    setFormData(prevState => ({
      ...prevState,
      supplement_option: supplement_option
    }));
  }

  return (
    <>
      <div className='col-lg-12'>
        <Card>
          <form onSubmit={formSubmit}>
            <div className='row'>
              <div className='col-12 col-md-12'>
                <LabeledInput
                  type='text'
                  label={t('SupplementTitle')}
                  id='supplement_title'
                  name='supplement_title'
                  required={true}
                  placeholder={t('SupplementTitle')}
                  value={supplement_title}
                  onChange={onChange}
                />
              </div>
              <div className='col-12 col-md-6'>
                          <DateInput
                            label={t('MyEventPage.StartDate')}
                            type='text'
                            required
                            id='start_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                start_date: date,
                                end_date: moment(date).add('days', 1).toDate()
                              }));
                            }}
                            startDate={formData.start_date}
                            minDate={moment().toDate()}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <DateInput
                            label={t('MyEventPage.EndDate')}
                            type='text'
                            required
                            id='end_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                end_date: date
                              }));
                            }}
                            startDate={formData.end_date}
                            minDate={moment(start_date).add('days', 1).toDate()}
                          />
                        </div>
               {
                supplement_type.value==1 && <>
                 <div className='col-12 col-md-6'>
                <LabeledInput
                  type='number'
                  label={t('SupplementPrice')}
                  id='supplement_price'
                  name='supplement_price'
                  required
                  placeholder={t('SupplementPrice')}
                  value={supplement_price}
                  onChange={onChange}
                  min='0'
                />
                </div>
                </>
               }
                <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  required={true}
                  label={t('supplementapply')}
                  defaultValue={supplement_applied}
                  options={applyTypeArray}
                  name='supplement_applied'
                  isClearable={false}
                  isSearchable={false}
                  value={supplement_applied}
                  handleChange={e =>
                    setFormData(state => ({
                      ...state,
                      supplement_applied: e
                    }))
                  }
                />
              </div>
              <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  required={true}
                  label={t('SupplementType')}
                  defaultValue={supplement_type}
                  options={supplementTypeArray}
                  name='supplement_type'
                  isClearable={false}
                  isSearchable={false}
                  value={supplement_type}
                  handleChange={e =>
                    setFormData(state => ({
                      ...state,
                      supplement_type: e
                    }))
                  }
                />
              </div>
              
              <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  label={t('Voucher.UseCode')}
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={generated_code}
                  value={generated_code}
                  handleChange={e =>
                    setFormData(state => ({
                      ...state,
                      generated_code: e
                    }))
                  }
                  options={[
                    {
                      label: t('Yes'),
                      value: 1
                    },
                    {
                      label: t('No'),
                      value: 0
                    }
                  ]}
                  name='generated_code'
                />
              </div>
              <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  label={t('SupplementRequired')}
                  isClearable={false}
                  required={true}
                  isSearchable={false}
                  defaultValue={supplement_required}
                  value={supplement_required}
                  handleChange={e =>
                    setFormData(state => ({
                      ...state,
                      supplement_required: e
                    }))
                  }
                  options={requiredTypeArray}
                  name='supplement_required'
                />
              </div>
             
              {
                 supplement_type.value==2 &&
                 <>
                   <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  label={t('SupplementMultiOption')}
                  isClearable={false}
                  required={true}
                  isSearchable={false}
                  defaultValue={supplement_multiple_type}
                  value={supplement_multiple_type}
                  handleChange={e =>
                    setFormData(state => ({
                      ...state,
                      supplement_multiple_type: e
                    }))
                  }
                  options={MultiTypeArray}
                  name='supplement_multiple_type'
                />
              </div>
                 </>
                }
              <div className='col-12 col-md-12' style={{padding:'0px'}}>
              {(template_type == 'standard' || template_type == 'custom') && (
                <div className='col-12 col-md-12'>
                <RadioInput2
                  label={t('Voucher.VoucherTemplateType')}
                  info=''
                  name='template_type'
                  entries={[
                    {
                      label: 'Standard',
                      value: 'standard',
                      id: 'enable_templatetype',
                      name: 'template_type',
                      selected: template_type == 'standard' ? true : false
                    },
                    {
                      label: 'Custom',
                      value: 'custom',
                      id: 'disable_templatetype',
                      name: 'template_type',
                      selected: template_type == 'custom' ? true : false
                    }
                  ]}
                  onChange={onChange}
                />
                </div>
                )}
                {IsCustomTemplate === true && (
                <div className='col-12 col-md-12'>
                  {templateName && IsNew === false && (
                    <img src={BACKEND_URL + templateName} className="img-fluid"/>
                  )}
                  <CustomFileInput2
                    label={t('AddTemplate')}
                    id='images'
                    name='images'
                    info={t('MyEventPage.CustomTemplateWarning')}
                    handleFile={(e) => {
                      setTemplateName(e.target.files[0].name);
                      setIsNew(true);
                    }}
                  filename={
                    templateName
                      ? templateName
                      : ''
                  }
                  />
                  <small className='form-text text-muted mb-3' style={{marginTop:'-20px'}}>
                             <FontAwesomeIcon
                               icon={faInfoCircle}
                               className='text-primary mr-1'
                              />
                            {t('maxlimit')}
                            </small>
                </div>
              )}
              </div>
            </div>
            {
                 supplement_type.value==2 && supplement_option.map((data,index) =>(
                            <>
                          <div className='row' style={{paddingRight:'20px'}}>
                          <div className='col-12 col-md-6'>
                            <LabeledInput
                              type='text'
                              label={t('optiontitle')}
                              id='title'
                              name='title'
                              value={data.title}
                              onChange={e=>{
                                supplement_option[index].title=e.target.value;
                                setFormData(prevState => ({
                                  ...prevState,
                                  supplement_option: supplement_option
                                }));
                              }}
                              required
                            />
                          
                        </div>
                        <div className='col-10 col-md-5'>
                            <LabeledInput
                              min="0"
                              type='number'
                              label={t('optionprice')}
                              id='price'
                              name='price'
                              value={data.price}
                              onChange={e=>{
                                supplement_option[index].price=e.target.value;
                                setFormData(prevState => ({
                                  ...prevState,
                                  supplement_option: supplement_option
                                }));
                              }}
                              required
                            />
                          
                        </div>
                        
                        {
                          supplement_option.length==(index+1)?
                          <>
                          <div className='divdes col-2 col-md-1'>
                        <button className='btn rowbut'  onClick={() => {
                           addRow();
                          
                          }}>+</button>
                        </div>
                          </>
                          :
                          <>
                          <div className='divdes col-2 col-md-1'>
                        <button className='btn rowbut2' onClick={() => {
                           removeRow(index);
                          }}>-</button>
                        </div>
                        </>
                        }
                        
                       
                        </div>
                        
                        
                            </>
                             ))
                      }
               
            {isLoading && (
              <Loader
                type='ThreeDots'
                color='#31c5f4'
                height={50}
                width={50}
              />
            )}
            {!isLoading && !edit && (
              <button className='btn btn-primary' >{t('CouponPage.CreateButton')}</button>
            )}

            {!isLoading && edit && (
              <button className='btn btn-primary'>{t('CouponPage.UpdateButton')}</button>
            )}
          </form>
        </Card>
      </div>
    </>
  );
};

export default TicketSupplimentForm;
