import { faPlus, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { DELETE_TEMPLATE_API, GET_Template_API_Event } from '../../../../APIConfig';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../../sections/ui/Alert';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
// import CustomSelect from '../../../../sections/ui/formfields/CustomSelect'
import EventTemplateForm from './EventTemplateForm';
import EventTemplateItem from './EventTemplateItem';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useTranslation } from 'react-i18next';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import $ from 'jquery';
const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`
};

const EventTemplate = props => {
  const { t, i18n} = useTranslation('common');

  const [templates, setTemplates] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [edit, setEdit] = useState(false);
  const [template, setTemplate] = useState(null);
  const [reloadTemplates, setReloadTemplates] = useState(false);

  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (foundUser.user_detail.role_id !== 3) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      setReloadTemplates(false);
      try {
        const { data } = await axios.get(GET_Template_API_Event, { headers });
        if (data.success) {
          setTemplates(data.data);
        } else {
          setTemplates([]);
        }
      } catch (error) {
      }
      setLoading(false);
      setTimeout(()=>{
        if ( ! $.fn.DataTable.isDataTable('#table1')) {
        $('#table1').DataTable({
          "scrollX": true
      });}
      },5)
    };
    fetchTemplates();
  }, [reloadTemplates, axios]);

  const showAlert = (message, error) => {
    if (error === 'error') {
      setError(true);
      setMessage(message);
    } else if (error === 'success') {
      setReloadTemplates(true);
      setShowAddForm(false);
      setError(false);
      setMessage(message);
    }
  };
  const showEdit = templatedata => {
    setEdit(true);
    setTemplate(templatedata);
    setShowAddForm(true);
  };

  const deleteTemplate = async id => {
    if (window.confirm(t('bookcancelmessage'))) {
      try {
        await axios.post(DELETE_TEMPLATE_API, { id: id }, { headers });
        showAlert(t('DeleteSuccessfully'), 'success');
      } catch (error) {
        console.error(error.response.message);
        showAlert(
          t('CouldNotDelete'),
          'error'
        );
      }
    }
  };
  return (
    <>
      <Breadcrumbs active='event-template' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='event-template' />
            <div className='col-lg-9'>
              <Card>
                <header className='section-heading mb-3'>
                  <h4 className='title-section'>
                    <FontAwesomeIcon icon={faTags} className='fa-w-14 mr-2' />
                    {t('DashboardPage.eventtemplates')}
                  </h4>
                </header>
                {message && (
                  <div
                    className={`alert alert-${error ? 'danger' : 'success'}`}>
                    {message}
                  </div>
                )}

                
                <div className='box shadow-none bg-gray mb-4'>
                  <div className='row'>
                    <div className='col-sm-12 col-md-12 d-flex align-items-end justify-content-center'>
                      {!showAddForm && (
                        <button
                          onClick={() => {
                            setShowAddForm(prevState => !prevState);
                          
                          }}
                          className='btn btn-primary ml-3'>
                          <FontAwesomeIcon icon={faPlus} className='mr-2' /> {t('CouponPage.AddNewButton')}
                        </button>
                      )}
                      {showAddForm && (
                        <button
                          onClick={() => {
                            setTemplate(null);
                            setEdit(false);
                            setShowAddForm(prevState => !prevState);
                            setTimeout(()=>{
                              if ( ! $.fn.DataTable.isDataTable('#table1')) {
                              $('#table1').DataTable({
                                "scrollX": true
                            });}
                            },5)
                          }}
                          className='btn btn-success ml-3'>
                          {t('CouponPage.CancelButton')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {showAddForm && (
                  <div className='box shadow-none bg-gray mb-4'>
                    <div className='row'>
                      <EventTemplateForm
                        edit={edit}
                        showAlert={showAlert}
                        template={template}
                      />
                    </div>
                  </div>
                )}

                {loading && <CustomLoader style={{ textAlign: 'center' }} />}
                {!loading && templates.length <= 0 && (
                  <Alert>{t('notemplatefound')}</Alert>
                )}
                {!loading && templates.length > 0 && !showAddForm && (
                  <div>
   <Table id='table1' className='table table-responsive-md table-striped custom-table'>
                    <Thead className='thead-light'>
                      <Tr>
                        <Th>{t('CouponPage.Title')}</Th>      
                        <Th>{t('CouponPage.StartDate')}</Th>
                        <Th>{t('CouponPage.Actions')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {templates.map(template => (
                        <EventTemplateItem
                          showEdit={showEdit}
                          template={template}
                          key={template.id}
                          deleteTemplate={deleteTemplate}
                        />
                      ))}
                    </Tbody>
                  </Table>
                  </div>
               
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default EventTemplate;
