import axios from 'axios'
import { API_DOMAIN } from '../../Config'
// import { MY_BOOKINGS_API } from '../../APIConfig'
import {
  MY_BOOKING_LIST_FAIL,
  MY_BOOKING_LIST_REQUEST,
  MY_BOOKING_LIST_SUCCESS
} from '../constants/myBookingsConstants'

export const myBookings = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MY_BOOKING_LIST_REQUEST
    })
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('user')) &&
          JSON.parse(localStorage.getItem('user')).access_token
        }`
      }
    }
    const { data } = await axios.get(
      `${API_DOMAIN}api/mybookings/get_mybookings?page=${page}`,
      config
    )

    dispatch({
      type: MY_BOOKING_LIST_SUCCESS,
      payload: data.bookings
    })
  } catch (error) {
    dispatch({
      type: MY_BOOKING_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}
