import {
  CART_ADD_ITEM,
  CART_ADD_ITEM_TO_STORAGE,
  // CART_GET_DATA_FROM_SERVER,
  CART_REMOVE_ITEM,
  CART_RESET_ITEM
} from '../constants/cartConstants'

export const addToCart =
  (event_id, ticket_id, ticket_qty, event, ticket, couponId = '', couponData=null) => async dispatch => {
    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        ticket_qty,
        ticket_id,
        event_id,
        event,
        ticket,
        couponId,
        couponData
      }
    })
    dispatch({ type: CART_ADD_ITEM_TO_STORAGE })
    // localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
  }

export const removeFromCart = (event_id, ticket_id) => dispatch => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: { event_id, ticket_id }
  })
  dispatch({ type: CART_ADD_ITEM_TO_STORAGE })
  // localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const clearCart = () => dispatch => {
  dispatch({ type: CART_RESET_ITEM })
  dispatch({ type: CART_ADD_ITEM_TO_STORAGE })
}

export const getCartData = (cartItems) => async (dispatch, getState) => {  
  // const data = ''
  // dispatch({
  //   type: CART_GET_DATA_FROM_SERVER,
  //   payload: data
  // })
}