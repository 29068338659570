import React, { useEffect, useState } from 'react';
import Sidebar from '../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../sections/ui/Alert';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import Card from '../../../sections/ui/Card';
import CustomFileInput from '../../../sections/ui/formfields/CustomFileInput';
// import CustomSelect from "../../../sections/ui/formfields/CustomSelect";
import LabeledInput from '../../../sections/ui/formfields/LabeledInput';
// import RadioInput from "../../../sections/ui/formfields/RadioInput";
// import countries from "../../../sections/ui/countries";
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import CustomLoader from '../../../sections/ui/CustomLoader';
import { BACKEND_URL } from '../../../APIConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { API_DOMAIN } from '../../../Config';
// import { MY_PROFILE_API, MY_PROFILE_UPDATE } from '../../../APIConfig';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../../sections/ui/formfields/CustomSelect';
import { useSelector } from 'react-redux';

const loggedInUser = localStorage.getItem('user');
const userDetails = JSON.parse(loggedInUser);
const headers = {
  Authorization: `Bearer ${userDetails && userDetails.access_token}`
};

function Profile(props) {
  const [user, setUser] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    address: '',
    organisation: '',
    bankName: '',
    bankCode: '',
    bankBranchName: '',
    bankBranchCode: '',
    bankAccountNumber: '',
    bankAccountName: '',
    bankAccountPhone: '',
    avatar: '',
    organizer_logo:'',
    id: '',
    gender: '',
    login_id:'',
    list_id:'',
    api_key:'',
    // facebook: '',
    // twitter: '',
    // instagram: '',
    // linkedin: ''
  });
  const {t, i18n} = useTranslation('common');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [updateUserLoading, setUpdateUserLoading] = useState();
  const [userUpdated, setUserUpdated] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [organizer_logo, setorganizer_logo]= useState('');
  const [showAddAvatar, setShowAddAvatar] = useState(false);
  const alert = useAlert();
  const [gender, setGender] = useState({ label: '', value: '' });
  const [countryselect, SetCountrySelect] = useState({
    label: "Belgium",
    value: "BE"
  });
  const [countryData, setCountryData] = useState([]);
  const countriesData = useSelector(state => state.countries.countries);
  
  useEffect(() => {
    if (loggedInUser) {
      if (userDetails.user_detail.role_id !== 3) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  useEffect(() => {
    let country_data = {};

    if (countriesData && countriesData.length > 0) {
      let countriesArray = countriesData.map(country => ({
        label: country.country_name,
        value: country.country_code
      }));
      setCountryData(countriesArray);
      countriesData.forEach(item=>{
        country_data[item.country_code] = item.country_name
      })
    }
    const getUser = async () => {
      try {
        setLoading(true);
        const { data } = await axios.post(
          `${API_DOMAIN}api/auth/me`,
          {},
          {
            headers
          }
        );
        const userData = {
          id: data.data.id,
          email: data.data.email,
          firstName: data.data.name.split(' ')[0]
            ? data.data.name.split(' ')[0]
            : '',
          lastName: data.data.name.split(' ')[1]
            ? data.data.name.replace(data.data.name.split(' ')[0],'').trim()
            : '',
          phone: data.data.phone ? data.data.phone : '',
          address: data.data.address ? data.data.address : '',
          avatar: data.data.avatar ? data.data.avatar : '',
          organiser_logo: data.data.organiser_logo ? data.data.organiser_logo : '',
          organisation: data.data.organisation ? data.data.organisation : '',
          bankName: data.data.bank_name ? data.data.bank_name : '',
          bankCode: data.data.bank_code ? data.data.bank_code : '',
          bankBranchName: data.data.bank_branch_name
            ? data.data.bank_branch_name
            : '',
          bankBranchCode: data.data.bank_branch_code
            ? data.data.bank_branch_code
            : '',
          bankAccountNumber: data.data.bank_account_number
            ? data.data.bank_account_number
            : '',
          bankAccountName: data.data.bank_account_name
            ? data.data.bank_account_name
            : '',
          bankAccountPhone: data.data.bank_account_phone
            ? data.data.bank_account_phone
            : '',
          gender: data.data.gender ? data.data.gender : '',
          login_id:data.data.login_id?data.data.login_id:'',
          list_id:data.data.list_id?data.data.list_id:'',
          api_key:data.data.api_key?data.data.api_key:'',
          // facebook: data.data.facebook ? data.data.facebook : '',
          // twitter: data.data.twitter ? data.data.twitter : '',
          // instagram: data.data.instagram ? data.data.instagram : '',
          // linkedin: data.data.linkedin ? data.data.linkedin : ''
        };

        SetCountrySelect(
          {
            label: data.data.country_code ? country_data[data.data.country_code] : '',
            value: data.data.country_code ? data.data.country_code : ''
          })
        setGender({
          label: data.data.gender ? data.data.gender : '',
          value: data.data.gender ? data.data.gender : ''
        });
        setUser(userData);
        setError('');
      } catch (err) {
        setError(t('AddedToFavoriteError'));
      }
      setLoading(false);
    };
    getUser();
  }, [countriesData]);


  // useEffect(() => {
 
  // }, [axios, userUpdated]);

  const onChangeHandler = e => {
    setUser(state => ({
      ...state,
      [e.target.name]: e.target.value
    }));
  };

  const updateProfileHandler = async e => {
    e.preventDefault();
    try {
      setUpdateUserLoading(true);
      const formData = new FormData();
      formData.append('first_name', user.firstName);
      formData.append('last_name', user.lastName);
      formData.append('phone', user.phone);
      formData.append('address', user.address);
      formData.append('organisation', user.organisation);
      formData.append('bank_name', user.bankName);
      formData.append('bank_code', user.bankCode);
      formData.append('bank_branch_name', user.bankBranchName);
      formData.append('bank_branch_code', user.bankBranchCode);
      formData.append('bank_account_number', user.bankAccountNumber);
      formData.append('bank_account_name', user.bankAccountName);
      formData.append('bank_account_phone', user.bankAccountPhone);
      formData.append('gender', gender.value);
      formData.append('country_code', countryselect.value);
      formData.append('api_key', user.api_key);
      formData.append('list_id', user.list_id);
      // formData.append('facebook', user.facebook);
      // formData.append('twitter', user.twitter);
      // formData.append('instagram', user.instagram);
      // formData.append('linkedin', user.linkedin);
      if (avatar) {
        formData.append('avatar', avatar);
      }
      if (organizer_logo) {
        formData.append('organiser_logo', organizer_logo);
      }
      await axios.post(`${API_DOMAIN}api/auth/update-profile`, formData, {
        headers
      });
      alert.success(t('Profileupated'));
      setShowAddAvatar(false);
      setAvatar('');
      setUserUpdated(state => !state);
    } catch (err) {
      alert.error(t('TryAgain'));
    }
    setUpdateUserLoading(false);
  };

  const fileHandler = e => {
    setAvatar(e.target.files[0]);
  };

  const fileHandler1 = e => {
    setorganizer_logo(e.target.files[0]);
  };

  return (
    <>
      <Breadcrumbs active='edit-your-organizer-profile' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='edit-your-organizer-profile' />
            <div className='col-lg-9'>
              <Card>
                {loading && <CustomLoader style={{ textAlign: 'center' }} />}
                {!loading && error && <Alert type='error'>{error}</Alert>}
                {!loading && !error && user && (
                  <form onSubmit={updateProfileHandler}>
                    <div className='col-12 col-md-12'>
                      <div style={{ textAlign: 'center' }}>
                        <div style={{ position: 'relative' }}>
                          <img
                            src={`${BACKEND_URL}${user.avatar}`}
                            alt='test'
                            style={{
                              width: 100,
                              height: 100,
                              borderRadius: '50%'
                            }}
                          />
                          <span
                            style={{
                              fontSize: 20,
                              position: 'absolute',
                              bottom: 0,
                              marginLeft: -25,
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setAvatar('');
                              setShowAddAvatar(state => !state);
                            }}>
                            <FontAwesomeIcon icon={faEdit} color='#31c5f4' />
                          </span>
                        </div>
                        {showAddAvatar && (
                          <CustomFileInput
                            label={t('ProfilePicture')}
                            id='organizer_profile'
                            filename={avatar && avatar.name}
                            handleFile={fileHandler}
                            info={t('MyEventPage.MainEventImageWarning')}
                          />
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12 col-md-12'>
                        <LabeledInput
                          label={t('OrganizerProfile.Email')}
                          type='email'
                          id='email'
                          name='email'
                          value={user.email}
                          required
                          register={user.email}
                          disabled
                        />
                      </div>
                      <div className='col-12 col-md-6'>
                        <LabeledInput
                          label={t('OrganizerProfile.FirstName')}
                          type='text'
                          id='firstName'
                          name='firstName'
                          value={user.firstName}
                          onChange={onChangeHandler}
                          required
                        />
                      </div>
                      <div className='col-12 col-md-6'>
                        <LabeledInput
                          label={t('OrganizerProfile.LastName')}
                          type='text'
                          id='lastName'
                          name='lastName'
                          value={user.lastName}
                          onChange={onChangeHandler}
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <LabeledInput
                            label={t('OrganizerProfile.Organization')}
                            type='text'
                            id='organisation'
                            name='organisation'
                            value={user.organisation}
                            onChange={onChangeHandler}
                            required
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('OrganizerProfile.Phone')}
                            type='text'
                            id='phone'
                            name='phone'
                            value={user.phone}
                            onChange={onChangeHandler}
                            required
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('OrganizerProfile.apikey')}
                            type='text'
                            id='api_key'
                            name='api_key'
                            value={user.api_key}
                            onChange={onChangeHandler}
                            
                          />
                        </div>
                        
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('OrganizerProfile.listid')}
                            type='text'
                            id='list_id'
                            name='list_id'
                            value={user.list_id}
                            onChange={onChangeHandler}
                            
                          />
                        </div>

                        <div className='col-12 col-md-12'>
                          <LabeledInput
                            label={t('OrganizerProfile.apitoken')}
                            type='text'
                            id='loginid'
                            name='loginid'
                            value={user.login_id}
                            readOnly={true}
                            onClick={
                              (ev)=>{
                                navigator.clipboard.writeText(user.login_id);
                                alert.success(t('copytoclipboard'));

                              }
                            }
                          />
                        </div>

                        <div className='col-md-12'>
                        <legend className="col-form-label ">{t('OrganizerProfile.OrganizationLogo')}</legend>
                        <div style={{ position: 'relative' }}>
                          
                          <img
                            src={`${BACKEND_URL}${user.organiser_logo}`}
                            alt='test'
                            style={{
                              width: 100,
                              height: 100,
                              borderRadius: '50%'
                            }}
                          />
                          
                          {/* <span
                            style={{
                              fontSize: 20,
                              position: 'absolute',
                              bottom: 0,
                              marginLeft: -25,
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setorganizer_logo('');
                              //setShowAddAvatar(state => !state);
                            }}>
                          </span> */}
                        </div>
                        
                          <CustomFileInput
                            label=''
                            id='organiser_logo'
                            filename={organizer_logo && organizer_logo.name}
                            handleFile={fileHandler1}
                            info=''
                          />
                        </div>
                        
                      </div>
                      <div >
                        <CustomSelect
                          className='custom_select-div'
                          label={t('Gender')}
                          defaultValue={gender}
                          options={[
                            { label: 'Male', value: 'Male' },
                            { label: 'Female', value: 'Female' },
                            { label: 'Other', value: 'other' }
                          ]}
                          name='gender'
                          isClearable={false}
                          isSearchable={false}
                          value={gender}
                          handleChange={e => setGender(e)}
                        />
                      </div>
                      
                      <div >
                      <CustomSelect
              className='custom_select-div'
              required={true}
              label={t('Country')}
              options={countryData}
              name='country'
              isClearable={false}
              value={countryselect}
              handleChange={e => {
               SetCountrySelect(e)
              }}
            />
                      
                      </div>
                      <LabeledInput
                        textArea
                        label={t('OrganizerProfile.Address')}
                        type='text'
                        id='address'
                        name='address'
                        value={user.address}
                        onChange={onChangeHandler}
                        required
                      />
                      <hr />
                      <h5 className='title-section mb-4'>
                      {t('OrganizerProfile.UpdateBankDetails')}
                      </h5>
                      <div className='row'>
                        {/* <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('OrganizerProfile.BankName')}
                            type='text'
                            id='bankName'
                            name='bankName'
                            value={user.bankName}
                            onChange={onChangeHandler}
                            required
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('OrganizerProfile.BankCode')}
                            type='text'
                            id='bankCode'
                            name='bankCode'
                            value={user.bankCode}
                            onChange={onChangeHandler}
                            required
                          />
                        </div> */}
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('OrganizerProfile.BankBranchName')}
                            type='text'
                            id='bankBranchName'
                            name='bankBranchName'
                            value={user.bankBranchName}
                            onChange={onChangeHandler}
                            required
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('OrganizerProfile.BankBranchCode')}
                            type='text'
                            id='bankBranchCode'
                            name='bankBranchCode'
                            value={user.bankBranchCode}
                            onChange={onChangeHandler}
                            required
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <LabeledInput
                            label={t('OrganizerProfile.BankAccountNo')}
                            type='text'
                            id='bankAccountNumber'
                            name='bankAccountNumber'
                            value={user.bankAccountNumber}
                            onChange={onChangeHandler}
                            required
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('OrganizerProfile.BankAccountName')}
                            type='text'
                            id='bankAccountName'
                            name='bankAccountName'
                            value={user.bankAccountName}
                            onChange={onChangeHandler}
                            required
                          />
                        </div>
                        {/* <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('OrganizerProfile.BankAccountPhone')}
                            type='text'
                            id='bankAccountPhone'
                            name='bankAccountPhone'
                            value={user.bankAccountPhone}
                            onChange={onChangeHandler}
                            required
                          />
                        </div> */}
                      </div>
                      {/* <hr />
                      <h5 className='title-section mb-4'>Social Profiles</h5>
                      <div className='row'>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label='Facebook'
                            type='url'
                            id='facebook'
                            name='facebook'
                            value={user.facebook}
                            onChange={onChangeHandler}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label='Twitter'
                            type='url'
                            id='twitter'
                            name='twitter'
                            value={user.twitter}
                            onChange={onChangeHandler}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label='Instagram'
                            type='url'
                            id='instagram'
                            name='instagram'
                            value={user.instagram}
                            onChange={onChangeHandler}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label='LinkedIn'
                            type='url'
                            id='linkedin'
                            name='linkedin'
                            value={user.linkedin}
                            onChange={onChangeHandler}
                          />
                        </div>
                      </div> */}
                    </div>
                    {updateUserLoading && <CustomLoader />}
                    {!updateUserLoading && (
                      <button type='submit' className='btn btn-primary btn'>
                        {t('OrganizerProfile.UpdateButton')}
                      </button>
                    )}
                  </form>
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Profile;
