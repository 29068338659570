import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const user = JSON.parse(localStorage.getItem('user'));
const userRole = user && user.user_detail && user.user_detail.role_id;


function Prefooter(props) {
  const {t, i18n} = useTranslation('common')

  return (
    <section className='section-intro padding-y-lg'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8 col-lg-10 text-center'>
            <article className='text-dark text-center text-lg-left mb-3'>
              <h3>{t('NewCreateEvent')} ?</h3>
            </article>
          </div>
          <div className='col-md-4 col-lg-2 text-center text-lg-right'>
            <Link
              to={`${
                userRole
                  ? userRole === 2
                    ? '/signup/organizer'
                    : '/dashboard/organizer/my-events/add'
                  : '/signup/organizer'
              }`}
              className='btn btn-light'>
              <FontAwesomeIcon icon={faCalendarPlus} /> {t('Footer.CreateMyEvent')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Prefooter;
