import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { cartReducer } from './service/reducers/cartReducers';
import loggedReducer from './service/reducers/IsLogged';
import reducer from './service/reducers/reducer';
import { myBookingListReducer } from './service/reducers/myBookingsReducers';
import { countriesReducer } from './service/reducers/countriesReducers';
const combinedReducers = combineReducers({
  cart: cartReducer,
  reducers: reducer,
  StoreInfo: loggedReducer,
  myBookingList: myBookingListReducer,
  countries: countriesReducer
});

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : [];

// const userInfoFromStorage = localStorage.getItem('userInfo')
//   ? JSON.parse(localStorage.getItem('userInfo'))
//   : null

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage
  }
  // userLogin: {
  //   userInfo: userInfoFromStorage
  // }
};

const middleware = [thunk];

const store = createStore(
  combinedReducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
