import { faEdit, faTrash ,faEye, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'

const TicketSuppliment = ({ supplement, showEdit, deleteSupplement}) => {
    const { t, i18n} = useTranslation('common');
    const {
        id,
        supplement_title,
        supplement_type,
        supplement_required,
        supplement_price,
        supplement_option,
        delete_allow
    } = supplement
    return (
        <Tr>
            <Td  style={{ display:'inline-grid'}}>
                <span
                    className='text-muted ml-2'
                    style={{ cursor: 'pointer' }}
                    onClick={() => showEdit(supplement)}
                    title={t('EditSupplement')}>
                    <FontAwesomeIcon icon={faEdit} />
                </span>
                {
                      delete_allow && <>
                        <span
                    className='text-muted ml-2'
                    style={{ cursor: 'pointer' }}
                    onClick={() => deleteSupplement(supplement.id)}
                    title={t('DeleteSupplement')}>
                    <FontAwesomeIcon icon={faTrash} color='red'/>
                </span>
                      </>
                }
              
            </Td>
            <Td>
             {supplement_title}
            </Td>
            <Td className='text-center padding-add'>
            {supplement_type==1?'Single':supplement_type==2?'Multiple':'Text'}  
                </Td>
                <Td className='text-center padding-add'>
                {supplement_required==0?'No':'Yes'}
                </Td>
        </Tr>
    )
}

export default TicketSuppliment
