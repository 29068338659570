import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useState } from 'react';
import { BACKEND_URL } from '../../../APIConfig';
import { API_DOMAIN } from '../../../Config';
import CustomLoader from '../../../sections/ui/CustomLoader';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import SortableList, { SortableItem } from "react-easy-sort";
const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`,
  Accept: 'application/json'
};


const EditEventGalleryImages = ({ eventId, images, type, setEditImageGallery}) => {
  const {t, i18n} = useTranslation('common');
  const [imagesGallery, setImagesGallery] = useState(images);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const onSortEnd = (oldIndex, newIndex) => {
    let newar = [...imagesGallery];
    var element = newar[oldIndex];
    newar.splice(oldIndex, 1);
    newar.splice(newIndex, 0, element);
    setImagesGallery([...newar]); 
    setEditImageGallery([...newar]) 
  };
  
  const deleteEventImage = async (imageName, type) => {
    if (
      window.confirm(
        t('RemoveImageFromEvent')
      )
    ) {
      if (imagesGallery && imagesGallery.length > 0) {
        const newImages = imagesGallery.filter(
          image => image !== imageName && image
        );
        try {
          setLoading(true);
          await axios.post(
            `${API_DOMAIN}api/myevents/delete-event-image`,
            { event_id: eventId, image_title: imageName, type },
            { headers }
          );
          alert.success(t('ImageRemoved'));
          setImagesGallery(newImages);
          if(type!='poster')
          {
            setEditImageGallery(newImages) 
          }
        } catch (error) {
          alert.error(t('TryAgain'));
        }
        setLoading(false);
      }
    }
  };
  return (
    <div className='row'>
      <div className='col-md-12'>
        {loading && <CustomLoader style={{ textAlign: 'center' }} />}
      </div>
      
      {!loading &&
        imagesGallery && type!='poster' &&
        imagesGallery.length > 0 &&
        <div className='col-md-12'>
        <SortableList
        onSortEnd={onSortEnd}
        className="listss"
        draggedItemClassName="dragged"
      >
            {imagesGallery.map((image,index) => (
           <SortableItem key={index}>
             <div key={image} className="item_img"
             style={{
              marginRight: 20,
              marginBottom: 20,
              position:'relative',
              width: '200px',
              borderRadius: 5,
              background:"url("+ BACKEND_URL+image + ")",
              boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }}>
                 <span
              style={{
                position: 'absolute',
                top: -8,
                right: -3,
                color: '#d20d0d',
                zIndex:9999999999,
                cursor: 'pointer'
              }}
              onClick={() => deleteEventImage(image, type)}>
              <FontAwesomeIcon icon={faTrash} />
            </span>
             </div>
            
        </SortableItem>
          ))}
        </SortableList>
        </div>
      }
    {!loading &&
        imagesGallery && type=='poster' &&
        imagesGallery.length > 0 &&
        imagesGallery.map(image => (
          <div key={image} className='col-md-4'>
            <img
              src={`${BACKEND_URL}${image}`}
              alt=''
              style={{
                marginRight: 20,
                marginBottom: 20,
                width: '100%',
                borderRadius: 5,
                boxShadow:
                  '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
              }}
            />
            <span
              style={{
                position: 'absolute',
                top: -10,
                right: 10,
                color: '#d20d0d',
                cursor: 'pointer'
              }}
              onClick={() => deleteEventImage(image, type)}>
              <FontAwesomeIcon icon={faTrash} />
            </span>
          </div>
        ))}

    </div>
  );
};

export default EditEventGalleryImages;
