import { faCheckCircle ,faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect ,useState} from 'react'
import { useDispatch ,useSelector} from 'react-redux'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../sections/ui/Breadcrumbs'
import Card from '../../sections/ui/Card'
import { clearCart } from '../../service/actions/cartActions'
// import { useHistory } from 'react-router-dom'
import {useTranslation} from "react-i18next";
import { API_DOMAIN, IMAGE_BASE_URL } from '../../Config';
import EventSlider from '../../sections/events/EventsDetailsSlider';
import { GET_PAYMENT_STATUS } from '../../APIConfig'
import axios from 'axios'

const OrderPendingIframe = ({ match }) => {
  const dispatch = useDispatch()
  const orderId = match.params.orderNumber
  const {t, i18n} = useTranslation('common');
  const cartItems = useSelector(state => state.cart.cartItems)
  const [eventDetail, setEventDetail] = useState({});
  const [galleryImages, setGalleryImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPaymentStatus = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(GET_PAYMENT_STATUS + orderId
      );
      //setStatus(data.data)
      if(data && data.data && data.data.payment_status === 'success'){
        window.location.href = '/order-iframe/success/'+orderId;
      }
      else if(data && data.data && data.data.payment_status === 'failed'){
        window.location.href = '/order-iframe/failed/'+orderId;
      }
      else{
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };
  useEffect(() => {
    setInterval(()=> getPaymentStatus(), 20000);
  })
  useEffect(() => {
    setEventDetail(cartItems[0].event)
    const imagesGallery = JSON.parse(cartItems[0].event.images);
    const imagesGalleryArray =
      imagesGallery && imagesGallery.length > 0
        ? imagesGallery.map(image => ({
          src: `${IMAGE_BASE_URL}/${image}`,
          thumbnail: `${IMAGE_BASE_URL}/${image}`,
          title: 'title',
          w: 1200,
          h: 900
        }))
        : [];
    setGalleryImages(imagesGalleryArray);
    dispatch(clearCart())
    var userdetail = localStorage.getItem("user");
    localStorage.clear();
    if(userdetail)
    localStorage.setItem("user",userdetail)
  }, [dispatch])

  return (
    <div>
      {/* <Breadcrumbs active='' /> */}
      <EventSlider images={galleryImages.map(image => image.src)} />
      <section className='section-content bg-white ' style={{minHeight:'100vh',paddingTop:'20px',paddingBottom:'5px'}}>
        <div className='container'>
          <div className='row'>
            <main
              className='col-lg-12 order-1 order-lg-0 mt-4 mt-lg-0'
              style={{
                textAlign: 'center'
              }}
            >
              <Card>
              <h1>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color='yellow'
                    style={{ marginRight: 10 }}
                  />
                  {t('PaymentProcessing')}
                </h1>
                <br />
              </Card>
              <Card title='Details'>
                {orderId && <h3>{t('OrderNumber')}: #{orderId}</h3>}
              </Card>
              <Link style={{color:eventDetail.url_color}} to={`/event-frame-details/${eventDetail.slug}/${eventDetail.id}`}>{t('CartPage.ContinueShoppingButton')}</Link>
            </main>
          </div>
        </div>
      </section>
    </div>
  )
}

export default OrderPendingIframe
