import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../sections/ui/Breadcrumbs';
// import Blog from './blog';
import Blog from '../../sections/home/LatestBlogs/Blog';
import Alert from '../../sections/ui/Alert';
import axios from 'axios';
import { BLOGS_ALL_API } from './../../APIConfig';
import CustomLoader from '../../sections/ui/CustomLoader';
import {useTranslation} from "react-i18next";

export default function Index() {
  const {t, i18n} = useTranslation('common');
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    try {
      setLoading(true);
      await axios({
        url: BLOGS_ALL_API,
        method: 'GET'
      }).then(function (response) {
        setBlogs(response.data.data);
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Breadcrumbs active='Blogs' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            {loading && (
              <div className='col-12 col-sm-12 col-lg-12'>
                <CustomLoader style={{ textAlign: 'center' }} />
              </div>
            )}
            {!loading &&
              blogs.length > 0 &&
              blogs.map((data, index) => {
                return (
                  <div className='col-12 col-sm-6 col-lg-4' key={data.id}>
                    <Blog
                      title={data.title}
                      key={data.id}
                      image={data.image}
                      alt={data.seo_title}
                      slug={data.slug}
                    />
                  </div>
                );
              })}
            {!loading && blogs.length <= 0 && (
              <div
                className='col-12 col-sm-12 col-lg-12'
                style={{ textAlign: 'center' }}>
                <Alert>{t('NoBlogsFound')}</Alert>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
