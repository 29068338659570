import { faEnvelope, faInbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Input from '../../sections/ui/formfields/Input';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { API_DOMAIN } from './../../Config';
import Alert from '../../sections/ui/Alert';
import CustomLoader from '../../sections/ui/CustomLoader';
import {useTranslation} from "react-i18next";


function Forgotpassword(props) {
  const {t, i18n} = useTranslation('common');
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [passwordsent, setPasswordSent] = useState(false);
  const [responseResult, setResponseresult] = useState({
    type: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = formData => {
    resetPassword(formData);
  };

  const resetPassword = async formdata => {
    try {
      setLoading(true);
      await axios({
        url: API_DOMAIN + 'api/auth/forget-password',
        method: 'POST',
        data: {
          email: formdata.email
        }
      }).then(function (response) {
        setResponseresult({
          type: 'success',
          message: response.data.message
        });
        setLoading(false);
      });
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setResponseresult({
          type: 'error',
          message: err.response.data
            ? err.response.data.message
            : t('UserNotFound')
        });
      } else {
        setResponseresult({
          type: 'error',
          message: t('UserNotFoundTryAgain')
        });
      }
      setLoading(false);
    }
  };

  return (
    <section
      className='section-content padding-y bg-white'
      style={{ borderTop: '1px solid #f1f3f7' }}>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='card box'>
              <div className='card-body'>
                <form className='for-form' onSubmit={handleSubmit(onSubmit)}>
                  <div className='col-12 col-md-6 mx-auto'>
                    <h1 className='text-center'>{t('ResetYourPassword')}</h1>
                    {responseResult.message && (
                      <Alert
                        type={responseResult.type}
                        children={responseResult.message}
                      />
                    )}
                     <div className={`form-group input-group ${errors.email && errors.email.type === "required" && 'error'}`}>
                <div className="input-group-prepend">
                  <span className="input-group-text border-0"><FontAwesomeIcon icon={faEnvelope} /></span>
                </div>
                <input
                  id="email"
                  className={`form-control `}
                  name="email"
                  placeholder={t('SignInPage.EmailAddress')}
                  type="email"
                  {...register('email', { required: true, maxLength: 50 })}
                  autoComplete="off"
                />

              </div>

                    {loading ? (
                      <CustomLoader style={{ textAlign: 'center' }} />
                    ) : (
                      <div className='form-group'>
                        <button
                          type='submit'
                          className='btn btn-primary btn-block'>
                          {t('ResetPassword')}
                        </button>
                      </div>
                    )}
                    {/* {!passwordsent ? (
                      <>
                        
                      </>
                    ) : (
                      <>
                        <p className='text-center'>
                          <FontAwesomeIcon
                            icon={faInbox}
                            className='fa-8x text-success'
                          />
                        </p>
                        <p className='text-center'>
                          An email has been sent. It contains a link you must
                          click to reset your password.
                          <br />
                          If you didn't get an email check your spam folder or
                          try again.
                          <br />
                        </p>
                      </>
                    )} */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Forgotpassword;
