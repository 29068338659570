import React, { useEffect, useState } from 'react';
import Sidebar from '../../../sections/my-account/organizer/sidebar/Sidebar';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import Card from '../../../sections/ui/Card';
import LabeledInput from '../../../sections/ui/formfields/LabeledInput';
import axios from 'axios';
import CustomLoader from '../../../sections/ui/CustomLoader';
import { useAlert } from 'react-alert';
import Alert from '../../../sections/ui/Alert';
import { API_DOMAIN } from '../../../Config';
import { useTranslation } from 'react-i18next';

const initialErrorState = [{ message: '' }, { message: '' }, { message: '' }];

export default function ChangePassword(props) {
  const {t, i18n} = useTranslation('common');
  const loggedInUser = localStorage.getItem('user');
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState();
  const [password, setPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const [error, setError] = useState('');
  const [errors, setErrors] = useState(initialErrorState);
  // const [successMessage, setSuccessMessage] = useState('');
  const alert = useAlert();

  useEffect(() => {
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (
        foundUser.user_detail.role_id !== 2 &&
        foundUser.user_detail.role_id !== 3
      ) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, [loggedInUser]);

  const updatePasswordHandler = async () => {
    if (!oldPassword) {
      setErrors(state => [
        ...state,
        (state[0].message = t('CurrentPasswordRequired'))
      ]);
      return;
    }
    if (!password) {
      setErrors(state => [
        ...state,
        (state[1].message = t('NewPasswordRequired'))
      ]);
      return;
    }
    if (!repeatPassword) {
      setErrors(state => [
        ...state,
        (state[2].message = t('RepeatPasswordRequired'))
      ]);
      return;
    }
    if (password !== repeatPassword) {
      setErrors(state => [
        ...state,
        (state[2].message = t('PasswordMatchRequired'))
      ]);
      return;
    }
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${JSON.parse(loggedInUser).access_token}`
    };

    try {
      setLoading(true);
      const { data } = await axios.post(
        `${API_DOMAIN}api/auth/update-profile`,
        {
          old_password: oldPassword,
          password,
          password_confirmation: repeatPassword
        },
        {
          headers
        }
      );
      // if (data.success) {
      //   setSuccessMessage('Password Updated');
      // } else {
      //   setError(data.message);
      // }
      if (data.success) {
        alert.success(t('PasswordUpdated'));
        setOldPassword('');
        setPassword('');
        setRepeatPassword('');
        setErrors(initialErrorState);
      }
      setError('');
    } catch (err) {
      setError(err.response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Breadcrumbs active='change-password' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='change-password' />
            <div className='col-lg-9 mt-4 mt-lg-0'>
              <Card>
                {error && <Alert type='error'>{error}</Alert>}
                {/* {successMessage && (
                  <Alert type='success'>{successMessage}</Alert>
                )} */}
                <LabeledInput
                  type='password'
                  label={t('ChangePasswordPage.CurrentPassword')}
                  id='current-password'
                  value={oldPassword}
                  onChange={e => setOldPassword(e.target.value)}
                  errorMessage={errors[0].message}
                  required
                />
                <LabeledInput
                  type='password'
                  label={t('ChangePasswordPage.NewPassword')}
                  id='new-password'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  errorMessage={errors[1].message}
                  required
                />
                <LabeledInput
                  type='password'
                  label={t('ChangePasswordPage.RepeatNewPassword')}
                  id='repeat-new-password'
                  value={repeatPassword}
                  onChange={e => setRepeatPassword(e.target.value)}
                  errorMessage={errors[2].message}
                  required
                />

                {loading ? (
                  <CustomLoader />
                ) : (
                  <button
                    className='btn btn-primary'
                    type='submit'
                    onClick={updatePasswordHandler}>
                    {t('ChangePasswordPage.ButtonText')}
                  </button>
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
