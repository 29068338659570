import Alert from '../../../../sections/ui/Alert';
import React, { useState, useEffect } from 'react';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import Popup from '../../../../sections/ui/Popup/Popup';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import OrderDetail from '../../attendee/OrderDetail';
import { useHistory } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import BookedTicketItem from './BookedTicketItem';
import { API_DOMAIN } from '../../../../Config';
import axios from 'axios';
import 'jquery/dist/jquery.min.js';
import {EVENTS_FILTER_API} from '../../../../APIConfig';
import $ from 'jquery';
import {useTranslation} from "react-i18next";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import CustomSelect from '../../../../sections/ui/formfields/CustomSelect';

const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);

function BookedTickets() {
  const {t, i18n} = useTranslation('common');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [bookings, setBookings] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const history = useHistory();
  const perPage = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState();
  const initialFilters = {
    sort_by: 2,
    category_id: null,
    status: 2
  };
  const initialFormData = {
    event_id:{
      label: t('MyEventPage.AllEvents'),
      value: 0
    }
  };
  const [filters, setFilters] = useState(initialFilters);
  const [eventData, setEventData] = useState([]);
  const [alleventData, setAllEventData] = useState([]);
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const {
    event_id
  } = formData;
  $(document).ready(function () {
   
  });

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (!foundUser.user_detail.role_id) {
        history.push('/signin');
      }
    } else {
      history.push('/signin');
    }
  }, [history, currentPage]);

  const getBookings = async (id) => {
    try {
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${authData && authData.access_token}`,
        Accept: 'application/json'
      };
      const { data } = await axios.get(`${API_DOMAIN}api/bookings/booked-tickets?page=${currentPage}&eventid=${id}`,
        { headers }
      );
      setBookings({ data: data.data });
      setLoading(false);
      
      setTimeout(()=>{
        if ( ! $.fn.DataTable.isDataTable('#test')) {
          $('#test').DataTable({
          
            "scrollX": true
        });
    }
      },5)
        try{
        if(data.data.length>0)
        {
        var totalincome=data.data.reduce((total,num)=>{
          return total+parseFloat(num.total_income.toString())          
        },0)
        totalincome=totalincome.toFixed(2) 
        var totalcheckins=data.data.reduce((total,num)=>{
          return total+parseFloat(num.checked_in.toString())         
        },0);

        var totalquantity=data.data.reduce((total,num)=>{
          return total+parseFloat(num.total_booked.toString())          
        },0);
        var totalfree=data.data.reduce((total,num)=>{
          return total+parseFloat(num.total_booked_free.toString())          
        },0);
        $("#test").append("<tfoot><tr><td><b>Totals</b></td><td></td><td>"+totalquantity+"</td><td>"+totalfree+"</td><td>"+totalcheckins+"</td><td>"+totalincome+"</td><td></td></tr></tfoot>")
        }
      }
      catch(e){}
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getEvents = async () => {
      try {
        setLoading(true);
        const headers = {
          Authorization: `Bearer ${authData && authData.access_token}`
        };
        const { data } = await axios.post(
          `${EVENTS_FILTER_API}?page=${currentPage}`,
          filters,
          {
            headers
          }
        );

       setAllEventData(data);
       let catArray = data.myevents.data.map(cat => ({
        label: cat.title,
        value: cat.id
      }));

      catArray.unshift({
        label: t('MyEventPage.AllEvents'),
        value: 0
      })
      setEventData(catArray);
        setLoading(false);
        getBookings(0)
      } catch (err) {
        setLoading(false);
      }
    };

    getEvents();
  }, [filters, currentPage, reload]);

  const getEvents = async () => {
    try {
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${authData && authData.access_token}`
      };
      const { data } = await axios.post(
        `${EVENTS_FILTER_API}?page=${currentPage}`,
        filters,
        {
          headers
        }
      );

     setAllEventData(data);
     let catArray = data.myevents.data.map(cat => ({
      label: cat.title,
      value: cat.id
    }));
    catArray.unshift({
      label: t('MyEventPage.AllEvents'),
      value: 0
    })

    setEventData(catArray);
    setLoading(false);
    getBookings(0);
    } catch (err) {
      setLoading(false);
    }
  };

  const paginationHandler = pageNumber => {

    setCurrentPage(pageNumber);
    getBookings(0);
  };
  return (
    <>
      <Breadcrumbs active='' />
      <Popup
        className='modal-lg modal-dialog-scrollable'
        openModal={showPopup}
        closeModal={() => setShowPopup(false)}
        title={
          <>
            <FontAwesomeIcon icon={faTicketAlt} className='mr-1' />
            {t('AttendeeDashoboardPage.BookingDetails')}
          </>
        }
        footer='close'>
        <OrderDetail order={order} />
      </Popup>
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='booked-tickets' />
            <div className='col-lg-9'>

              <Card
                title={`${!loading && bookings && bookings.data
                    ? bookings.data.length
                    : 0
                  } bookings found.`}>
                    <div className='row'>
              <div className='col-sm-6 col-lg-6'>
              <CustomSelect
                          className='custom_select-div'
                          required={true}
                          label={t('MyEventPage.SelectEvent')}
                          options={eventData}
                          name='event_id'
                          isClearable={false}
                          isSearchable={false}
                          value={event_id}
                          handleChange={e => {
                          getBookings(e.value)
                          setFormData(state => ({
                            ...state,
                            event_id: e
                          }));
                          }}
                        />
              </div>
             </div> 
                <div className='pb-3 px-3 pt-1'>
                  <div className='row'>
                    <div className='col-12 col-md-12'>
                      {loading || !bookings.data ? (
                        <CustomLoader
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {!loading && error && <Alert type='error'>{error}</Alert>}
                      {!loading &&
                        bookings &&
                        bookings.data &&
                        bookings.data.length <= 0 && (
                          <Alert type='error'>{t('NoBookingsFound')}</Alert>
                        )}
                      {!loading &&
                        bookings &&
                        bookings.data &&
                        bookings.data.length > 0 && (
                          <>
                          <div>

                         
                            <Table className='table table-responsive-md table-striped custom-table' id='test'>
                              <Thead className='thead-light'>
                                <Tr>
                                  <Th>{t('EventName')}</Th>
                                  <Th>{t('TicketTitle')}</Th>
                                  <Th>{t('BookedQty')}</Th>
                                  <Th>{t('FreeBookedQty')}</Th>
                                  <Th>{t('CheckedInQty')}</Th>
                                  <Th>{t('TotalIncome')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.PaymentStatus')}</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {bookings &&
                                  bookings.data &&
                                  bookings.data.map(booking => (
                                    <BookedTicketItem
                                      booking={booking}
                                      setShowPopup={setShowPopup}
                                      setOrder={setOrder}
                                    />
                                  ))}
                                 
                              </Tbody>
                              
                            </Table>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BookedTickets;
