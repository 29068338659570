import Alert from '../../../sections/ui/Alert';
import React, { useEffect, useState } from 'react';
import Sidebar from '../../../sections/my-account/attendee/sidebar/Sidebar';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import Card from '../../../sections/ui/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import MercItem from '../../../sections/my-account/attendee/cart/MercItem';
// import Popup from '../../../sections/ui/Popup/Popup';
import { useAlert } from 'react-alert';
import axios from 'axios';
import { API_DOMAIN } from '../../../Config';
import CustomLoader from '../../../sections/ui/CustomLoader';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {useTranslation} from "react-i18next";

const authUser = localStorage.getItem('user');
const authData = JSON.parse(authUser);
// set header for sending requests
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`,
  accept: 'application/json'
};
function Dashboard(props) {
  const {t, i18n} = useTranslation('common');
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [loadFav, setLoadFav] = useState(true);
  const alert = useAlert();

  useEffect(() => {
    if (authUser) {
      if (!authData.user_detail.role_id) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  useEffect(() => {
    const getFavorites = async () => {
      try {
        setLoading(true);
        const { data } = await axios.post(
          `${API_DOMAIN}api/favorite/show`,
          {},
          { headers }
        );
        setFavorites(data.data);
        setLoading(false);
      } catch (error) {
        setError(t('TryAgain'));
        setLoading(false);
      }
    };
    getFavorites();
  }, [axios, loadFav]);

  const removeAsFavorite = async eventId => {
    if (window.confirm(t('RemoveFromFavorite'))) {
      try {
        setLoading(true);
        await axios.get(`${API_DOMAIN}api/favorite/delete/${eventId}`, {
          headers
        });
        alert.success(t('RemovedToFavorite'));
        setLoading(false);
        setLoadFav(state => !state);
      } catch (error) {
        alert.error(t('TryAgain'));
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Breadcrumbs active='my-favorites' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='my-favorites' />
            <div className='col-lg-9'>
              <Card title={`${favorites.length} ${t('favoritesfound')}`}>
                <div className='pb-3 px-3 pt-1'>
                  <div className='row no-gutters'>
                    <div className='col-12 col-md-12'>
                      {loading ? (
                        <CustomLoader
                          style={{
                            textAlign: 'center'
                          }}
                        />
                      ) : error ? (
                        <Alert type='error'>{error}</Alert>
                      ) : favorites && favorites.length <= 0 ? (
                        <>
                          <Alert type='error'>{t('NoFavoritesFound')}</Alert>
                        </>
                      ) : (
                        <table className='table table-responsive-md table-striped custom-table'>
                          <thead className='thead-light'>
                            <tr>
                              <th>{t('EventTitle')}</th>
                              <th>{t('Addedon')}</th>
                              <th>{t('Actions')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {favorites.map(favorite => (
                              <tr key={favorite.id}>
                                <td>
                                  {favorite.event_detail && (
                                    <Link
                                      to={`/event-details/${favorite.event_detail.slug}/${favorite.event_id}`}>
                                      {favorite.event_detail.title}
                                    </Link>
                                  )}
                                </td>
                                <td>
                                  {moment(favorite.created_at).format(
                                    'DD-MMM-YYYY'
                                  )}
                                </td>
                                <td>
                                  <span
                                    className='text-muted ml-2'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      removeAsFavorite(favorite.event_id);
                                    }}
                                    title={t('RemoveFavoriteBookingDetails')}>
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      color='red'
                                    />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard;
