import { faPlus, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { DELETE_Supplement_API, GET_Supplement_API} from '../../../../APIConfig';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../../sections/ui/Alert';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
// import CustomSelect from '../../../../sections/ui/formfields/CustomSelect'
import TicketSupplimentForm from './TicketSupplimentForm';
import TicketSupplimentItem from './TicketSupplimentItem';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useTranslation } from 'react-i18next';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import $ from 'jquery';
import { CSVLink } from "react-csv";
import { API_DOMAIN } from '../../../../Config';

const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`
};

const TicketSuppliment = props => {
  const { t, i18n} = useTranslation('common');
  const [supplements, setSupplements] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [edit, setEdit] = useState(false);
  const [supplement, setSupplement] = useState(null);
  const [reloadSupplements, setReloadSupplements] = useState(false);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (foundUser.user_detail.role_id !== 3) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  useEffect(() => {
    const fetchSupplements = async () => {
      setLoading(true);
      setReloadSupplements(false);
      try {
        const { data } = await axios.post(GET_Supplement_API, {},{ headers });
        if (data.success) {
          setSupplements(data.data);
        } else {
          setSupplements([]);
        }
      } catch (error) {
      }
      setLoading(false);
      setTimeout(()=>{
        if ( ! $.fn.DataTable.isDataTable('#table1')) {
        $('#table1').DataTable({
          "scrollX": true
      });}
      },5)
     
    };
    fetchSupplements();
  }, [reloadSupplements, axios]);

  const showAlert = (message, error) => {
    if (error === 'error') {
      setError(true);
      setMessage(message);
    } else if (error === 'success') {
      setReloadSupplements(true);
      setShowAddForm(false);
      setError(false);
      setMessage(message);
    }
  };
  const showEdit = supplement => {
    setEdit(true);
    let supplementTypeObj = { label: '', value: 0 };
    if (supplement.supplement_type === 1) {
      supplementTypeObj = { label: t('Single'), value: 1 };
    }
    else if (supplement.supplement_type === 2) {
      supplementTypeObj = { label: t('Multiple'), value: 2 };
    } 
    else if (supplement.supplement_type === 3) {
      supplementTypeObj = { label: t('Text'), value: 3 };
    } 

    let requiredObj = { label: t('No'), value: 0 };
    if (supplement.supplement_required === 1) {
      requiredObj = { label: t('Yes'), value: 1 };
    }

   let supplement_applied_custom={ label: t('ticketsupplement'), value: 1 }
   if (supplement.supplement_applied === 2) {
    supplement_applied_custom = { label: t('ordersupplement'), value: 2 };
  }
  let supplement_multiple_type_custom={ label: 'Checkbox', value: 0 }
  if (supplement.supplement_multiple_type === 1) {
    supplement_multiple_type_custom = { label: 'Radio', value: 1 };
 }

 let generated_code_custom = { label: t('No'), value: 0 };
    if (supplement.generated_code === 1) {
      generated_code_custom = { label: t('Yes'), value: 1 };
    }


    let supplement_custom_options = JSON.parse(supplement.supplement_option)
    const supplementData = {
      ...supplement,
      supplement_id: supplement.id,
      supplement_type: supplementTypeObj,
      supplement_required:requiredObj,
      supplement_price: supplement.supplement_price,
      supplement_option : supplement_custom_options,
      supplement_applied : supplement_applied_custom,
      supplement_multiple_type : supplement_multiple_type_custom,
      generated_code:generated_code_custom,
      start_date:new Date(supplement.start_date),
      end_date:new Date(supplement.end_date),
    };
   

    setSupplement(supplementData);
    setShowAddForm(true);
  };

  // delete supplement
  const deleteSupplement = async id => {
    if (window.confirm(t('DeleteThisSupplement'))) {
      try {
        await axios.post(DELETE_Supplement_API, { id: id }, { headers });
        showAlert(t('SupplementDeleteSuccessfully'), 'success');
      } catch (error) {
        console.error(error.response.message);
        showAlert(
          t('SupplementNotDelete'),
          'error'
        );
      }
    }
  };
  return (
    <>
      <Breadcrumbs active='Ticket Suppliments' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='ticketsuppliments' />
            <div className='col-lg-9'>
              <Card>
                <header className='section-heading mb-3'>
                  <h4 className='title-section'>
                    <FontAwesomeIcon icon={faTags} className='fa-w-14 mr-2' />
                    {t('TicketSuppliments')}
                  </h4>
                </header>
                {message && (
                  <div
                    className={`alert alert-${error ? 'danger' : 'success'}`}>
                    {message}
                  </div>
                )}

                
                <div className='box shadow-none bg-gray mb-4'>
                  <div className='row'>

 
                    <div className='col-sm-12 col-md-12 d-flex align-items-end justify-content-center'>
                      {!showAddForm && (
                        <button
                          onClick={() => {
                            setShowAddForm(prevState => !prevState);
                          
                          }}
                          className='btn btn-primary ml-3'>
                          <FontAwesomeIcon icon={faPlus} className='mr-2' /> {t('addsupplement')}
                        </button>
                      )}
                      {(showAddForm) && (
                        <button
                          onClick={() => {
                            setSupplement(null);
                            setEdit(false);
                            setShowAddForm(false);
                            setTimeout(()=>{
                              if ( ! $.fn.DataTable.isDataTable('#table1')) {
                              $('#table1').DataTable({
                                "scrollX": true
                            });}
                            },5)
                          }}
                          className='btn btn-success ml-3'>
                          {t('CouponPage.CancelButton')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {showAddForm && (
                  <div className='box shadow-none bg-gray mb-4'>
                    <div className='row'>
                      <TicketSupplimentForm
                        edit={edit}
                        showAlert={showAlert}
                        supplement={supplement}
                      />
                    </div>
                  </div>
                )}

                {loading && <CustomLoader style={{ textAlign: 'center' }} />}
                {!loading && supplements.length <= 0 && !showAddForm && (
                  <Alert>{t('NoSuplementfound')}</Alert>
                )}
                {!loading && supplements.length > 0 && !showAddForm && (
                  <div>
                  <Table id='table1' className='table table-responsive-md table-striped custom-table'>
                    <Thead className='thead-light'>
                      <Tr>
                        <Th>{t('CouponPage.Actions')}</Th>
                        <Th>{t('SupplementTitle')}</Th>
                        <Th>{t('SupplementType')}</Th>
                        <Th>{t('SupplementRequired')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {supplements.map(supplement => (
                        <TicketSupplimentItem
                          showEdit={showEdit}
                          supplement={supplement}
                          key={supplement.id}
                          deleteSupplement={deleteSupplement}
                        />
                      ))}
                    </Tbody>
                  </Table>
                  </div>
               
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default TicketSuppliment;
