import React from 'react';
import StarRating from '../StarRating';
import moment from 'moment';
import { BACKEND_URL } from '../../../APIConfig';

function SingleReview(props) {
  const userAvatar = { background: `url(${BACKEND_URL}${props.userimage})` };
  // let calcStyle = { background: 'url(/assets/user-default-image.png)' };
  // if (props.avatar) {
  //   calcStyle = { background: 'url(' + props.avatar + ')' };
  // }
  return (
    <div className='user-review-wrapper mb-5'>
      <div className='mt-3'>
        <span className='avatar' style={userAvatar}></span>
        <h6 className='user-fullname display-inline-block ml-2'>
          {props.userName}
        </h6>
      </div>
      <div className='rating-wrap mt-3'>
        <StarRating rating={props.rating} />
        <div className='label-rating ml-2'>{props.rating} out of 5 stars</div>
      </div>
      <small className='text-muted mt-1'>
        {moment(props.createdDate).format('dddd DD MMM YYYY HH:mm')}
      </small>
      <h6 className='mt-4 font-weight-bold'>{props.reviewHeading}</h6>
      <p className='mt-2 text-sm readmore'>{props.description}</p>
    </div>
  );
}

export default SingleReview;
