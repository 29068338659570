import React, { useEffect, useState } from 'react';
import Sidebar from '../../../sections/my-account/attendee/sidebar/Sidebar';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import Card from '../../../sections/ui/Card';
import LabeledInput from '../../../sections/ui/formfields/LabeledInput';
import 'react-datepicker/dist/react-datepicker.css';
import CustomFileInput from '../../../sections/ui/formfields/CustomFileInput';
import axios from 'axios';
import { API_DOMAIN } from '../../../Config';
import { BACKEND_URL } from '../../../APIConfig';
import CustomLoader from '../../../sections/ui/CustomLoader';
import Alert from '../../../sections/ui/Alert';
import { useAlert } from 'react-alert';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomSelect from '../../../sections/ui/formfields/CustomSelect';
import {useTranslation} from "react-i18next";
import { useSelector } from 'react-redux';

const loggedInUser = localStorage.getItem('user');
const userDetails = JSON.parse(loggedInUser);
const headers = {
  Authorization: `Bearer ${userDetails && userDetails.access_token}`
};

export default function Settings(props) {
  const {t, i18n} = useTranslation('common');

  const [user, setUser] = useState({
    email: '',
    firstName: '',
    lastName: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    phone: '',
    address: '',
    avatar: '',
    gender: '',
    id: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [updateUserLoading, setUpdateUserLoading] = useState();
  const [userUpdated, setUserUpdated] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [showAddAvatar, setShowAddAvatar] = useState(false);
  const alert = useAlert();
  const [gender, setGender] = useState({ label: '', value: '' });
  const [countryselect, SetCountrySelect] = useState({
    label: "Belgium",
    value: "BE"
  });
  const [countryData, setCountryData] = useState([]);
  const countriesData = useSelector(state => state.countries.countries);
  useEffect(() => {
    if (loggedInUser) {
      if (userDetails.user_detail.role_id !== 2) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  useEffect(() => {
    let country_data = {};
    if (countriesData && countriesData.length > 0) {
      let countriesArray = countriesData.map(country => ({
        label: country.country_name,
        value: country.country_code
      }));
      setCountryData(countriesArray);
      countriesData.forEach(item=>{
        country_data[item.country_code] = item.country_name
      })
    }
    const getUser = async () => {
      try {
        setLoading(true);
        const { data } = await axios.post(
          `${API_DOMAIN}api/auth/me`,
          {},
          {
            headers
          }
        );
        const userData = {
          id: data.data.id,
          email: data.data.email,
          firstName: data.data.name.split(' ')[0]
            ? data.data.name.split(' ')[0]
            : '',
          lastName: data.data.name.split(' ')[1]
            ? data.data.name.replace(data.data.name.split(' ')[0],'').trim()
            : '',
          phone: data.data.phone ? data.data.phone : '',
          address: data.data.address ? data.data.address : '',
          avatar: data.data.avatar ? data.data.avatar : '',
          organiser_logo: data.data.organiser_logo ? data.data.organiser_logo : '',
          organisation: data.data.organisation ? data.data.organisation : '',
          bankName: data.data.bank_name ? data.data.bank_name : '',
          bankCode: data.data.bank_code ? data.data.bank_code : '',
          bankBranchName: data.data.bank_branch_name
            ? data.data.bank_branch_name
            : '',
          bankBranchCode: data.data.bank_branch_code
            ? data.data.bank_branch_code
            : '',
          bankAccountNumber: data.data.bank_account_number
            ? data.data.bank_account_number
            : '',
          bankAccountName: data.data.bank_account_name
            ? data.data.bank_account_name
            : '',
          bankAccountPhone: data.data.bank_account_phone
            ? data.data.bank_account_phone
            : '',
          gender: data.data.gender ? data.data.gender : ''
          // facebook: data.data.facebook ? data.data.facebook : '',
          // twitter: data.data.twitter ? data.data.twitter : '',
          // instagram: data.data.instagram ? data.data.instagram : '',
          // linkedin: data.data.linkedin ? data.data.linkedin : ''
        };

        SetCountrySelect(
          {
            label: data.data.country_code ? country_data[data.data.country_code] : '',
            value: data.data.country_code ? data.data.country_code : ''
          })
        setGender({
          label: data.data.gender ? data.data.gender : '',
          value: data.data.gender ? data.data.gender : ''
        });
        setUser(userData);
        setError('');
      } catch (err) {
        setError(t('AddedToFavoriteError'));
      }
      setLoading(false);
    };
    getUser();
  }, [countriesData]);


  const onChangeHandler = e => {
    setUser(state => ({
      ...state,
      [e.target.name]: e.target.value
    }));
  };

  const updateProfileHandler = async () => {
    try {
      setUpdateUserLoading(true);
      const formData = new FormData();
      formData.append('first_name', user.firstName);
      formData.append('last_name', user.lastName);
      formData.append('phone', user.phone);
      formData.append('address', user.address);

      formData.append('street', user.street);
      formData.append('house_number', user.houseNumber);
      formData.append('postal_code', user.postalCode);
      formData.append('gender', gender.value);
      formData.append('country_code', countryselect.value);
      if (avatar) {
        formData.append('avatar', avatar);
      }
      await axios.post(`${API_DOMAIN}api/auth/update-profile`, formData, {
        headers
      });
      alert.success(t('Profileupated'));
      setAvatar();
      setUserUpdated(state => !state);
    } catch (err) {
      alert.error(t('SomethingWentWrong'));
    }
    setUpdateUserLoading(false);
  };

  const fileHandler = e => {
    setAvatar(e.target.files[0]);
  };

  return (
    <>
      <Breadcrumbs isHome active='account-settings' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='account-settings' />
            <div className='col-lg-9'>
              <Card>
                {loading && <CustomLoader style={{ textAlign: 'center' }} />}
                {!loading && error && <Alert type='error'>{error}</Alert>}
                {!loading && !error && user && (
                  <>
                    <div className='row'>
                      <div className='col-12 col-md-12'>
                        <div style={{ textAlign: 'center' }}>
                          <div style={{ position: 'relative' }}>
                            <img
                              src={`${BACKEND_URL}${user.avatar}`}
                              alt='test'
                              style={{
                                width: 100,
                                height: 100,
                                borderRadius: '50%'
                              }}
                            />
                            <span
                              style={{
                                fontSize: 20,
                                position: 'absolute',
                                bottom: 0,
                                marginLeft: -25,
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                setAvatar();
                                setShowAddAvatar(state => !state);
                              }}>
                              <FontAwesomeIcon icon={faEdit} color='#31c5f4' />
                            </span>
                          </div>
                          {showAddAvatar && (
                            <CustomFileInput
                              label={t('ProfilePicture')}
                              id='organizer_profile'
                              filename={avatar && avatar.name}
                              handleFile={fileHandler}
                            />
                          )}
                        </div>
                      </div>
                      <div className='col-12 col-md-12'>
                        <LabeledInput
                          type='email'
                          label={t('Email')}
                          id='email'
                          name='email'
                          placeholder='example@example.com'
                          value={user.email}
                          onChange={onChangeHandler}
                          disabled
                        />
                      </div>
                      <div className='col-12 col-md-6'>
                        <LabeledInput
                          type='text'
                          label={t('OrganizerProfile.FirstName')}
                          id='firstName'
                          name='firstName'
                          placeholder='Jhon'
                          value={user.firstName}
                          onChange={onChangeHandler}
                          required
                        />
                      </div>
                      <div className='col-12 col-md-6'>
                        <LabeledInput
                          type='text'
                          label={t('OrganizerProfile.LastName')}
                          id='lastName'
                          name='lastName'
                          placeholder='Doe'
                          value={user.lastName}
                          onChange={onChangeHandler}
                          required
                        />
                      </div>
                      <div className='col-12 col-md-12'>
                        <LabeledInput
                          type='text'
                          label={t('PhoneNo')}
                          id='phone'
                          name='phone'
                          placeholder=''
                          value={user.phone}
                          onChange={onChangeHandler}
                        />
                      </div>
                      <div className='col-12 col-md-6'>
                        <CustomSelect
                          className='custom_select-div'
                          label={t('Gender')}
                          defaultValue={gender}
                          options={[
                            { label: 'Male', value: 'Male' },
                            { label: 'Female', value: 'Female' },
                            { label: 'Other', value: 'other' }
                          ]}
                          name='gender'
                          isClearable={false}
                          isSearchable={false}
                          value={gender}
                          handleChange={e => setGender(e)}
                        />
                      </div>

                      <div className='col-12 col-md-6'>
                      <CustomSelect
              className='custom_select-div'
              required={true}
              label={t('Country')}
              options={countryData}
              name='country'
              isClearable={false}
              value={countryselect}
              handleChange={e => {
               SetCountrySelect(e)
              }}
            />
                      </div>

                      <div className='col-12 col-md-6'>
                        <LabeledInput
                          type='text'
                          label={t('HouseNo')}
                          id='houseNumber'
                          name='houseNumber'
                          placeholder='House Number'
                          value={user.houseNumber}
                          onChange={onChangeHandler}
                        />
                      </div>
                      <div className='col-12 col-md-6'>
                        <LabeledInput
                          type='text'
                          label={t('Street')}
                          id='street'
                          name='street'
                          placeholder='Street'
                          value={user.street}
                          onChange={onChangeHandler}
                        />
                      </div>
                      <div className='col-12 col-md-6'>
                        <LabeledInput
                          type='text'
                          label={t('PostalCode')}
                          id='postalCode'
                          name='postalCode'
                          placeholder='Postal Code'
                          value={user.postalCode}
                          onChange={onChangeHandler}
                        />
                      </div>

                      <div className='col-12 col-md-12'>
                        <LabeledInput
                          textArea
                          label={t('Address')}
                          id='address'
                          name='address'
                          placeholder=''
                          value={user.address}
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>
                    {updateUserLoading && <CustomLoader />}
                    {!updateUserLoading && (
                      <button
                        type='button'
                        className='btn btn-primary btn'
                        onClick={updateProfileHandler}>
                        {t('MyEventPage.UpdateButtonText')}
                      </button>
                    )}
                  </>
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
