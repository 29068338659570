import Alert from "../../../sections/ui/Alert";
import React, { useState } from "react";
import Sidebar from "../../../sections/my-account/attendee/sidebar/Sidebar";
import Breadcrumbs from "../../../sections/ui/Breadcrumbs";
import Card from "../../../sections/ui/Card";
import CartItem from "../../../sections/my-account/attendee/cart/CartItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faChevronRight,
  faCog,
  faMinusSquare,
  faSync,
  faTrash,
  faTshirt,
} from "@fortawesome/free-solid-svg-icons";
import MercItem from "../../../sections/my-account/attendee/cart/MercItem";
import Popup from "../../../sections/ui/Popup/Popup";
import {useTranslation} from "react-i18next";

function Dashboard(props) {
  const {t, i18n} = useTranslation('common');
  const [mercState, setMerc] = useState(false);
  const [mercsTotal, setmercsTotal] = useState(false);

  const setMercHandler = (e) => {
    e.preventDefault();
    setMerc(!mercState);
  };

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if(!foundUser.user_detail.role_id){
        props.history.push('/signin')
      }
    }
    else{
      props.history.push('/signin')
    }
  }, []);
  
  const addMercToCart = () => {
    setMerc(false);
    setmercsTotal(true);
  };
  const RemoveMercHandler = (e) => {
    e.preventDefault();
    setMerc(false);
    setmercsTotal(false);
  };
  return (
    <>
      <Breadcrumbs active="my-cart" />
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <Sidebar active="my-cart" cartItems={1} />
            <div className="col-lg-9">
              <Card title="0 tickets found in your cart">
                <div className="pb-3 px-3 pt-1">
                  <div className="row no-gutters">
                    <div className="col-12 col-md-8">
                      <p className="text-muted font-weight-bold m-0">
                        {t('MyFollowings')}
                      </p>
                    </div>
                    <div className="col-12 col-md-4 d-none d-md-block">
                      <div className="d-flex justify-content-between">
                        
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard;
