import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../sections/ui/Breadcrumbs'
import Card from '../../sections/ui/Card'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ThankYou = () => {
  const history = useHistory()
  const {t, i18n} = useTranslation('common');
  const orderId = history.location.state && history.location.state.orderId
  return (
    <div>
      <Breadcrumbs active='' />
      <section className='section-content bg-white padding-y'>
        <div className='container'>
          <div className='row'>
            <main
              className='col-lg-12 order-1 order-lg-0 mt-4 mt-lg-0'
              style={{
                textAlign: 'center'
              }}
            >
              <Card>
                <h1>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color='green'
                    style={{ marginRight: 10 }}
                  />
                  {t('TicketBookedSuccessfully')}
                </h1>
                <br />
              </Card>
              <Card title='Details'>
                {orderId && <h3>{t('Header.MyTickets')}: #{orderId}</h3>}
              </Card>
              <Link to='/event-list/All'>{t('CartPage.ContinueShoppingButton')}</Link>
            </main>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ThankYou
