import {
  faCalendarAlt,
  faCog,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DashItem from "../../../sections/my-account/organizer/DashItem";
import Sidebar from "../../../sections/my-account/organizer/sidebar/Sidebar";
import Breadcrumbs from "../../../sections/ui/Breadcrumbs";
import Card from "../../../sections/ui/Card";
import CustomSelect from "../../../sections/ui/formfields/CustomSelect";

function Payout(props) {
  React.useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if(foundUser.user_detail.role_id !== 3){
        props.history.push('/signin')
      }
    }
    else{
      props.history.push('/signin')
    }
  }, []);
  return (
    <>
      <Breadcrumbs active="payout-request" />
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <Sidebar active="payout-request" />
            <div className="col-lg-9 mt-4 mt-lg-0">
              <Card>
                <header className="section-heading mb-3">
                  <h4 className="title-section float-left">
                    <FontAwesomeIcon icon={faCalendarAlt} /> Payout Requests
                  </h4>
                  <a
                    href="/dashboard/organizer/my-events"
                    className="float-right"
                  >
                    <FontAwesomeIcon icon={faCog} /> My events
                  </a>
                  <div className="clearfix"></div>
                </header>
                <div className="row mb-3 pl-2 pr-2">
                  <DashItem
                    size="4"
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    title="Total Bookings"
                    val="138263.15 USD"
                  />
                  <DashItem
                    size="4"
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    title="Total Admin Commission"
                    val="2946.38 USD"
                  />
                  <DashItem
                    size="4"
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    title="Total Profit"
                    val="135316.97 USD"
                  />
                </div>
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-md-4 mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        0 result(s) found
                      </span>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <label className="mr-3">Sort by</label>
                      <div className="select_white-bg w-100">
                        <CustomSelect
                          className="custom_select-div"
                          options={[
                            {
                              label: "All Events",
                              value: "All Events",
                            },
                          ]}
                          name="sorting"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <label className="mr-3">Boking Date</label>
                      <div className="select_white-bg">
                        <CustomSelect
                          className="custom_select-div"
                          options={[
                            {
                              label: "All Events",
                              value: "All Events",
                            },
                          ]}
                          name="sorting"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <table className="table table-responsive-md table-striped custom-table">
                  <thead className="thead-light">
                    <tr>
                      <th>Event Name</th>
                      <th>Bookings</th>
                      <th>Commission</th>
                      <th>Profit</th>
                      <th>Month</th>
                      <th>Transferred</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a href="">Seven Rocks Show</a>
                      </td>
                      <td>168.75 USD</td>
                      <td>8.44 USD</td>
                      <td>160.31 USD </td>
                      <td>Oct ,2020 </td>
                      <td>
                        <a href="#" className="btn btn-sm btn-info">
                          Pending
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href="">International Yoga Day</a>
                      </td>
                      <td>497.55 USD</td>
                      <td>24.88 USD </td>
                      <td>472.68 USD </td>
                      <td>Oct ,2020 </td>
                      <td>
                        <a href="#" className="btn btn-sm btn-success">
                          Paid
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Payout;
