import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";

function BredcrumPreview(props) {
  const {t, i18n} = useTranslation('common');

  return (
    <section className='section-pagetop bg-gray'>
      <div className='container clearfix'>
        <h4 className='title-page dark b float-xl-left mb-0 notranslate'>{props.title} - Event Preview</h4>
        <nav className='float-xl-right mt-2 mt-xl-0'>
          <ol className='breadcrumb text-white'>
            <li className='breadcrumb-item'>
              <Link to='/' className='dark'>
                <FontAwesomeIcon icon={faHome} />
              </Link>
              <FontAwesomeIcon icon={faAngleRight} className='breadArrows' />
            </li>
            <li className='breadcrumb-item'>
              <Link to='/event-list/All' className='dark'>
                {t('CategoryPage.Events')}
              </Link>
              <FontAwesomeIcon icon={faAngleRight} className='breadArrows' />
            </li>
            <li className='breadcrumb-item active notranslate' aria-current='page'>
              {props.title}
            </li>
          </ol>
        </nav>
      </div>
    </section>
  );
}

export default BredcrumPreview;
