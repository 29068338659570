import Alert from '../../../../sections/ui/Alert';
import React, { useState, useEffect } from 'react';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import Popup from '../../../../sections/ui/Popup/Popup';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import OrderDetail from '../../attendee/OrderDetail';
import { useHistory } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import BookingItem from './BookingItem';
import { API_DOMAIN } from '../../../../Config';
import {EVENTS_FILTER_API } from '../../../../APIConfig';

import LabeledInput from '../../../../sections/ui/formfields/LabeledInput';
import axios from 'axios';
import 'jquery/dist/jquery.min.js';
import { renderToString,renderToStaticMarkup } from 'react-dom/server'
import CustomSelect from '../../../../sections/ui/formfields/CustomSelect';

import $ from 'jquery';
import { useTranslation } from "react-i18next";
import { faEye, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);

function Bookings() {
  const { t, i18n } = useTranslation('common');
  const [ bookingtable, setBookingTable ] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [bookings, setBookings] = useState([]);
  const [totalbookings, setTotalBookings] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showResendPopup, setShowResendPopup] = useState(false);
  const [sendEmailText, setSendEmailText] = useState('');
  const history = useHistory();
  const perPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState();
  const [message, setMessage] = useState(false);
  const [Method, setMethod] = useState('custom');
  const [eventData, setEventData] = useState([]);
  const [event, setEvent] = useState( {
    label: t('MyEventPage.SelectEvent'),
    value: '0'
  });
  const [alleventData, setAllEventData] = useState([]);
  const [filtersnonexpired, setFiltersNonExpired] = useState({
    sort_by: 2,
    category_id: null,
    status: 2,
    expiredallow:"false"
  });
  const [filtersall, setFiltersAll] = useState({
    sort_by: 2,
    category_id: null,
    status: 2
  });
  const [expiredEvents,setExpiredEvents]=useState(0);
 useEffect(()=> {
  $(document).ready(function () {
   
   
  });

 }, [])
  const showAlert = (message, error) => {
    if (error === 'error') {
      setMessage(message);
    } else if (error === 'success') {
      setMessage(message);
      alert(message)
    }
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (!foundUser.user_detail.role_id) {
        history.push('/signin');
      }
    } else {
      history.push('/signin');
    }
  }, [history, currentPage]);

  const getBookings = async (expired,event_id, update) => {
    try {
      setTimeout(()=>{
        if ( ! $.fn.DataTable.isDataTable('#table1') || update) {
          if(update)
          {
            $("#table1").dataTable().fnDestroy()
          }
       const tb = $('#table1').DataTable({
          processing: true,
          serverSide: true,
          "ajax":{
              url: `${API_DOMAIN}api/bookings/paginate_organiser_bookings?expired=${expired}&event_id=${event_id}`,
              type: "GET",
              headers:  
              {
                Authorization: `Bearer ${authData && authData.access_token}`
              },
          },
          "order": [[ 1, "desc" ]],
          columns: [ 
            { data: 'id' ,className:'pivoted',},
            { data: 'order_number' ,className:'pivoted',
            render : function(data, type, row) {
              return '<div data-testid="td-before" class="tdBefore">'+t('AttendeeDashoboardPage.Order#')+'</div>'+data
          }  },
            { data: 'created_at' ,className:'pivoted',
            render : function(data, type, row) {
              return '<div data-testid="td-before" class="tdBefore">'+t('AttendeeDashoboardPage.OrderDate')+'</div>'+data
          }},
            { data: 'customer_name' ,className:'pivoted',
            render : function(data, type, row) {
              return '<div data-testid="td-before" class="tdBefore">'+t('CustomerName')+'</div>'+data
          }},
            { data: 'event_title' ,className:'pivoted',
            render : function(data, type, row) {
              return '<div data-testid="td-before" class="tdBefore">'+t('AttendeeDashoboardPage.EventTitle')+'</div>'+data
          }},
            { data: 'ticket_title' ,className:'pivoted',
            render : function(data, type, row) {
              return '<div data-testid="td-before" class="tdBefore">'+t('AttendeeDashoboardPage.TicketType')+'</div>'+data
          }},
            { data: 'quantity' ,className:'pivoted',
            render : function(data, type, row) {
              return '<div data-testid="td-before" class="tdBefore">'+t('AttendeeDashoboardPage.Quantity')+'</div>'+data
          }},
          //   { data: 'is_paid'  ,className:'pivoted',
          //   render : function(data, type, row) {
          //     return '<div data-testid="td-before" class="tdBefore">'+t('IsPaid')+'</div>'+(data=='Yes'?t('Yes'):t('No'))
          // }},
          { data: 'total_scanned'  ,className:'pivoted',
          render : function(data, type, row) {
            return '<div data-testid="td-before" class="tdBefore">'+t('ticketscanned')+'</div>'+data
        }},
            { data: 'event_start_date' ,className:'pivoted',
            render : function(data, type, row) {
              return '<div data-testid="td-before" class="tdBefore">'+t('AttendeeDashoboardPage.EventStartDateTime')+'</div>'+data
          }}
         ],
         "drawCallback": function (settings) { 
          var response = settings.json;
          setTotalBookings(response.iTotalRecords)
          var bookings=response.aaData.map((ar)=> { return ar.current_records; })
          $(".viewdetail").click((ev)=>{
            var index=ev.currentTarget.attributes[0].value;
            setOrder(bookings[index]);

            setShowPopup(true);
          })
          $(".resendemail").click((ev)=>{
          var index=ev.currentTarget.attributes[0].value;
          setSendEmailText('')
          setMethod("custom")

          setOrder(bookings[index]);
          setShowResendPopup(true)
          })
        },
      "createdRow": function( row, data, dataIndex ) {
      
      },
      "scrollX": true
      });

      setBookingTable(tb)
    }
      },5)
    } catch (err) {
      setLoading(false);
    }
  };

  const getEvents = async (filters) => {
    try {
      const headers = {
        Authorization: `Bearer ${authData && authData.access_token}`
      };
      const { data } = await axios.post(
        `${EVENTS_FILTER_API}?page=${currentPage}`,
        filters,
        {
          headers
        }
      );
     setAllEventData(data);
     let catArray = data.myevents.data.map(cat => ({
      label: cat.title,
      value: cat.id
    }));

    setEventData(catArray);
    } catch (err) {
    }
  };

  useEffect(() => {
    getBookings('true',0,false);   
    getEvents(filtersall);
  }, []);

  const checked_in =  async orderDetail => {

   let quantity_selected = orderDetail.quantity==1?1:prompt(t('selectquantity'));
    if (quantity_selected && !isNaN(parseInt(quantity_selected))) {
      if(orderDetail.quantity!=1 || window.confirm(t('bookcancelmessage')))
      {
      try {
        const headers = {
          Authorization: `Bearer ${authData && authData.access_token}`
        };
        const { data } = await axios.post(
          `${API_DOMAIN}api/verify_ticket`,
          {
            booking_id:orderDetail.id,
            type:'standard',
            order_number:orderDetail.order_number,
            ticket_ids:[orderDetail.ticket_id],
            page_no:parseInt(quantity_selected)
          },
          { headers }
        );
        if(data["status"])
        {
          alert(data["message"])
          setShowPopup(false);
          setOrder();
          bookingtable.ajax.reload( null, false )
        }
        else
        {
          alert(data["message"])
        }
      } catch (err) {

        alert(t('bookingcheckinfailed'))
      }
    }
    }
    else
    {
      alert(t('bookingstausfailed'))
    }
  
  };


  const resendEmail = async (id, orderid) => {
    try {
     
      const headers = {
        Authorization: `Bearer ${authData && authData.access_token}`
      };
      const { data } = await axios.get(
        `${API_DOMAIN}api/resend-ticket/${id}/${orderid}?to_mail=${sendEmailText}`,
        { headers }
      );
      
      showAlert(t('MailSentSuccessfully'), 'success');
    } catch (err) {
    
    }
    
  };
  const onChange = e => {
    setSendEmailText(e.target.value)
  };
 
  const sendEmailHandler = async e => {
    setShowResendPopup(false);
    resendEmail(order.id, order.order_number.toString());
  };

   const cancelBooking = async type => {
    if (window.confirm(t('bookcancelmessage'))) {
      try {
        const headers = {
          Authorization: `Bearer ${authData && authData.access_token}`
        };
        const { data } = await axios.get(
          `${API_DOMAIN}api/bookings/cancel-booking/${order.id}/${order.order_number}/${type}`,
          { headers }
        );
        if(data["status"])
        {
          alert(t('bookingstausupdated'))
          setShowPopup(false);
          setOrder();
          bookingtable.ajax.reload( null, false )
        }
        else
        {
          alert(t('bookingstausfailed'))
        }
      } catch (err) {

        alert(t('bookingstausfailed'))
      }
    }

  };

  return (
    <>
      <Breadcrumbs active='' />
      <Popup
        className='modal-lg modal-dialog-scrollable'
        openModal={showPopup}
        closeModal={() => setShowPopup(false)}
        title={
          <>
            <FontAwesomeIcon icon={faTicketAlt} className='mr-1' />
            {t('AttendeeDashoboardPage.BookingDetails')}
          </>
        }
        footer='close'>
        <OrderDetail order={order} cancelBooking={cancelBooking} checked_in={checked_in}/>
      </Popup>
      <Popup
        className='modal-lg modal-dialog-scrollable'
        openModal={showResendPopup}
        closeModal={() => setShowResendPopup(false)}
        title={
          <>
            <FontAwesomeIcon icon={faTicketAlt} className='mr-1' />
            {t('AttendeeDashoboardPage.BookingDetails')}
          </>
        }
        footer={
          <>
            <span className='close'></span>
            <span>
              {t('SendEmail')}
            </span>

          </>
        }
        footerAction={sendEmailHandler}
      >
        <>
          <div className='row'>
            <div className='col-12'>
            <legend className={`col-form-label`}>
            {t('SelectEmailType')}
            </legend>
            </div>
            <div className='col-3 form-group'>
              <div className='custom-control custom-radio custom-control-inline'>
                <input
                  id='custom'
                  type='radio'
                  name='Method'
                  value='custom'
                  checked={Method === 'custom'}
                  onChange={e => {
                    setMethod(e.target.value)
                    setSendEmailText('')
                  }
                  }
                />
                <label style={{ marginLeft: '10px', marginTop: '8px' }}>
                  Custom
                </label>
              </div>
            </div>
            <div className='col-3 form-group'>
              <div className='custom-control custom-radio custom-control-inline'>
                <input
                  id='default'
                  type='radio'
                  name='Method'
                  value='default'
                  checked={Method === 'default'}
                  onChange={e => {
                    setMethod(e.target.value)

                    setSendEmailText(order.customer_email)
                  }
                  }
                />
                <label style={{ marginLeft: '10px', marginTop: '8px' }}>
                  Default
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className='col-12 col-md-12'>
              <LabeledInput
                type='text'
                label={t('Email')}
                id='title'
                name='title'
                required
                readOnly={Method === 'default' ? true : false}
                placeholder={t('Email')}
                value={sendEmailText}
                onChange={onChange}
              />
            </div>
          </div>
        </>
      </Popup>
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='bookings' />
            <div className='col-lg-9'>
              <Card title={`${totalbookings} bookings found`}>
                <div className='pb-3 px-3 pt-1'>
                <div style={{display:'flex',alignItems:'center'}}>
                <div className='col-12 col-md-6' style={{textAlign:'left',marginBottom:'20px'}}>
              <CustomSelect
                          className='custom_select-div'
                          required={true}
                          label={t('MyEventPage.SelectEvent')}
                          options={eventData}
                          name='event_id'
                          isClearable={false}
                          isSearchable={false}
                          value={event}
                          handleChange={e => {
                            setEvent(e)
                            if(expiredEvents==1)
                             getBookings('false',e.value,true)
                            else
                             getBookings('true',e.value,true)
                          }}
                        />
              
              </div>
                <div className='col-12 col-md-6' style={{textAlign:'right',marginBottom:'20px'}}>
                 <input
                  type='checkbox'
                  id='expired_events'
                  name='expired_events'
                  checked={expiredEvents}        
                  onChange={e =>
                    {

                       setExpiredEvents(e.target.checked?1:0)
                       setEvent({
                        label: t('MyEventPage.SelectEvent'),
                        value: '0'
                      })
                       if(e.target.checked==1)
                       {
                       getEvents(filtersnonexpired)
                       getBookings('false',event.value,true)
                       }
                       else
                       {
                       getEvents(filtersall)
                       getBookings('true',event.value,true)
                       }
                       
                    }
                    }
                  placeholder='expired_events'
                  style={{marginRight: 5, paddingRight:'10px', position:'relative', top:'2px'}}
                />
                <label htmlFor='expired_events' style={{paddingLeft:'5px'}}>{t('MyEventPage.expired_events')}</label>
              </div>
              </div>
                  <div className='row'>
                    <div className='col-12 col-md-12'>
                    
                       <Table className='table table-responsive-md table-striped custom-table' id='table1'>
                       <Thead className='thead-light'>
                                <Tr>
                                <Th>
                                    <span
                                      className='text-muted ml-2'
                                      style={{ cursor: 'pointer' }}>
                                      <FontAwesomeIcon icon={faEye} color='#fff' />
                                    </span>
                                  </Th>
                                  <Th>{t('AttendeeDashoboardPage.Order#')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.OrderDate')}</Th>
                                  <Th>{t('CustomerName')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.EventTitle')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.TicketType')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.Quantity')}</Th>
                                  {/* <Th>{t('IsPaid')}</Th> */}
                                  <Th>{t('ticketscanned')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.EventStartDateTime')}</Th>
                                
                                </Tr>
                              </Thead>
                              <Tbody>

                              </Tbody>
                            </Table>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Bookings;
