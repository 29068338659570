import React, { useEffect, useState } from "react";
function Collapsible(props) {
  const [open, SetOpen] = useState(false);

  const clickHandler = (e) => {
    e.preventDefault();
    SetOpen(!open);
  };
  const handleHtml = (val) => {
    return val;
  };
  useEffect(() => {
    props.openDefault ? SetOpen(true) : SetOpen(false);
  }, []);

  return (
    <>
      <a
        href="#"
        className={`dropdown-toggle ${props.className ? props.className : ""}`}
        onClick={clickHandler}
      >
        {handleHtml(props.title)}
      </a>
      <div className={`collapse ${open ? "show" : ""}`}>{props.children}</div>
    </>
  );
}

export default Collapsible;
