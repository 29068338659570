import { faPlus, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { DELETE_Voucher_API, GET_Voucher_API } from '../../../../APIConfig';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../../sections/ui/Alert';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
import VoucherForm from './VoucherForm';
import VoucherItem from './VoucherItem';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useTranslation } from 'react-i18next';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import $ from 'jquery';

const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`
};

const Voucher = props => {
  const { t, i18n} = useTranslation('common');
  const [vouchers, setVouchers] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [edit, setEdit] = useState(false);
  const [voucher, setVoucher] = useState(null);
  const [reloadVouchers, setReloadVouchers] = useState(false);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (foundUser.user_detail.role_id !== 3) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  useEffect(() => {
    const fetchVouchers = async () => {
      setLoading(true);
      setReloadVouchers(false);
      try {
        const { data } = await axios.post(GET_Voucher_API, {},{ headers });
        if (data.success) {
          setVouchers(data.data);
        } else {
          setVouchers([]);
        }
      } catch (error) {
      }
      setLoading(false);
      setTimeout(()=>{
        if ( ! $.fn.DataTable.isDataTable('#table1')) {
        $('#table1').DataTable({
          "scrollX": true
      });}
      },5)
     
    };
    fetchVouchers();
  }, [reloadVouchers, axios]);

  const showAlert = (message, error) => {
    if (error === 'error') {
      setError(true);
      setMessage(message);
    } else if (error === 'success') {
      setReloadVouchers(true);
      setShowAddForm(false);
      setError(false);
      setMessage(message);
    }
  };
  const showEdit = voucher => {
    setEdit(true);
    let generated_code = { label: t('Yes'), value: 1 };
    if (voucher.generated_code == 0) {
      generated_code = { label: t('No'), value: 0 };
    }

    let voucher_type = { label: t('Voucher.VoucherTicket'), value: 1 };
    if (voucher.voucher_type == 2) {
      voucher_type = { label: t('Voucher.VoucherOrder'), value: 2 };
    }
    const voucherData = {
      ...voucher,
      id: voucher.id,
      generated_code: generated_code,
      voucher_type:voucher_type,
    };
    setVoucher(voucherData);
    setShowAddForm(true);
  };

  const deleteVoucher = async id => {
    if (window.confirm(t('Voucher.DeleteThisVoucher'))) {
      try {
        await axios.post(DELETE_Voucher_API, { id: id }, { headers });
        showAlert(t('Voucher.VoucherDeleteSuccessfully'), 'success');
      } catch (error) {
        console.error(error.response.message);
        showAlert(
          t('Voucher.VoucherNotDelete'),
          'error'
        );
      }
    }
  };
  return (
    <>
      <Breadcrumbs active='voucher' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='voucher' />
            <div className='col-lg-9'>
              <Card>
                <header className='section-heading mb-3'>
                  <h4 className='title-section'>
                    <FontAwesomeIcon icon={faTags} className='fa-w-14 mr-2' />
                    {t('DashboardPage.Voucher')}
                  </h4>
                </header>
                {message && (
                  <div
                    className={`alert alert-${error ? 'danger' : 'success'}`}>
                    {message}
                  </div>
                )}

                
                <div className='box shadow-none bg-gray mb-4'>
                  <div className='row'>
 
                    <div className='col-sm-12 col-md-12 d-flex align-items-end justify-content-center'>
                      {!showAddForm  && (
                        <button
                          onClick={() => {
                            setVoucher(null);
                            setShowAddForm(prevState => !prevState);
                          }}
                          className='btn btn-primary ml-3'>
                          <FontAwesomeIcon icon={faPlus} className='mr-2' /> {t('Voucher.Create')}
                        </button>
                      )}
                      {showAddForm  && (
                        <button
                          onClick={() => {
                            setVoucher(null);
                            setEdit(false);
                            setShowAddForm(false);
                            setTimeout(()=>{
                              if ( ! $.fn.DataTable.isDataTable('#table1')) {
                              $('#table1').DataTable({
                                "scrollX": true
                            });}
                            },5)
                          }}
                          className='btn btn-success ml-3'>
                          {t('CouponPage.CancelButton')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {showAddForm && (
                  <div className='box shadow-none bg-gray mb-4'>
                    <div className='row'>
                      <VoucherForm
                        edit={edit}
                        showAlert={showAlert}
                        voucher={voucher}
                      />
                    </div>
                  </div>
                )}

                {loading && <CustomLoader style={{ textAlign: 'center' }} />}
                {!loading && vouchers.length <= 0 && !showAddForm &&  (
                  <Alert>{t('Voucher.NoVoucherfound')}</Alert>
                )}
                {!loading && vouchers.length > 0 && !showAddForm && (
                  <div>
                  <Table id='table1' className='table table-responsive-md table-striped custom-table'>
                    <Thead className='thead-light'>
                      <Tr>
                        <Th>{t('Voucher.Actions')}</Th>
                        <Th>{t('Voucher.VoucherTitle')}</Th>
                        {/* <Th>{t('Voucher.VoucherType')}</Th> */}
                        <Th>{t('Voucher.usecode')}</Th>
                        <Th>{t('Voucher.TemplateType')}</Th>
                        <Th>{t('Voucher.CreateAt')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {vouchers.map(voucher => (
                        <VoucherItem
                          showEdit={showEdit}
                          voucher={voucher}
                          key={voucher.id}
                          deleteVoucher={deleteVoucher}
                        />
                      ))}
                    </Tbody>
                  </Table>
                  </div>
               
                )}

              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Voucher;
