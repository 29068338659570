import ReactTooltip from 'react-tooltip';
import {useTranslation} from "react-i18next";

const Audience = ({ audience }) => {
  const {t, i18n} = useTranslation('common');
  
  return (
    <dl className='dlist-align'>
      <dt className='text-muted'>{t('EventDetailPage.Audience')}</dt>
      <dd className='text-right'>
        <ul className='list-inline'>
          {audience.length > 0 &&
            audience.map(aud => (
              <li className='list-inline-item' key={aud}>
                <span data-tip data-for={aud}>
                  <img
                    src={`/assets/uploads/audiences/${aud.toLowerCase()}.png`}
                    className='img-xxs'
                    alt='adults'
                  />
                </span>
                <ReactTooltip
                  id={aud}
                  effect='solid'
                  className='tooltip-inner'
                  backgroundColor='#31c5f4'
                  borderColor='#31c5f4'
                  arrowColor='#31c5f4'>
                  <span>{aud}</span>
                </ReactTooltip>
              </li>
            ))}
        </ul>
      </dd>
    </dl>
  );
};

export default Audience;
