import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Sidebar from '../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../sections/ui/Alert';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import Card from '../../../sections/ui/Card';
import CustomLoader from '../../../sections/ui/CustomLoader';
import { Link } from 'react-router-dom';
import { API_DOMAIN } from '../../../Config';

const headers = {
  Authorization: `Bearer ${
    localStorage.getItem('user') &&
    JSON.parse(localStorage.getItem('user')).access_token
  }`
};


function Reviews(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (!foundUser.user_detail.role_id) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  useEffect(() => {
    const getReviews = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`${API_DOMAIN}api/my_reviews`, {
          headers
        });
        setReviews(data.data);

      } catch (err) {
        setError('something went wrong, please try again');

      }
      setLoading(false);
    };
    getReviews();
  }, [axios]);

  return (
    <>
      <Breadcrumbs active='my-reviews' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='my-reviews' />
            <div className='col-lg-9'>
              <Card title={`${reviews.length} review(s) found`}>
                <div className='pb-3 px-3 pt-1'>
                  <div className='row no-gutters'>
                    <div className='col-12 col-md-12'>
                      {loading ? (
                        <CustomLoader
                          style={{
                            textAlign: 'center'
                          }}
                        />
                      ) : error ? (
                        <Alert type='error'>{error}</Alert>
                      ) : reviews && reviews.length <= 0 ? (
                        <>
                          <Alert type='error'>No Reviews Found</Alert>
                        </>
                      ) : (
                        <table className='table table-responsive-md table-striped custom-table'>
                          <thead className='thead-light'>
                            <tr>
                              <th>Event Title</th>
                              <th>Review</th>
                              <th>Comment</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reviews.map(review => (
                              <tr key={review.id}>
                                <td>
                                  <Link
                                    to={`/event-details/${review.event_detail.slug}/${review.event_detail.id}`}>
                                    {review.event_detail.title}
                                  </Link>
                                </td>
                                <td>{review.review}</td>
                                <td>{review.description}</td>
                                <td>
                                  {moment(review.created_at).format(
                                    'DD-MMM-YYYY'
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Reviews;
