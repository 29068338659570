import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
export default function ColorPicker(props) {
    const [showPicker, setShowPicker] = useState(false);
    const [color, setColor] = useState(props.defaultColor);
   
      const onClick = () => {
       setShowPicker(!showPicker)
    };
 
    const onClose = () => {
        setShowPicker(false)
    };
 
    const onChange = (color) => {
    setColor(color.hex)
    props.onChange(color.hex);
    };
    const styles = reactCSS({
        'default': {
          color: {
            width: '100px',
            height: '25px',
            borderRadius: '3px',
            background: color,
          },
          popover: {
            position: 'absolute',
            zIndex: '3',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
          swatch: {
            padding: '6px',
            background: '#ffffff',
            borderRadius: '2px',
            cursor: 'pointer',
            display: 'inline-block',
            boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
          },          
        },
      });
 
    return (
        <>
        <div>
        <div style={ styles.swatch } onClick={onClick}>
          <div style={ styles.color } />
        </div>
        { showPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={onClose}/>
          <SketchPicker color={ color} onChange={ onChange } />
        </div> : null }
      </div>
        </>
    );
  }
  