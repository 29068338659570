import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
function StarRating(props) {
  const ratings = [1, 2, 3, 4, 5];
  return (
    <div className="star_ratings-wrap">
      {ratings.map((num) => {
        return (
          <FontAwesomeIcon
            key={num}
            icon={faStar}
            className={`star_ratings ${props.rating >= num ? "" : " empty"}`}
          />
        );
      })}
    </div>
  );
}

export default StarRating;
