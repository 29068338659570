// import {
//     faEnvelopeOpenText,
//     faFax,
//     faMapMarker,
//     faPhone,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../sections/ui/Breadcrumbs';
// import Card from "../../sections/ui/Card";
// import LabeledInput from "../../sections/ui/formfields/LabeledInput";
import axios from 'axios';
import parse from 'html-react-parser';
import { GET_FRONTEND_PAGES } from '../../APIConfig';
import CustomLoader from '../../sections/ui/CustomLoader';

export default function HowItWorks(props) {
  const [howWorksstate, setHowworksstate] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    try {
      setLoading(true);
      axios.get(GET_FRONTEND_PAGES).then(response => {
        let obj = response.data.data.find(o => o.slug === 'how-it-works');
        setHowworksstate(obj);
        setLoading(false);
      });
    } catch {
      setLoading(false);
    }
  };

  return (
    <>
      <Breadcrumbs active={'How It Works'} />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 order-0 order-lg-1'>
              <div className='card box'>
                <div className='card-body'>
                  {loading && <CustomLoader style={{ textAlign: 'center' }} />}
                  {!loading && (
                    <>
                      {/* <header className="section-heading mb-1">
                                        <h1 className="title-section">How It Works</h1>
                                    </header> */}
                      {howWorksstate.body == undefined
                        ? ''
                        : parse(`${howWorksstate.body}`)}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
