import { faTshirt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Sidebar from "../../../sections/my-account/organizer/sidebar/Sidebar";
import Breadcrumbs from "../../../sections/ui/Breadcrumbs";
import Card from "../../../sections/ui/Card";
import CustomFileInput from "../../../sections/ui/formfields/CustomFileInput";
import CustomSelect from "../../../sections/ui/formfields/CustomSelect";
import LabeledInput from "../../../sections/ui/formfields/LabeledInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AddMerchandise(props) {
  const [startDate, setStartDate] = useState(new Date());
  React.useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if(foundUser.user_detail.role_id !== 3){
        props.history.push('/signin')
      }
    }
    else{
      props.history.push('/signin')
    }
  }, []);
  
  return (
    <>
      <Breadcrumbs active="add-your-merchandise" />
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <Sidebar active="add-your-merchandise" />
            <div className="col-lg-9">
              <Card>
                <header className="section-heading mb-3">
                  <h4 className="title-section">
                    <FontAwesomeIcon icon={faTshirt} className="fa-w-14 mr-2" />
                    Add Merchandise
                  </h4>
                </header>
                <div className="row">
                  <div className="col-12 col-md-12">
                    <LabeledInput
                      type="text"
                      label="Merchandise Name"
                      id="merch-name"
                      name="merch-name"
                      required
                      placeholder=""
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <CustomFileInput
                      label="Merchandise Image"
                      id="organizer_profile"
                      required
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <CustomSelect
                      className="custom_select-div"
                      label="Category"
                      required
                      options={[
                        {
                          label: "All",
                        },
                        {
                          label: "Cinema",
                        },
                        {
                          label: "Concert Music",
                        },
                        {
                          label: "Conference",
                        },
                        {
                          label: "Exposition",
                        },
                        {
                          label: "Festival/Spectacle",
                        },
                        {
                          label: "Game/Competition",
                        },
                        {
                          label: "Hotel",
                        },
                        {
                          label: "Exposition",
                        },
                        {
                          label: "Festival/Spectacle",
                        },
                        {
                          label: "Game/Competition",
                        },
                        {
                          label: "Hotel",
                        },
                        {
                          label: "Others",
                        },
                      ]}
                      name="event-category"
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="date-added" className="required">
                        Date Added
                      </label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="form-control w-100"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <CustomSelect
                      className="custom_select-div"
                      label="Size"
                      required
                      options={[
                        {
                          label: "xs",
                        },
                        {
                          label: "sm",
                        },
                        {
                          label: "lg",
                        },
                        {
                          label: "xl",
                        },
                      ]}
                      name="merch-size"
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <LabeledInput
                      type="text"
                      label="Merchandise Qty"
                      id="merch-qty"
                      name="merch-qty"
                      required
                      placeholder=""
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <LabeledInput
                      type="text"
                      label="Merchandise Price"
                      id="merch-price"
                      name="merch-price"
                      required
                      placeholder=""
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <LabeledInput
                      type="text"
                      label="Merchandise Commission"
                      id="merch-commission"
                      name="merch-commission"
                      required
                      placeholder=""
                    />
                  </div>
                </div>
                <a href="" className="btn btn-primary ml-3">
                  Add Merchandise
                </a>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
