import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'

const BookedTicketItem = ({ booking, deleteBooking, setShowPopup, setOrder }) => {
var totalincome=0;
  if(booking.ticket_detail)
{

  totalincome = booking.total_income
}

  return (
    <Tr>
      <Td>{booking.event_detail !== null && booking.event_detail.title}</Td>
      <Td>{booking.ticket_detail !== null && booking.ticket_detail.title.toString()}</Td>
      <Td>{booking.total_booked.toString()}</Td>
      <Td>{booking.total_booked_free.toString()}</Td>
      <Td>{booking.checked_in.toString()}</Td>
      <Td>{totalincome}</Td>
      <Td>{booking.is_paid=== 1 ? 'Yes' : 'No'}</Td>
    </Tr>
  );
};

export default BookedTicketItem;
