import Loader from "react-loader-spinner"

const CustomLoader = props => (
  <Loader
    {...props}
    type='ThreeDots'
    color={props.isFrame?props.urlColor:'#31c5f4'}
    height={50}
    width={50}
    timeout={503000} //3 secs
  />
)

export default CustomLoader
