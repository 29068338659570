// import Alert from "../../../sections/ui/Alert";
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_DOMAIN } from '../../../Config';
import Sidebar from '../../../sections/my-account/attendee/sidebar/Sidebar';
import Alert from '../../../sections/ui/Alert';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import Card from '../../../sections/ui/Card';
import CustomLoader from '../../../sections/ui/CustomLoader';
// import CartItem from "../../../sections/my-account/attendee/cart/CartItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCartPlus,
//   faChevronRight,
//   faCog,
//   faMinusSquare,
//   faSync,
//   faTrash,
//   faTshirt,
// } from "@fortawesome/free-solid-svg-icons";
// import MercItem from "../../../sections/my-account/attendee/cart/MercItem";
// import Popup from "../../../sections/ui/Popup/Popup";
import {useTranslation} from "react-i18next";

const headers = {
  Authorization: `Bearer ${
    localStorage.getItem('user') &&
    JSON.parse(localStorage.getItem('user')).access_token
  }`
};

function Dashboard(props) {
  const {t, i18n} = useTranslation('common');
  // const [mercState, setMerc] = useState(false);
  // const [mercsTotal, setmercsTotal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (!foundUser.user_detail.role_id) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  useEffect(() => {
    
    const getReviews = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`${API_DOMAIN}api/my_reviews`, {
          headers
        });
        setReviews(data.data);
        
      } catch (err) {
        setError(t('SomethingWentWrong'));
        
      }
      setLoading(false);
    };
    getReviews();
  }, [axios]);

  // const setMercHandler = (e) => {
  //   e.preventDefault();
  //   setMerc(!mercState);
  // };

  // const addMercToCart = () => {
  //   setMerc(false);
  //   setmercsTotal(true);
  // };
  // const RemoveMercHandler = (e) => {
  //   e.preventDefault();
  //   setMerc(false);
  //   setmercsTotal(false);
  // };
  return (
    <>
      <Breadcrumbs active='my-reviews' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='my-reviews' />
            <div className='col-lg-9'>
              <Card title={`${reviews.length} ${t('ReviewFound')}`}>
                <div className='pb-3 px-3 pt-1'>
                  <div className='row no-gutters'>
                    <div className='col-12 col-md-12'>
                      {loading ? (
                        <CustomLoader
                          style={{
                            textAlign: 'center'
                          }}
                        />
                      ) : error ? (
                        <Alert type='error'>{error}</Alert>
                      ) : reviews && reviews.length <= 0 ? (
                        <>
                          <Alert type='error'>{t('NoReviews')}</Alert>
                        </>
                      ) : (
                        <table className='table table-responsive-md table-striped custom-table'>
                          <thead className='thead-light'>
                            <tr>
                              <th>{t('AttendeeDashoboardPage.EventTitle')}</th>
                              <th>{t('Review')}</th>
                              <th>{t('Comment')}</th>
                              <th>{t('Date')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reviews.map(review => (
                              <tr key={review.id}>
                                <td>
                                  {review.event_detail && (
                                    <Link
                                      to={`/event-details/${review.event_detail.slug}/${review.event_detail.id}`}>
                                      {review.event_detail.title}
                                    </Link>
                                  )}
                                </td>
                                <td>{review.review}</td>
                                <td>{review.description}</td>
                                <td>
                                  {moment(review.created_at).format(
                                    'DD-MMM-YYYY'
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard;
