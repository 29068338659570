import {
    faCalendar,
    faHome,
    faMailBulk,
    faMapMarkedAlt,
    faSearch,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React, { useState } from "react";
  import Events from "../../../sections/home/UpcomingEvents/UpcomingEvents";
  import FilterCollapsible from "../../../sections/ui/FilterCollapsible";
  import CustomSelect from "../../../sections/ui/formfields/CustomSelect";
  import LabeledInput from "../../../sections/ui/formfields/LabeledInput";
  import countries from "../../../sections/ui/countries";
  import ReactTooltip from "react-tooltip";
  import Nouislider from "nouislider-react";
  import "nouislider/distribute/nouislider.css";
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import {useTranslation} from "react-i18next";


  export default function EventList(props) {
    const [ticketPrice, setTicketPrice] = useState(true);
    const [sliderValues, setSliderVal] = useState(["0", "10000"]);
    const [startDate, setStartDate] = useState(new Date());
    const {t, i18n} = useTranslation('common');
    const ticketPriceHandler = () => {
      setTicketPrice(!ticketPrice);
    };
  
    const onChangeSlide = (data) => {
      setSliderVal(data);
    };
    const sliderOnChange = (e, val) => {
      const newVal = [...sliderValues];
      if (val === "min") {
        newVal[0] = e.target.value;
      } else if (val === "max") {
        newVal[1] = e.target.value;
      }
      setSliderVal(newVal);
    };
  
    return (
      <>
        <section className="section-pagetop bg-gray">
          <div className="container clearfix">
            <h4 className="title-page dark b float-xl-left mb-0">{t('Venues')}</h4>
  
            <nav className="float-xl-right mt-2 mt-xl-0">
              <ol className="breadcrumb text-white">
                <li className="breadcrumb-item">
                  <a href="/" className="dark">
                    <FontAwesomeIcon icon={faHome} />
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                {t('Venues')}
                </li>
              </ol>
            </nav>
          </div>
        </section>
        <section className="section-content bg-white padding-y">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3 order-2 order-lg-1">
                <div className="card card-filter">
                  <FilterCollapsible title="keyword">
                    <LabeledInput
                      type="text"
                      id="for_keywords"
                      name="forKeywords"
                    />
                  </FilterCollapsible>
                  <FilterCollapsible title="Category">
                    <CustomSelect
                      className="custom_select-div z-3"
                      options={[
                        {
                          label: "All",
                        },
                        {
                          label: "Cinema",
                        },
                        {
                          label: "Concert Music",
                        },
                        {
                          label: "Conference",
                        },
                        {
                          label: "Exposition",
                        },
                        {
                          label: "Festival/Spectacle",
                        },
                        {
                          label: "Game/Competition",
                        },
                        {
                          label: "Hotel",
                        },
                        {
                          label: "Exposition",
                        },
                        {
                          label: "Festival/Spectacle",
                        },
                        {
                          label: "Game/Competition",
                        },
                        {
                          label: "Hotel",
                        },
                        {
                          label: "Others",
                        },
                      ]}
                      name="event-category"
                    />
                  </FilterCollapsible>
                  <FilterCollapsible title="Location">
                    <div className="custom-control custom-checkbox form-check-input">
                      <div className="form-check mb-4 filter-online-container">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="filter-online-only"
                          name="onlineonly"
                          value="1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="filter-online-only"
                        >
                          {t('OnlineEventsOnly')}
                        </label>
                      </div>
                    </div>
  
                    <div className="location-based-filters">
                      <div className="custom-control custom-checkbox form-check-input">
                        <div className="form-check mb-4">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="filter-local-only"
                            name="localonly"
                            value="1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="filter-local-only"
                          >
                            {t('LocalEventsOnly')}
                          </label>
                        </div>
                      </div>
                      <p id="user-country" className="text-muted">
                        Events in India
                        <span className="flag flag-in ml-2"></span>
                      </p>
                    </div>
                    <CustomSelect
                      label="Country"
                      className="custom_select-div z-2"
                      options={countries}
                      name="country"
                    />
                  </FilterCollapsible>
                  <FilterCollapsible title="Ticket price">
                    <div className="custom-control custom-checkbox form-check-input ml-0 mb-4">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="free-events-only"
                        name="freeonly"
                        onChange={ticketPriceHandler}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="free-events-only"
                      >
                        Free events only
                      </label>
                    </div>
                    {ticketPrice ? (
                      <>
                        <Nouislider
                          range={{ min: 0, max: 10000 }}
                          start={sliderValues}
                          connect
                          onChange={onChangeSlide}
                        />
                        <div className="row ranger-slider-inputs mt-3">
                          <div className="col-12 col-sm-6">
                            <label htmlFor="pricemin">Min</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <input
                                id="pricemin"
                                name="pricemin"
                                type="text"
                                className="form-control range-slider-min-input"
                                placeholder="Min"
                                value={Math.trunc(sliderValues[0])}
                                onChange={(e) => sliderOnChange(e, "min")}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <label htmlFor="pricemax">Max</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <input
                                id="pricemax"
                                name="pricemax"
                                type="text"
                                className="form-control range-slider-max-input"
                                placeholder="Max"
                                value={Math.trunc(sliderValues[1])}
                                onChange={(e) => sliderOnChange(e, "max")}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </FilterCollapsible>
                  <FilterCollapsible title="Date">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control w-100"
                    />
                  </FilterCollapsible>
                  <FilterCollapsible title="Audience">
                    <div
                      className="btn-group btn-group-toggle"
                      data-toggle="buttons"
                    >
                      <label
                        className="btn btn-light btn-sm py-2 px-3"
                        data-tip
                        data-for="detail-icon-1"
                      >
                        <input
                          type="radio"
                          name="audience"
                          id="children"
                          value="children"
                        />
                        <img
                          src="assets/uploads/audiences/children.png"
                          className="img-15-15"
                          alt="Children"
                        />
                        <ReactTooltip
                          id="detail-icon-1"
                          effect="solid"
                          className="tooltip-inner"
                          backgroundColor="#31c5f4"
                          borderColor="#31c5f4"
                          arrowColor="#31c5f4"
                        >
                          <span style={{ fontSize: "11px" }}>Children</span>
                        </ReactTooltip>
                      </label>
                      <label
                        className="btn btn-light btn-sm py-2 px-3"
                        data-tip
                        data-for="detail-icon-2"
                      >
                        <input
                          type="radio"
                          name="audience"
                          id="youth"
                          value="youth"
                        />
                        <img
                          src="assets/uploads/audiences/youth.png"
                          className="img-15-15"
                          alt="Youth"
                        />
                        <ReactTooltip
                          id="detail-icon-2"
                          effect="solid"
                          className="tooltip-inner"
                          backgroundColor="#31c5f4"
                          borderColor="#31c5f4"
                          arrowColor="#31c5f4"
                        >
                          <span style={{ fontSize: "11px" }}>Youth</span>
                        </ReactTooltip>
                      </label>
                      <label
                        className="btn btn-light btn-sm py-2 px-3"
                        data-tip
                        data-for="detail-icon-3"
                      >
                        <input
                          type="radio"
                          name="audience"
                          id="family"
                          value="family"
                        />
                        <img
                          src="assets/uploads/audiences/family.png"
                          className="img-15-15"
                          alt="Family"
                        />
                        <ReactTooltip
                          id="detail-icon-3"
                          effect="solid"
                          className="tooltip-inner"
                          backgroundColor="#31c5f4"
                          borderColor="#31c5f4"
                          arrowColor="#31c5f4"
                        >
                          <span style={{ fontSize: "11px" }}>Family</span>
                        </ReactTooltip>
                      </label>
                      <label
                        className="btn btn-light btn-sm py-2 px-3"
                        data-tip
                        data-for="detail-icon-4"
                      >
                        <input
                          type="radio"
                          name="audience"
                          id="adults"
                          value="adults"
                        />
                        <img
                          src="assets/uploads/audiences/adults.png"
                          className="img-15-15"
                          alt="Adults"
                        />
                        <ReactTooltip
                          id="detail-icon-4"
                          effect="solid"
                          className="tooltip-inner"
                          backgroundColor="#31c5f4"
                          borderColor="#31c5f4"
                          arrowColor="#31c5f4"
                        >
                          <span style={{ fontSize: "11px" }}>Adults</span>
                        </ReactTooltip>
                      </label>
                      <label
                        className="btn btn-light btn-sm py-2 px-3"
                        data-tip
                        data-for="detail-icon-5"
                      >
                        <input
                          type="radio"
                          name="audience"
                          id="group"
                          value="group"
                        />
                        <img
                          src="assets/uploads/audiences/group.png"
                          className="img-15-15"
                          alt="Group"
                        />
                        <ReactTooltip
                          id="detail-icon-5"
                          effect="solid"
                          className="tooltip-inner"
                          backgroundColor="#31c5f4"
                          borderColor="#31c5f4"
                          arrowColor="#31c5f4"
                        >
                          <span style={{ fontSize: "11px" }}>Group</span>
                        </ReactTooltip>
                      </label>
                    </div>
                  </FilterCollapsible>
                  {/* <article className="card-group-item">
                    <div className="card-body">
                      <button className="btn btn-block btn-outline-primary">
                        <FontAwesomeIcon icon={faSearch} className="mr-2" />
                        Search
                      </button>
                    </div>
                  </article> */}
                  <article className="card-group-item">
                    <div className="card-body p-0">
                      <article className="box bg-primary">
                        <figure className="itemside mb-4">
                          <div className="aside align-self-center">
                            <span className="icon-wrap icon-sm round bg-white">
                              <FontAwesomeIcon
                                icon={faMailBulk}
                                className="text-primary"
                              />
                            </span>
                          </div>
                          <figcaption className="text-wrap">
                            <h5 className="title text-white">
                              Subscribe to our newsletter
                            </h5>
                          </figcaption>
                        </figure>
                        <div className="form-row">
                          <div className="form-group col mb-0">
                            <input
                              id="newsletter-email"
                              type="email"
                              className="newsletter-email form-control rounded-0 border-0 line-height-1"
                              placeholder="Email address"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col mb-0">
                            <button
                              id="newsletter-subscribe"
                              data-target="/en/newsletter-subscribe"
                              type="button"
                              className="btn btn-dark justify-content-center w-100 rounded-0"
                            >
                              SUBSCRIBE
                            </button>
                          </div>
                        </div>
                      </article>
                    </div>
                  </article>
                </div>
              </aside>
              <div className="col-lg-9 order-1 order-lg-2">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-6 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        0 event(s) found
                      </span>
                    </div>
                    {/* <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                  <a href="#events-map" className="btn btn-primary mr-3">
                    <FontAwesomeIcon
                      icon={faMapMarkedAlt}
                      className="fa-fw mr-2"
                    />
                    <span style={{ fontSize: "11px" }}>view on map</span>
                  </a>
                  <a href="#events-calendar" className="btn btn-primary">
                    <FontAwesomeIcon icon={faCalendar} className="fa-fw mr-2" />
                    <span style={{ fontSize: "11px" }}>view on calendar</span>
                  </a>
                </div> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-6 mb-3">
                    <Events  name="Venue-1"/>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-6 mb-3">
                    <Events  name="Venue-2"/>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-6 mb-3">
                    <Events  name="Venue-3"/>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-6 mb-3">
                    <Events  name="Venue-4"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
  