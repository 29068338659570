import React, { useState, useEffect } from 'react';
import Upcomingevents from '../home/UpcomingEvents/UpcomingEvents';
import axios from 'axios';
import { EVENTS_ALL_API } from '../../APIConfig';
import CustomLoader from '../ui/CustomLoader';
import {useTranslation} from "react-i18next";

const UpCommingEvents = () => {
  const {t, i18n} = useTranslation('common');
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getEvents = async () => {
    try {
      setLoading(true);
      const { data } = await axios({
        url: EVENTS_ALL_API + '?upcoming_event=1',
        method: 'GET'
      });
      setEventData(data.events.data.slice(0, 9));
    } catch {
    }
    setLoading(false);
  };

  useEffect(() => getEvents(), []);

  return (
    <section className='section-content padding-y-lg bg-white'>
      <div className='container'>
        <header className='section-headin' style={{marginBottom: 50}}>
          <h4 className='title-section text-center text-muted text-uppercase'>
          {t('UpcomingEvents')}
          </h4>
        </header>
        {loading ? (
          <CustomLoader style={{textAlign: 'center'}} />
        ) : (
          <div className='row-sm'>
            {eventData.length <= 0 ? (
              <div style={{ margin: 'auto' }}>
                <p>{t('NoEventsFound')}</p>
              </div>
            ) : (
              eventData.map((event, index) => (
                <div className='col-12 col-sm-6 col-lg-4' key={index}>
                  <Upcomingevents event={event} />
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default UpCommingEvents;
