import { faSearchengin } from '@fortawesome/free-brands-svg-icons';
import {
  faPlus,
  // faSearch,
  faSearchMinus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Sidebar from '../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../sections/ui/Alert';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import CustomSelect from '../../../sections/ui/formfields/CustomSelect';
import EventsList from '../../../sections/my-account/organizer/my-events/EventsList';
import axios from 'axios';
import { EVENTS_FILTER_API, CATEGORY_API } from '../../../APIConfig';
import { Link } from 'react-router-dom';
// import { min } from 'moment';
import Pagination from 'react-js-pagination';
import CustomLoader from '../../../sections/ui/CustomLoader';
import { useTranslation } from 'react-i18next';
import { API_DOMAIN } from '../../../Config';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import $ from 'jquery';
import { CSVLink } from "react-csv";

const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);

function Myupcomingevents(props) {
  const [showAllFilters, setShowAllFilters] = useState(false);
  const {t, i18n} = useTranslation('common');
  // const initialFormData = {
  //   sort_by: null,
  //   category_id: null,
  //   status: 1
  // };
  const initialFilters = {
    sort_by: 2,
    category_id: null,
    status: 2,
    expiredallow:"false"
  };
  const [filters, setFilters] = useState(initialFilters);
  // const [categoryData, setCategoryData] = useState([]);
  const [catOptions, setCatOptions] = useState([]);
  const [error, setError] = useState('');
  const [eventsList, setEventsList] = useState({ data: [] });

  // const [paginationData, setPaginationData] = useState({
  //   current_page: 1,
  //   per_page: 10,
  //   data: []
  // })
  const perPage = 10;
  const [eventFields, setEventFields] = useState(false);
  const [eventCustomerList, setEventCustomerList] = useState([]);
  const [selectedEvent, setselectedEvent] = useState();
  const [eventCustomerCsv,setEventCustomerCsv] = useState({})
  const [couponCsvData,setCouponCsvData] = useState({})

  const [currentPage, setCurrentPage] = useState(1);
  const [totalEvents, setTotalEvents] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (authDataString) {
      if (authData.user_detail.role_id !== 3) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  // get all the categories from server
  useEffect(() => {
    const getCategories = async () => {
      try {
        const { data } = await axios({
          url: CATEGORY_API,
          method: 'GET'
        });
        // setCategoryData(data.data);
        setCatOptions(
          data.data.map(function (category) {
            return { label: category.name, value: category.id };
          })
        );
      } catch (err) {
        setError(t('TryAgain'));
      }
    };

    getCategories();
  }, []);

  // get all events
  useEffect(() => {
    const getEvents = async () => {
      try {
        setLoading(true);
        const headers = {
          Authorization: `Bearer ${authData && authData.access_token}`
        };
        const { data } = await axios.post(
          `${EVENTS_FILTER_API}?page=${currentPage}`,  
          filters,
          {
            headers
          }
        );
        setEventsList({ data: data.myevents.data });
        setTotalEvents(data.myevents.total);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        //setIsError('User Not found. Please try again'); 
      }
    };

    getEvents();
  }, [filters, currentPage, reload]);

  const paginationHandler = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const getEventCustomers = async (eventid,event) =>
  {
    try {
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${authData && authData.access_token}`
      };
      const { data } = await axios.post(
        `${API_DOMAIN}api/bookings/event-customers`,
        {
          event_id: eventid
        },
        {
          headers
        }
      ); 
      setselectedEvent(event);
      setEventCustomerList([...data.customers]);
      setEventFields(true)
      if(event.custom_field_enabled==1)
      {
        const allheaders = [
          { label: t('CustomerName'), key: "customer_name" },
          { label: t('CustomerEmail'), key: "customer_email" },
          { label: t('MyEventPage.custom_field'), key: "custom_field" },
        ];
        const alldata = [];
        let customer_list=data.customers;
        for(var k=0;k<customer_list.length;k++)
        {
          alldata.push(
            { 
              customer_name: customer_list[k].customer_name,
              customer_email: customer_list[k].customer_email, 
              custom_field: customer_list[k].custom_field
           }
          )
        }
        setEventCustomerCsv(
          {
            data: alldata,
            headers: allheaders,
            filename: 'customer-list.csv'
          })
      }

      else
      {
        const allheaders = [
          { label: t('CustomerName'), key: "customer_name" },
          { label: t('CustomerEmail'), key: "customer_email" }
        ];
        const alldata = [];
        let customer_list=data.customers;
        for(var k=0;k<customer_list.length;k++)
        {
          alldata.push(
            { 
              customer_name: customer_list[k].customer_name,
              customer_email: customer_list[k].customer_email
           }
          )
        }
        setEventCustomerCsv(
          {
            data: alldata,
            headers: allheaders,
            filename: 'customer-list.csv'
          })
      }
 
        setLoading(false);
        if ( ! $.fn.DataTable.isDataTable('#table2')) {
          $('#table2').DataTable({
            "scrollX": true
        });}
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <>
      <Breadcrumbs active='my-upcoming-events' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='my-upcoming-events' />
            <div className='col-lg-9 mt-4 mt-lg-0'>
              <div className='box shadow-none bg-gray mb-4'>
              {
                  !eventFields && (
                <>
                 <div className='row'>
                  <div className='col-sm-12 col-lg-12' style={{display:'flex',justifyContent:'center'}}>
                    <Link
                      to='/dashboard/organizer/my-events-upcoming/add'
                      className='btn btn-primary ml-3'>
                      <FontAwesomeIcon icon={faPlus} className='mr-2' /> {t('MyEventPage.AddNewButton')}
                    </Link>
                  </div>
                </div>
                </>
                  )
               }
                {
                  eventFields && (
                  <>
                   <div className='row'>
                  <div className='col-sm-12 col-lg-12' style={{display:'flex',justifyContent:'center'}}>
                  <button
                          onClick={() => {
                            setEventFields(false)
                          }}
                          className='btn btn-success ml-3'>
                          {t('CouponPage.CancelButton')}
                        </button>
                  </div>
                </div>
                  </>)
                }
               
                {showAllFilters ? (
                  <div>
                    <hr />
                    <div className='row'>
                      {/* <div className="col-12 col-sm-4 text-left mb-4 select_white-bg">
                        <CustomSelect
                        register={register}
                          className="custom_select-div"
                          label="Event"
                          options={[]}
                          name="event"
                        />
                      </div> */}
                      <div className='col-12 col-sm-4 text-left mb-4 select_white-bg'>
                        <CustomSelect
                          className='custom_select-div'
                          required={true}
                          defaultValue={setFilters.category_id}
                          options={catOptions}
                          name='category_id'
                          isClearable={false}
                          isSearchable={false}
                          value={setFilters.category_id}
                          handleChange={e => {
                            setFilters(state => ({
                              ...state,
                              category_id: e.value
                            }));
                          }}
                        />
                      </div>

                      <div className='col-12 col-sm-4 text-left mb-4'>
                        <div className='custom-control custom-checkbox'>
                          <input
                            id='allEvents'
                            type='radio'
                            name='published'
                            value='2'
                            className='custom-control-input'
                            defaultChecked={filters.status === 2 ? true : false}
                            onClick={e => {
                              setFilters(state => ({
                                ...state,
                                status: e.target.value
                              }));
                            }}
                          />
                          <label
                            htmlFor='allEvents'
                            className='custom-control-label'>
                            {t('MyEventPage.SelectAll')}
                          </label>
                        </div>
                        <div className='custom-control custom-checkbox'>
                          <input
                            id='published-filter-yes'
                            type='radio'
                            name='published'
                            value='1'
                            defaultChecked={filters.status === 1 ? true : false}
                            className='custom-control-input'
                            onClick={e => {
                              setFilters(state => ({
                                ...state,
                                status: e.target.value
                              }));
                            }}
                          />
                          <label
                            htmlFor='published-filter-yes'
                            className='custom-control-label'>
                            {t('MyEventPage.PublishedOnly')}
                          </label>
                        </div>
                        <div className='custom-control custom-checkbox'>
                          <input
                            id='unpublished'
                            type='radio'
                            name='published'
                            value='0'
                            className='custom-control-input'
                            defaultChecked={filters.status === 0 ? true : false}
                            onClick={e => {
                              setFilters(state => ({
                                ...state,
                                status: e.target.value
                              }));
                            }}
                          />
                          <label
                            htmlFor='unpublished'
                            className='custom-control-label'>
                            {t('MyEventPage.Unpublished')}
                          </label>
                        </div>
                      </div>
                      <div className='col-12 col-sm-4 text-left mb-4'>
                        {/* <button
                          type='submit'
                          className='btn btn-primary'
                          data-toggle='tooltip'
                          // onClick={GetEventsFilter()}
                        >
                          <i className='fas fa-search'></i>
                          <FontAwesomeIcon icon={faSearch} /> Search
                        </button> */}
                        <button
                          type='reset'
                          className='btn btn-primary mx-3'
                          data-toggle='tooltip'
                          onClick={() => setFilters(initialFilters)}>
                          <FontAwesomeIcon icon={faSearchMinus} /> {t('MyEventPage.Reset')}
                        </button>
                      </div>
                    </div>{' '}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {loading ? (
                <CustomLoader style={{ textAlign: 'center' }} />
              ) : (
                <>
                  {!eventFields && eventsList.data.length === 0 ? (
                    <Alert type='warning'>{t('MyEventPage.NoEventsFound')}</Alert>
                  ) : (

                    <>
                    {
                      !eventFields && (
                      <>
                <EventsList data={eventsList.data} setReload={setReload} getEventCustomers={getEventCustomers}/>
                      </>)
                    }
                    </>
                  )}
                  {eventsList.data.length > 0 && (
                    <div style={{ float: 'right' }}>
                      {/* <Pagination
                        prevPageText='<'
                        nextPageText='>'
                        hideFirstLastPages={true}
                        itemClass='page-item'
                        linkClass='page-link'
                        activePage={currentPage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalEvents}
                        pageRangeDisplayed={5}
                        onChange={paginationHandler}
                      /> */}
                    </div>
                  )}
                </>
              )}

              {!loading && eventCustomerList.length > 0 && eventFields && (
                  <div>
                     <div style={{display:'flex',justifyContent:'center',marginBottom:20}}>
                       <CSVLink  {...eventCustomerCsv} className='btn btn-primary'>{t('ExporttoCSV')}</CSVLink>
                       </div>
                  <Table id='table2' className='table table-responsive-md table-striped custom-table'>
                    <Thead className='thead-light'>
                      <Tr>
                        <Th>{t('CustomerName')}</Th>
                        <Th>{t('CustomerEmail')}</Th>
                        {selectedEvent.custom_field_enabled==1
                        &&
                        <><Th>{t('MyEventPage.custom_field')}</Th></>
                        }
                        
                      </Tr>
                    </Thead>
                    <Tbody>
                      {eventCustomerList.map(eventcustomer => (
                      <>
                       <Tr>
                      <Td>{eventcustomer.customer_name}</Td>
                      <Td>{eventcustomer.customer_email}</Td>
                      {selectedEvent.custom_field_enabled==1
                        &&
                        <><Td>{eventcustomer.custom_field}</Td></>
                      }
                      
                       </Tr>
                      </>      
                      ))}
                    </Tbody>
                  </Table>
                  </div>
               
                )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Myupcomingevents;
