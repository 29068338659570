import Card from '../../../../sections/ui/Card';
import CustomFileInput from '../../../../sections/ui/formfields/CustomFileInput';
import CustomSelect from '../../../../sections/ui/formfields/CustomSelect';
import LabeledInput from '../../../../sections/ui/formfields/LabeledInput';
import DatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import {CREATE_Voucher_API, BACKEND_URL,EVENT_TICKETS_ALL,CREATE_EVENT_API,EVENTS_FILTER_API,ADD_COUPON_API } from '../../../../APIConfig';
import axios from 'axios';
import moment from 'moment';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import {useTranslation} from "react-i18next";
import RadioInput2 from '../../../../sections/ui/formfields/RadioInput2';
import CustomFileInput2 from '../../../../sections/ui/formfields/CustomFIleInput2';
import $ from 'jquery';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAlert } from 'react-alert';
import TimePicker from 'rc-time-picker';
import DateInput from '../../../../sections/ui/formfields/LabededDateField';


const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`,
  Accept: 'application/json'
};

const VoucherForm = ({ edit, showAlert, voucher }) => {
  const {t, i18n} = useTranslation('common');
  const usalert = useAlert();
  const voucherTypeArray = [
    {
      label: t('Voucher.VoucherTicket'),
      value: 1
    },
    {
      label: t('Voucher.VoucherOrder'),
      value: 2
    }
  ];

  const initialFormData = {
    title: '',
    voucher_type: {
      label: t('Voucher.VoucherTicket'),
      value: 1
    },
    generated_code: {
      label: t('Yes'),
      value: 1
    },
    added_tickets:[],
    event_id:'',
    ticket_id:'',
    template_image: null,
    template_type: null,
    start_date:new Date(),
    start_time:voucher?(voucher.start_time?voucher.start_time:moment().format('HH:mm:ss')):moment().format('HH:mm:ss'),
    end_date:new Date(new Date().setDate(new Date().getDate() + 1)),
    end_time:voucher?(voucher.end_time?voucher.end_time:moment().format('HH:mm:ss')):moment().format('HH:mm:ss'),
  };
  const initialFilters = {
    sort_by: null,
    category_id: null,
    status: 2,
    expiredallow:"false"
  };
  const [formData, setFormData] = useState(initialFormData);
  const [eventData, setEventData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [alleventData, setAllEventData] = useState([]);
  const [allticketData, setAllTicketData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [reload, setReload] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [IsCustomTemplate, setCustomTemplate] = useState(true);
  const [IsNew, setIsNew] = useState(false);
  const [templateName, setTemplateName] = useState('');
 

  const {
    title,
    voucher_type,
    generated_code,
    added_tickets,
    event_id,
    ticket_id,
    template_type,
    start_date,
    end_date,
    start_time,
    end_time,
  } = formData;


  useEffect(() => {
    const getEvents = async () => {
     
      try {
        setIsLoading(true);
        const headers = {
          Authorization: `Bearer ${authData && authData.access_token}`
        };
        const { data } = await axios.post(
          `${EVENTS_FILTER_API}?page=${currentPage}`,
          filters,
          {
            headers
          }
        );
     
      
       setAllEventData(data);
       let catArray = data.myevents.data.map(cat => ({
        label: cat.title,
        value: cat.id
      }));

      setEventData(catArray);
    
      setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    getEvents();
  }, [filters, currentPage, reload]);
 
  useEffect(() => {
    if (voucher !== null) {
      setFormData({
        ...voucher,
        end_date:voucher.end_date === '0000-00-00' || !voucher.end_date
        ? new Date(new Date().setDate(new Date().getDate() + 1))
        : new Date(voucher.end_date),
       end_time :voucher.end_time
       ? voucher.end_time
       : moment().format('HH:mm:ss'),
       start_date:voucher.start_date === '0000-00-00' || !voucher.start_date
        ? new Date()
        : new Date(voucher.start_date),
       start_time :voucher.start_time
       ? voucher.start_time
       : moment().format('HH:mm:ss'),
      }) 
      if (voucher.template_type === 'custom') {
        setTemplateName(voucher.template_image);
        setCustomTemplate(true);
        setIsNew(false);
      }
    };
    if(voucher==null)
    {
      setFormData(prevState => ({
        ...prevState,
        template_type: "custom"
      }));
    }
    return () => {
      setFormData(initialFormData);
    };
  }, [voucher]);
  const getTickets = async (id) => {
      
    try {
      const { data } = await axios.post(
        EVENT_TICKETS_ALL,
        { event_id: id },
        { headers }
      );
      setAllTicketData([]);
    
      if (data.status && data.tickets.length > 0) {
        setAllTicketData(data.tickets);
      }
      if(data.status)
      {
  let catArray =data.tickets.map(cat => ({
    label: cat.title,
    value: cat.id
  }));
  setTicketData(catArray);
}


    } catch (err) {
    }
  };
  const onChange = e => {
    if (e.target.name === 'template_type') {
      if (e.target.value === 'standard') {
        setCustomTemplate(false);

      }
      else {
        setCustomTemplate(true);
      }
    }
    else{
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }
  };

  const formSubmit = async e => {
    e.preventDefault();
    const userData = localStorage.getItem('user');
    const userDataObj = JSON.parse(userData);

    let token = userDataObj !== null && userDataObj.access_token;

    if (!token) {
      return;
    }
 
    const data = {
      ...formData,
      voucher_type: voucher_type.value,
      generated_code: generated_code.value,
      template_type: IsCustomTemplate ? 'custom' : 'standard',
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    
    let updateaddedtickets= [];
   data.added_tickets.forEach(vl=>{
   updateaddedtickets.push(vl.value)
   })
   data.added_tickets=updateaddedtickets;
  let exist = false;
   const newFormData = new FormData();
   for (let key in data) {
     if (key === 'template_image') {
       if(data[key])
         exist=true;
     } else {
       newFormData.append(key, data[key]);
     }
   }
   if (data.template_type === 'custom') {
     var file = $('#images')[0].files[0];    
     if(file)
     {
     const filesize = (file.size / (1024*1024)).toFixed(2);
     if(filesize<=2)
     {
     newFormData.append(`template_image`, file);
     exist=true;
     }
     else
     {
      showAlert(
        t('maxlimit'),
        'error'
      );
      usalert.error(t('maxlimit'));
      return;
     } 
    }
    }
    if(data.added_tickets.length==0 || !exist)
    {
      showAlert(
        t('fillallfields'),
        'error'
      );
      return;
    }
    setIsLoading(true);
    try {
      await axios.post(CREATE_Voucher_API, newFormData, { headers });
      showAlert(t('Created'), 'success');
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors !== undefined) {
        showAlert(t('PleaseCheckAllfields'), 'error');
      } else {
        showAlert(
          t('FailedCreation'),
          'error'
        );
      }
    }

    setIsLoading(false);
  };
 

  return (
    <>
      <div className='col-lg-12'>
        <Card>
          <form onSubmit={formSubmit}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='text'
                  label={t('Voucher.VoucherTitle')}
                  id='title'
                  name='title'
                  required
                  placeholder={t('Voucher.VoucherTitle')}
                  value={title}
                  onChange={onChange}
                />
              </div>
              <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  required={true}
                  label={t('Voucher.VoucherType')}
                  defaultValue={voucher_type}
                  options={voucherTypeArray}
                  name='voucher_type'
                  isClearable={false}
                  isSearchable={false}
                  value={voucher_type}
                  handleChange={e =>
                    setFormData(state => ({
                      ...state,
                      voucher_type: e
                    }))
                  }
                />
              </div>
              <div className='col-12 col-md-6'>
                          <DateInput
                            label={t('MyEventPage.StartDate')}
                            type='text'
                            required
                            id='start_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                start_date: date,
                                end_date: moment(date).add('days', 1).toDate()
                              }));
                            }}
                            startDate={start_date}
                          //minDate={moment().toDate()}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <label htmlFor='startTime'>
                            {t('MyEventPage.StartTime')}<span style={{ color: 'red' }}>*</span>
                          </label>
                          <TimePicker
                            id='startTime'
                            use12Hours={false}
                            showSecond={false}
                            className='form-control'
                            defaultValue={moment(start_time, [
                              moment.ISO_8601,
                              'HH:mm'
                            ])}
                            allowEmpty={false}
                            onChange={e =>
                              setFormData(state => ({
                                ...state,
                                start_time: e.format('HH:mm:ss')
                              }))
                            }
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <DateInput
                            label={t('MyEventPage.EndDate')}
                            type='text'
                            required
                            id='end_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                end_date: date
                              }));
                            }}
                            startDate={end_date}
                            minDate={moment(start_date).add('days', 1).toDate()}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <label htmlFor='endTime'>
                            {t('MyEventPage.EndTime')}<span style={{ color: 'red' }}>*</span>
                          </label>
                          <TimePicker
                            id='endTime'
                            showSecond={false}
                            className='form-control'
                            defaultValue={moment(end_time, [
                              moment.ISO_8601,
                              'HH:mm'
                            ])}
                            allowEmpty={false}
                            onChange={e =>
                              setFormData(state => ({
                                ...state,
                                end_time: e.format('HH:mm:ss')
                              }))
                            }
                            use12Hours={false}
                          />
                        </div>
              <div className='col-12 col-md-12'>
              <CustomSelect
                          className='custom_select-div'
                          required={true}
                          label={t('MyEventPage.SelectEvent')}
                          options={eventData}
                          name='event_id'
                          isClearable={false}
                          isSearchable={false}
                          value={event_id}
                          handleChange={e => {
                       
                            getTickets(e.value)
                            setFormData(state => ({
                              ...state,
                              event_id: e
                            }));
                          }}
                        />
              </div>

              <div className='col-12 col-md-12'>
                <CustomSelect
                  className='custom_select-div'
                  label={t('MyEventPage.SelectTicket')}
                  placeholder={t('MyEventPage.SelectTicket')}
                  name='added_tickets'
                  isSearchable={false}
                  isMulti
                  value={added_tickets}
                  options={ticketData}
                  handleChange={e => 
                  {
                    setFormData(state => ({
                      ...state,
                      added_tickets: e
                    }));
                  }}
                />
              </div>

              <div className='col-12 col-md-12'>
                <CustomSelect
                  className='custom_select-div'
                  label={t('Voucher.UseCode')}
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={generated_code}
                  value={generated_code}
                  handleChange={e =>
                    setFormData(state => ({
                      ...state,
                      generated_code: e
                    }))
                  }
                  options={[
                    {
                      label: t('Yes'),
                      value: 1
                    },
                    {
                      label: t('No'),
                      value: 0
                    }
                  ]}
                  name='status'
                />
              </div>
              <div className='col-12 col-md-12' style={{padding:'0px'}}>
              {/* {(template_type == 'standard' || template_type == 'custom') && (
                <RadioInput2
                  label={t('Voucher.VoucherTemplateType')}
                  info=''
                  name='template_type'
                  entries={[
                    {
                      label: 'Standard',
                      value: 'standard',
                      id: 'enable_templatetype',
                      name: 'template_type',
                      selected: template_type == 'standard' ? true : false
                    },
                    {
                      label: 'Custom',
                      value: 'custom',
                      id: 'disable_templatetype',
                      name: 'template_type',
                      selected: template_type == 'custom' ? true : false
                    }
                  ]}
                  onChange={onChange}
                />)} */}
                {IsCustomTemplate === true && (
                <div className='col-12 col-md-12'>
                  {templateName && IsNew === false && (
                    <img src={BACKEND_URL + templateName} className="img-fluid"/>
                  )}
                  <CustomFileInput2
                    label={t('AddTemplate')}
                    id='images'
                    name='images'
                    info={t('MyEventPage.CustomTemplateWarning2')}
                    handleFile={(e) => {
                      setTemplateName(e.target.files[0].name);
                      setIsNew(true);
                    }}
                  filename={
                    templateName
                      ? templateName
                      : ''
                  }
                  />
                  <small className='form-text text-muted mb-3' style={{marginTop:'-20px'}}>
                             <FontAwesomeIcon
                               icon={faInfoCircle}
                               className='text-primary mr-1'
                              />
                            {t('maxlimit')}
                            </small>
                </div>
              )}
              </div>
            
            </div>
         
            {isLoading && (
              <Loader
                type='ThreeDots'
                color='#31c5f4'
                height={50}
                width={50}
                timeout={3000} 
              />
            )}
            {!isLoading && !edit && (
              <button className='btn btn-primary' >{t('Voucher.CreateButton')}</button>
            )}

            {!isLoading && edit && (
              <button className='btn btn-primary'>{t('Voucher.UpdateButton')}</button>
            )}
          </form>
        </Card>
      </div>
    </>
  );
};

export default VoucherForm;
