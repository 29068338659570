// import CartItemSubTotal from '../../../sections/my-account/attendee/cart/CartItemSubTotal'
// import CartItemTotal from '../../../sections/my-account/attendee/cart/CartItemTotal'
import Card from '../../../sections/ui/Card';
import {useTranslation} from "react-i18next";

const CheckoutTotalSummary = props => {
  const {t, i18n} = useTranslation('common');
  return (
    <aside className='col-lg-4 pt-3 pt-lg-0 order-0 order-lg-1'>
      <Card title={t('CheckoutPage.CheckeoutSummary')}>
        <div className='sticky-top sticky-sidebar'>
          <div className='checkout-timer-wrapper'>
            {/* <Alert type='danger' icon={faHourglassHalf}>
                    <span className='checkout-timer'>30:00 </span>
                    left before tickets are released
                  </Alert> */}
          </div>
          <dl className='dlist-align'>
            <dt>{t('CheckoutPage.Tickets')}</dt>
            <dd className='text-right ml-5'>
              {/* <CartItemSubTotal /> */}€{parseFloat(props.ticketTotal).toFixed(2).replace('.', ',')}
            </dd>
          </dl>
          {/* <dl className='dlist-align'>
            <dt>Tax</dt>
            <dd className='text-right ml-5'>€{props.totalTax}</dd>
          </dl> */}
          <dl className='dlist-align'>
            <dt>{t('CheckoutPage.HandlingCharges')}</dt>
            <dd className='text-right ml-5'>€{props.totalFee}</dd>
          </dl>

          <dl className='dlist-align'>
            <dt>{t('CheckoutPage.TransactionFee')}</dt>
            <dd className='text-right ml-5'>€{props.transactionFee}</dd>
          </dl>
          <dl className='dlist-align'>
            <dt>{t('SuplementFee')}</dt>
            <dd className='text-right ml-5'>€{props.supplementFee}</dd>
          </dl>
          {/* <div className='price-wrap'>
                        <var className='price'>
                          Handling Changes: €{' '}
                          <span className='notranslate'>{totalFee}</span>
                        </var>
                      </div> */}
          {props.totalDiscount ? (
            <dl className='dlist-align'>
              <dt>{t('TotalDiscount')}</dt>
              <dd className='text-right ml-5'> - €{props.totalDiscount.toString().replace('.', ',')}</dd>
            </dl>
          ) : (
            ''
          )}

          {/* <dl className='dlist-align'>
            <dt>Merchandise</dt>
            <dd className='text-right ml-5'>$0</dd>
          </dl> */}
          <hr />
          <dl className='dlist-align h4' style={props.isFrame?{color:props.urlColor}:{color:"#31c5f4"}}>
            <dt>{t('CheckoutPage.Total')}</dt>
            <dd className='text-right ml-5'>
              <strong>
                €{props.total.toString().replace('.', ',')}
              </strong>
            </dd>
          </dl>
          <hr />

          <div className='row'>
            {/* <div className='col'>
              <img
                src='/assets/uploads/americanexpress-dark.c2ea2d77.svg'
                className='width-auto img-fluid'
                alt=''
              />
            </div>
            <div className='col'>
              <img
                src='/assets/uploads/visa-dark.f6a55e1d.svg'
                className='width-auto img-fluid'
                alt=''
              />
            </div>
            <div className='col'>
              <img
                src='/assets/uploads/mastercard-dark.b1695f2b.svg'
                className='width-auto img-fluid'
                alt=''
              />
            </div>
            <div className='col'>
              <img
                src='/assets/uploads/discover-dark.00f5c21f.svg'
                className='width-auto img-fluid'
                alt=''
              />
            </div>
            <div className='col'>
              <img
                src='/assets/uploads/paypal-dark.2abbaed4.svg'
                className='width-auto img-fluid'
                alt=''
              />
            </div> */}
          </div>
        </div>
      </Card>
    </aside>
  );
};

export default CheckoutTotalSummary;
