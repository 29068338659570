import {
  faCalendarAlt,
  faCartArrowDown,
  faCog,
  faTicketAlt,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import DashItem from '../../../sections/my-account/organizer/DashItem';
import Sidebar from '../../../sections/my-account/organizer/sidebar/Sidebar';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import Card from '../../../sections/ui/Card';
import { EVENT_STAT, DASHBOARD_STAT } from '../../../APIConfig';
import Alert from '../../../sections/ui/Alert';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const headers = {
  Authorization: `Bearer ${
    JSON.parse(localStorage.getItem('user')) &&
    JSON.parse(localStorage.getItem('user')).access_token
  }`
};

function Dashboard(props) {
  const {t, i18n} = useTranslation('common');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [ticketStat, setTicketStat] = useState([]);
  const [dasboardStat, setDashboardStat] = useState([]);

  const fetchTicketStat = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(EVENT_STAT, {}, { headers });
      if (data.success) {
        setTicketStat(data.data.event_detail);
        setError('');
      } else {
        setError(t('TopEventsPage.TopEventsnotfound'));
        setTicketStat([]);
      }
    } catch (error) {
      setError(t('TopEventsPage.TopEventsnotfound'));
      
    }
    setLoading(false);
  });

  const fetchDashboardStat = async () => {
    var config = {
      method: 'get',
      url: DASHBOARD_STAT,
      headers: headers
    };

    axios(config)
      .then(function (response) {
        setDashboardStat(response.data);
      })
      .catch(function (error) {
      
      });
  };

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (foundUser && foundUser.user_detail && foundUser.user_detail.role_id != "3") {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  useEffect(() => {
    fetchTicketStat();
  }, []);

  useEffect(() => {
    fetchDashboardStat();
  }, []);

  return (
    <>
      <Breadcrumbs active='dashboard' isHome />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='dashboard' />
            <div className='col-lg-9'>
              <Card>
                <header className='section-heading mb-3'>
                  <h4 className='title-section float-left'>
                    <FontAwesomeIcon icon={faCalendarAlt} /> {t('EventsSummary')}
                  </h4>
                  <Link
                    to='/dashboard/organizer/my-events'
                    className='float-right'>
                    <FontAwesomeIcon icon={faCog} /> {t('DashboardPage.MyEvents')}
                  </Link>
                  
                  <div className='clearfix'></div>
                </header>
                <div className='row mb-3 pl-2 pr-2'>
                  <DashItem
                    icon={<FontAwesomeIcon icon={faCalendarAlt} />}
                    title={t('DashboardPage.TotalEventsAdded')}
                    val={
                      dasboardStat.myevents === undefined
                        ? 0
                        : dasboardStat.myevents.total_event
                    }
                  />
                  <DashItem
                    icon={<FontAwesomeIcon icon={faCalendarAlt} />}
                    title={t('DashboardPage.PublishedEvents')}
                    val={
                      dasboardStat.myevents === undefined
                        ? 0
                        : dasboardStat.myevents.published
                    }
                  />
                  <DashItem
                    icon={<FontAwesomeIcon icon={faCalendarAlt} />}
                    title={t('DashboardPage.UpcomingEvents')}
                    val={
                      dasboardStat.myevents === undefined
                        ? 0
                        : dasboardStat.myevents.draft
                    }
                  />
                  {/* <DashItem
                    icon={<FontAwesomeIcon icon={faCalendarAlt} />}
                    title={'Event dates'}
                    val='0'
                  /> */}
                </div>
                {/* <header className='section-heading mb-3 mt-5'>
                  <h4 className='title-section float-left'>
                    <i className='fas fa-cart-arrow-down'></i>
                    <FontAwesomeIcon icon={faCartArrowDown} /> Attendees summary
                  </h4>
                  <Link
                    to='/en/dashboard/organizer/manage-orders'
                    className='float-right'
                  >
                    <FontAwesomeIcon icon={faUsers} /> Manage attendees
                  </Link>
                  <div className='clearfix'></div>
                </header>
                <div className='row mb-3 pl-2 pr-2'>
                  <DashItem
                    icon={<FontAwesomeIcon icon={faCartArrowDown} />}
                    title='Orders placed'
                    val='0'
                  />
                  <DashItem
                    icon={<FontAwesomeIcon icon={faCartArrowDown} />}
                    title='Paid orders
                    '
                    val='0'
                  />
                  <DashItem
                    icon={<FontAwesomeIcon icon={faCartArrowDown} />}
                    title='Awaiting payment'
                    val='0'
                  />
                  <DashItem
                    icon={<FontAwesomeIcon icon={faCartArrowDown} />}
                    title='Tickets sold'
                    val='0'
                  />
                </div> */}

                {/* // TODO: Enable this for ticket stats listing */}
                {/* <header className='section-heading mb-3 mt-5'>
                  <h4 className='title-section float-left'>
                    <i className='fas fa-cart-arrow-down'></i>
                    <FontAwesomeIcon icon={faTicketAlt} /> Event Ticket
                    Statistics
                  </h4>
                  <span style={{ float: 'right' }}>
                    {loading && !error && (
                      <Loader
                        type='ThreeDots'
                        color='#31c5f4'
                        height={50}
                        width={50}
                        timeout={3000} //3 secs
                      />
                    )}
                  </span>
                  <div className='clearfix'></div>
                </header> */}

                {/* {!loading && error && <Alert>{error}</Alert>} */}
                {/* {!loading && !error && ticketStat.length > 0 && (
                  <div className='row mb-3 pl-2 pr-2'>
                    <table className='table table-responsive-md table-striped custom-table'>
                      <thead className='thead-light'>
                        <tr>
                          <th>Event</th>
                          <th>Ticket</th>
                          <th>Quantity</th>
                          <th>Order Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ticketStat.map((stat, index) => (
                          <tr key={index}>
                            <td> {stat.title} </td>
                            <td> {stat.tickets} </td>
                            <td> {stat.tickets_quantity}</td>
                            <td> ${stat.total_price} </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )} */}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Dashboard;
