import {
  MY_BOOKING_LIST_FAIL,
  MY_BOOKING_LIST_REQUEST,
  MY_BOOKING_LIST_SUCCESS
} from '../constants/myBookingsConstants'

export const myBookingListReducer = (state = { bookings: [] }, action) => {
  switch (action.type) {
    case MY_BOOKING_LIST_REQUEST:
      return {
        loading: true
      }
    case MY_BOOKING_LIST_SUCCESS:
      return {
        loading: false,
        bookings: action.payload
      }
    case MY_BOOKING_LIST_FAIL:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}
