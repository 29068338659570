import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { BACKEND_URL } from '../../../APIConfig';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';

function Upcomingevents(props) {
  const countries = useSelector(state => state.countries.countries);
  let countryName;
  if (countries) {
    countryName = countries.find(
      country => country.id === props.event.country_id
    );
  }
  return (
    <div className='card card-event notranslate'>
      <div
        className='img-wrap img-lazy-load b-loaded'
        style={{
          backgroundImage: `url("${BACKEND_URL + props.event.poster}")`
        }}>
        {/* <a href={`/event-details/${props.event.slug}/${props.event.id}`}></a> */}
        <Link to={`/event-details/${props.event.slug}/${props.event.id}`} />
      </div>
      <div className='info-wrap'>
        {/* <div className='event-favorite'>
          <a href='#' data-tip data-for='tootip1'>
            <FontAwesomeIcon icon={faHeart} />
          </a>
          <ReactTooltip
            id='tootip1'
            effect='solid'
            className='tooltip-inner'
            backgroundColor='#31c5f4'
            borderColor='#31c5f4'
            arrowColor='#31c5f4'
          >
            <span>Add to favorites</span>
          </ReactTooltip>
        </div> */}
        <div className='event-info'>
          <h5>
            <Link
              to={`/event-details/${props.event.slug}/${props.event.id}`}
              className='text-dark notranslate'>
              {props.event.title}
              <p style={{fontSize: '13px'}}>{props.event.excerpt}</p>
            </Link>
          </h5>

          <div className='text-black-50 small notranslate'>
            <FontAwesomeIcon icon={faMapMarkerAlt} className='fa-fw' />{' '}
            {props.event.venue}, {props.event.address}, {props.event.city}
            {countryName && `, ${countryName.country_name}`}
          </div>
          <div className='text-black-50 small'>
            <FontAwesomeIcon icon={faClock} className='fa-fw' />{' '}
            {`${moment(
              `${props.event.start_date}`
            ).format('dddd DD MMM YYYY')} ${moment(
              `${props.event.start_date} ${props.event.start_time}`
            ).format('HH:mm')}`}
          </div>
        </div>
        <div className='price-wrap'>
          {/* <span className='price-new'>
            {props.event.tickets && `€${props.event.tickets.filter((tick)=>tick.status==1).sort((a,b) => a.price - b.price)[0]?props.event.tickets.filter((tick)=>tick.status==1).sort((a,b) => a.price - b.price)[0].price.replace('.', ','):'0'}`}
          </span> */}
        </div>
        <div className='clearfix'></div>
      </div>
      <span className='event-category'>{props.event.category_name}</span>
      <div className='event-date text-center'>
        <div className='event-month bg-primary text-light'>
          {moment(props.event.start_date).format('MMM').toUpperCase()}
        </div>
        <div className='event-day bg-white'>
          {moment(props.event.start_date).format('DD')}
        </div>
      </div>
    </div>
  );
}

export default Upcomingevents;
