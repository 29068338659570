import { faEdit, faPlus, faTshirt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Sidebar from "../../../sections/my-account/organizer/sidebar/Sidebar";
import Alert from "../../../sections/ui/Alert";
import Breadcrumbs from "../../../sections/ui/Breadcrumbs";
import Card from "../../../sections/ui/Card";
import CustomSelect from "../../../sections/ui/formfields/CustomSelect";

function Merchandise(props) {
  React.useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if(foundUser.user_detail.role_id !== 3){
        props.history.push('/signin')
      }
    }
    else{
      props.history.push('/signin')
    }
  }, []);
  
  return (
    <>
      <Breadcrumbs active="my-merchandise" />
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <Sidebar active="my-merchandise" />
            <div className="col-lg-9">
              <Card>
                <header className="section-heading mb-3">
                  <h4 className="title-section">
                    <FontAwesomeIcon icon={faTshirt} className="fa-w-14 mr-2" />
                    My Merchandise
                  </h4>
                </header>
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-md-3 mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        0 result(s) found
                      </span>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <label className="mr-3">Sort by</label>
                      <div className="select_white-bg w-100">
                        <CustomSelect
                          className="custom_select-div"
                          options={[
                            {
                              label: "Creation date (desc)",
                              value: "Creation date (desc)",
                            },
                            {
                              label: "Creation date (asc)",
                              value: "Creation date (asc)",
                            },
                            {
                              label: "Popularity (desc)",
                              value: "Popularity (desc)",
                            },
                            {
                              label: "Popularity (asc)",
                              value: "Popularity (asc)",
                            },
                          ]}
                          name="sorting"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="select_white-bg">
                        <CustomSelect
                          className="custom_select-div"
                          label="Category"
                          options={[
                            {
                              label: "All",
                            },
                            {
                              label: "Cinema",
                            },
                            {
                              label: "Concert Music",
                            },
                            {
                              label: "Conference",
                            },
                            {
                              label: "Exposition",
                            },
                            {
                              label: "Festival/Spectacle",
                            },
                            {
                              label: "Game/Competition",
                            },
                            {
                              label: "Hotel",
                            },
                            {
                              label: "Exposition",
                            },
                            {
                              label: "Festival/Spectacle",
                            },
                            {
                              label: "Game/Competition",
                            },
                            {
                              label: "Hotel",
                            },
                            {
                              label: "Others",
                            },
                          ]}
                          name="event-category"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3 d-flex align-items-end justify-content-end">
                      <a
                        href="/dashboard/organizer/my-merchandise/add"
                        className="btn btn-primary ml-3"
                      >
                        <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add
                        new
                      </a>
                    </div>
                  </div>
                </div>
                <Alert>No Merchandise found</Alert>
                <table className="table table-responsive-md table-striped custom-table">
                  <thead className="thead-light">
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Category</th>
                      <th>Date Added</th>
                      <th>Qty.</th>
                      <th>Size</th>
                      <th>Price</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          src="/assets/uploads/merchandise/dummy-shirt.jpg"
                          className="merchandise_table-img"
                        />
                      </td>
                      <td>
                        <a href="">Seven Rocks Show Apparel</a>
                      </td>
                      <td>Apparel</td>
                      <td>14/08/2020</td>
                      <td>42</td>
                      <td>lg</td>
                      <td>8.44 USD </td>
                      <td>
                        <a href="#" className="text-muted">
                          <FontAwesomeIcon icon={faEdit} /> edit
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="/assets/uploads/merchandise/mask.jpg"
                          className="merchandise_table-img"
                        />
                      </td>
                      <td>
                        <a href="">Seven Rocks Show mask</a>
                      </td>
                      <td>Mask</td>
                      <td>02/03/2021</td>
                      <td>20</td>
                      <td>lg</td>
                      <td>3.00 USD </td>
                      <td>
                        <a href="#" className="text-muted">
                          <FontAwesomeIcon icon={faEdit} /> edit
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Merchandise;
