import { faMoneyCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { useState } from 'react'
import Card from '../../../sections/ui/Card'
import { useTranslation } from 'react-i18next';

const CheckoutPayment = ({ checkoutHandler, freeTicket, totalFee ,setBrandType,brandtype,isFrame,buttonColor,urlColor,isccv,isviva}) => {
  const [paymentMethod, setPaymentMethod] = useState('2')
  const [brandMethod, setBrandMethod] = useState(brandtype)
  const {t, i18n} = useTranslation('common');

  useEffect(() => {
    if(isFrame)
    document.documentElement.style.setProperty('--mycolor',buttonColor);

    if (freeTicket && paymentMethod === 0) {
      setPaymentMethod('0')
    }
    if(isccv==1&&isviva==0)
    {
      //setBrandMethod("bcmc");
      //setBrandType("bcmc")
    }
    if(isccv==0&&isviva==1)
    {
      setBrandMethod("vivawallet");
      setBrandType("vivawallet")
    }
  }, [freeTicket])
  return (
    <>
      { !freeTicket && paymentMethod !== 0 && (isccv==1) && (
        <Card title={t('CheckoutPage.PaymentMethod')}>
          <div className='form-row'>
            {
              isccv==1 && <>
              <div className='col-12 col-lg-3 form-group'>
              <div className='custom-control custom-radio custom-control-inline'>
                <input
                  className='custom-control-input'
                  id='brand_Bancontact'
                  type='radio'
                  name='payment_brand'
                  value='bcmc'
                  checked={brandMethod === 'bcmc'}
                  onChange={e => {setBrandMethod(e.target.value);setBrandType(e.target.value)}}
                />
                <label
                  className='custom-control-label required'
                  htmlFor='brand_Bancontact'
                >
                  <img
                    style={{ width: 200 }}
                    src='/assets/images/payment1.png'
                    className='img-80-80 mr-3 ml-3'
                    alt=''
                  />
                </label>
              </div>
            </div>
            <div className='col-12 col-lg-3 form-group'>
              <div className='custom-control custom-radio custom-control-inline'>
                <input
                  className='custom-control-input'
                  id='brand_Visa'
                  type='radio'
                  name='payment_brand'
                  value='visa'
                  checked={brandMethod === 'visa'}
                  onChange={e => {setBrandMethod(e.target.value);setBrandType(e.target.value)}}
                />
                <label
                  className='custom-control-label required'
                  htmlFor='brand_Visa'
                >
                  <img
                    style={{ width: 200 }}
                    src='/assets/images/payment2.png'
                    className='img-80-80 mr-3 ml-3'
                    alt=''
                  />
                </label>
              </div>
            </div>
            <div className='col-12 col-lg-3 form-group'>
              <div className='custom-control custom-radio custom-control-inline'>
                <input
                  className='custom-control-input'
                  id='brand_Mastercard'
                  type='radio'
                  name='payment_brand'
                  value='mastercard'
                  checked={brandMethod === 'mastercard'}
                  onChange={e => {setBrandMethod(e.target.value);setBrandType(e.target.value)}}
                />
                <label
                  className='custom-control-label required'
                  htmlFor='brand_Mastercard'
                  
                >
                  <img
                    style={{ width: 200 }}
                    src='/assets/images/payment3.jpg'
                    className='img-80-80 mr-3 ml-3'
                    alt=''
                  />
                </label>
              </div>
            </div>
              </>
            }
            {
              isviva==1 && <>
                <div className='col-12 col-lg-3 form-group'>
              <div className='custom-control custom-radio custom-control-inline'>
                <input
                  className='custom-control-input'
                  id='brand_Vivawallet'
                  type='radio'
                  name='payment_brand'
                  value='vivawallet'
                  checked={brandMethod === 'vivawallet'}
                  onChange={e => {setBrandMethod(e.target.value);setBrandType(e.target.value)}}
                />
                <label
                  className='custom-control-label required'
                  htmlFor='brand_Vivawallet'
                  
                >
                  <img
                    style={{ width: 200,marginTop:'-15px' }}
                    src='/assets/images/viva.png'
                    className='img-80-80 mr-3 ml-3'
                    alt=''
                  />
                </label>
              </div>
            </div>
              </>
            }
          </div>
          <div className='form-row'>
            {/* <div className='col-6 form-group'>
              <div className='custom-control custom-radio custom-control-inline'>
                <input
                  className='custom-control-input'
                  id='ccv_checkout'
                  type='radio'
                  name='payment_gateway'
                  value='2'
                  checked={paymentMethod === '2'}
                  onChange={e => setPaymentMethod(e.target.value)}
                />
                <label
                  className='custom-control-label required'
                  htmlFor='ccv_checkout'
                >
                  <img
                    style={{ width: 200 }}
                    src='/assets/uploads/ccv-logo.svg'
                    className='img-80-80 mr-3 ml-3'
                    alt=''
                  />
                  CCV
                </label>
              </div>
            </div> */}
            
            <div className='col-6 form-group'>
              <input type='hidden' name='payment_gateway' value='0' />
            </div>
          </div>
        </Card>
      )}

      <button
        id='checkout_submit'
        type='submit'
        className='btn btn-primary btn-block mt-4'
        style={isFrame?{backgroundColor:buttonColor,borderColor:buttonColor}:{}}
        // disabled='disabled'
        onClick={() => checkoutHandler(brandMethod)}
      >
        <FontAwesomeIcon icon={faMoneyCheck} className='mr-2' /> {t('CheckoutPage.PayNow')}
      </button>
    </>
  )
}

export default CheckoutPayment
