import Alert from '../../../sections/ui/Alert';
import React, { useState } from 'react';
import Sidebar from '../../../sections/my-account/attendee/sidebar/Sidebar';
import Breadcrumbs from '../../../sections/ui/Breadcrumbs';
import Card from '../../../sections/ui/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTicketAlt
} from '@fortawesome/free-solid-svg-icons';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'

import $ from 'jquery';
import Popup from '../../../sections/ui/Popup/Popup';
import { useDispatch, useSelector } from 'react-redux';
import MyBookingItem from './MyBookingItem';
import { useEffect } from 'react';
import { myBookings } from '../../../service/actions/myBookingsActions';
import CustomLoader from '../../../sections/ui/CustomLoader';
import OrderDetail from './OrderDetail';
import { useHistory } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import {useTranslation} from "react-i18next";


function Dashboard() {
  const {t, i18n} = useTranslation('common');
  const myBookingList = useSelector(state => state.myBookingList);
  const dispatch = useDispatch();
  const { loading, error, bookings } = myBookingList;
  const [showPopup, setShowPopup] = useState(false);
  const [order, setOrder] = useState();
  const history = useHistory();
  const perPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (!foundUser.user_detail.role_id) {
        history.push('/signin');
      }
    } else {
      history.push('/signin');
    }
    dispatch(myBookings(currentPage),(ev)=>{});
   
  }, [dispatch, history, currentPage]);

  useEffect(() => {
    if(myBookingList.bookings){ setTimeout(()=>{
      if ( ! $.fn.DataTable.isDataTable('#table1')) {
      $('#table1').DataTable({  
        "scrollX": true,
        "order": []
    });
  }
    },50)}
  }, [myBookingList]);

  const deleteBooking = id => {
  };

  const paginationHandler = pageNumber => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <Breadcrumbs active='' />
      <Popup
        className='modal-lg modal-dialog-scrollable'
        openModal={showPopup}
        closeModal={() => setShowPopup(false)}
        title={
          <>
            <FontAwesomeIcon icon={faTicketAlt} className='mr-1' />
            {t('AttendeeDashoboardPage.TicketDetails')}
          </>
        }
        footer='close'>
        <OrderDetail order={order} />
      </Popup>
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='my-tickets' />
            <div className='col-lg-9'>
              <Card
                title={`${
                  !loading && bookings && bookings.data
                    ? bookings.data.length
                    : 0
                } ${t('TicketsFound')}`}>
                <div className='pb-3 px-3 pt-1'>
                  <div className='row'>
                    <div className='col-12 col-md-12'>
                      {loading || !bookings ? (
                        <CustomLoader
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {!loading && error && <Alert type='error'>{error}</Alert>}
                      {!loading &&
                        bookings &&
                        bookings.data &&
                        bookings.data.length <= 0 && (
                          <Alert type='error'>{t('NoTicketsFound')}</Alert>
                        )}
                      {!loading &&
                        bookings &&
                        bookings.data &&
                        bookings.data.length > 0 && (
                          <>
                            <Table id="table1" className='table table-responsive-md table-striped custom-table'>
                              <Thead className='thead-light'>
                                <Tr>
                                  <Th>{t('AttendeeDashoboardPage.Actions')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.Order#')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.EventTitle')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.TicketType')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.Quantity')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.EventStartDateTime')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.EventEndDateTime')}</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {bookings &&
                                  bookings.data &&
                                  bookings.data.map(booking => (
                                    <MyBookingItem
                                      key={booking.id}
                                      booking={booking}
                                      deleteBooking={deleteBooking}
                                      setShowPopup={setShowPopup}
                                      setOrder={setOrder}
                                      order={booking}
                                    />
                                  ))}
                              </Tbody>
                            </Table>
                            {
                              <div style={{ float: 'right' }}>
                                {/* <Pagination
                                  prevPageText='<'
                                  nextPageText='>'
                                  hideFirstLastPages={true}
                                  itemClass='page-item'
                                  linkClass='page-link'
                                  activePage={currentPage}
                                  itemsCountPerPage={perPage}
                                  totalItemsCount={bookings.total}
                                  pageRangeDisplayed={5}
                                  onChange={paginationHandler}
                                /> */}
                              </div>
                            }
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard;
