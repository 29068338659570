import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const RadioInput2 = props => (
  <fieldset className={`form-group ${props.className ? 'remove-margin' : ''}`} >
    <legend className={`col-form-label ${props.required ? 'required' : ''} ${props.className ? 'text-align-left' : ''}`} >
      {props.label}
    </legend>
    {props.info ? (
      <small className='form-text text-muted mb-3'>
        <FontAwesomeIcon icon={faInfoCircle} className='text-primary mr-1' />
        {props.info}
      </small>
    ) : (
      ''
    )}
    <div className={props.className?props.className:''}>
      {props.entries.map(val => (
        <div
          className='custom-control custom-radio custom-control-inline'
          key={val.id}
        >
          <input
            type='radio'
            id={val.id}
            name={val.name}
            value={val.value}
            className='custom-control-input checkboxmy'
            defaultChecked={val.selected}
            onChange={props.onChange}
          />
          <label
            className={`custom-control-label ${
              props.required ? 'required' : ''
            }`}
            htmlFor={val.id}
          >
            {val.label}
          </label>
        </div>
      ))}
    </div>
  </fieldset>
)

export default RadioInput2
