import React, { useState } from 'react';
import {
  faEnvelopeOpenText,
  faMapMarker,
  faPhone
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumbs from '../../sections/ui/Breadcrumbs';
import Card from '../../sections/ui/Card';
import LabeledInput from '../../sections/ui/formfields/LabeledInputField';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Alert from '../../sections/ui/Alert';
import { CONTACT_API, SETTINGS_API } from '../../APIConfig';
import CustomLoader from '../../sections/ui/CustomLoader';
import {useTranslation} from "react-i18next"; 
import CustomSelect from '../../sections/ui/formfields/CustomSelect';
import { useAlert } from 'react-alert';
const authDataString = localStorage.getItem('user');

function ContactUs(props) {
  const {t, i18n} = useTranslation('common');
  const [SettingData, setSettingData] = useState([]);
  const [FormSubmitData, setFormSubmitData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [reason, setReason] = useState({
    label: t('q5'),
    value: 'Other'
  });
  const alert = useAlert();
  const { register, handleSubmit, reset } = useForm();
  React.useEffect(() => getSettingData(), [reset]);

  const onSubmit = (formData, e) => {
    if(reason.value == "I have a question about an order / my tickets" && authDataString)
    window.location.href = "/dashboard/attendee/my-tickets";
    else
    SubmitContactForm(formData, e);
  };
  const SubmitContactForm = (formData, e) => {
    setLoading(true);
    axios({
      url: CONTACT_API,
      method: 'GET',
      params: {
        name: formData.username,
        email: formData.useremail,
        title: formData.usersubject,
        message: formData.usermessage,
        reason:reason.value
      }
    })
      .then(function (res) {
        setFormSubmitData(res.data);
        e.target.reset();
        setError('')
        setLoading(false);
        alert.success(t('MailSentSuccessfully'));
      })
      .catch(err => {
        setError(t('TryAgain'));
        setLoading(false);
      });
    
  };

  const getSettingData = async () => {
    try {
      await axios({
        url: SETTINGS_API,
        method: 'GET'
      }).then(function (res) {
        setSettingData(res.data.data);
      });
    } catch {}
  };
  let address = SettingData.find(o => o.key === 'contact.address');
  let phone = SettingData.find(o => o.key === 'contact.phone');
  let email = SettingData.find(o => o.key === 'contact.email');
  return (
    <>
      <Breadcrumbs active='contact-us' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <Card>
                <h3 className='text-center mb-5'>{t('ContactInformation')}</h3>
                <div className='row'>
                  <div className='col-12 col-lg-4 card border-0 card-body text-center shadow-none'>
                    <figure className='itemside'>
                      <div className='aside'>
                        <span className='icon-wrap icon-md round bg-info'>
                          <FontAwesomeIcon
                            icon={faEnvelopeOpenText}
                            className='white'
                          />
                        </span>
                      </div>
                      <figcaption className='text-wrap align-self-center'>
                        <h6 className='title mb-0' >
                          {email === undefined ? '' : email.value}
                        </h6>
                      </figcaption>
                    </figure>
                  </div>

                  <div className='col-12 col-lg-4 card border-0 card-body text-center shadow-none'>
                    <figure className='itemside'>
                      <div className='aside'>
                        <span className='icon-wrap icon-md round bg-info'>
                          <FontAwesomeIcon icon={faPhone} className='white' />
                        </span>
                      </div>
                      <figcaption className='text-wrap align-self-center'>
                        <h6 className='title mb-0' >
                          {phone === undefined ? '' : phone.value}
                        </h6>
                      </figcaption>
                    </figure>
                  </div>

                  {/* <div className="col-12 col-lg-4 card border-0 card-body text-center shadow-none">
                    <figure className="itemside">
                      <div className="aside">
                        <span className="icon-wrap icon-md round bg-info">
                          <FontAwesomeIcon icon={faFax} className="white" />
                        </span>
                      </div>
                      <figcaption className="text-wrap align-self-center">
                        <h6 className="title" className="mb-0">+123456789</h6>
                      </figcaption>
                    </figure>
                  </div> */}

                  <div className='col-12 col-lg-4 card border-0 card-body shadow-none'>
                    <figure className='itemside'>
                      <div className='aside'>
                        <span className='icon-wrap icon-md round bg-info'>
                          <FontAwesomeIcon
                            icon={faMapMarker}
                            className='white'
                          />
                        </span>
                      </div>
                      <figcaption className='text-wrap align-self-center'>
                        <h6 className='title mb-0'>
                          {address === undefined ? '' : address.value}
                        </h6>
                      </figcaption>
                    </figure>
                  </div>
                </div>
                <hr className='mt-5 mb-5' />
                <h3 className='text-center mb-5'>{t('SendUsEmail')}</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='col-12 col-lg-8 mx-auto'>
                    {error && <Alert type='error'>{error}</Alert>}
                    {FormSubmitData.success === true ? (
                      <Alert type='success' children={FormSubmitData.message} />
                    ) : null}
                          <CustomSelect
                            className='custom_select-div'
                            label={t('selectreason')}
                            register={register}
                            options={[
                              { label: t('q1'), value: 'I have a question about an order / my tickets' },
                              { label: t('q2'), value: 'I have a question regarding registering' },
                              { label: t("q3"), value: "I can't login"},
                              { label: t('q4'), value: 'I want to sell tickets' },
                              { label: t('q5'), value: 'Other' }
                            ]}
                            name='reason'
                            id='reason'
                            value={reason}
                            required={true}
                            isClearable={false}
                            handleChange={e => {
                              setReason(e)
                            }}
                          />
                    <LabeledInput
                      type='text'
                      label={t('Name')}
                      id='username'
                      register={register}
                      name='username'
                      required
                    />
                    <LabeledInput
                      type='email'
                      label={t('Email')}
                      id='useremail'
                      register={register}
                      name='useremail'
                      required
                    />
                    <LabeledInput
                      type='text'
                      label={t('Subject')}
                      id='usersubject'
                      register={register}
                      name='usersubject'
                      required
                    />
                    <LabeledInput
                      textArea
                      label={t('Message')}
                      required
                      id='usermessage'
                      register={register}
                      name='usermessage'></LabeledInput>
                    {loading && (
                      <CustomLoader style={{ textAlign: 'center' }} />
                    )}
                    {!loading && (
                      <div className='form-group'>
                        <button
                          type='submit'
                          id='form_save'
                          name='form-submit'
                          className='btn btn-primary btn-block btn'>
                          {t('SendButton')}
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
