import Card from '../../../../sections/ui/Card';
import CustomFileInput from '../../../../sections/ui/formfields/CustomFileInput';
import CustomSelect from '../../../../sections/ui/formfields/CustomSelect';
import LabeledInput from '../../../../sections/ui/formfields/LabeledInput';
import DatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { EVENT_TICKETS_ALL,ADD_COUPON_API,EVENTS_FILTER_API } from '../../../../APIConfig';
import axios from 'axios';
import moment from 'moment';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import {useTranslation} from "react-i18next";

const couponTypeArray = [
  {
    label: 'Fixed Discount',
    value: 1
  },
  {
    label: ' % Discount',
    value: 2
  }
];

const couponapplyTypeArray = [
  {
    label: 'Tickets Only',
    value: 0
  },
  {
    label: 'Supplements Only',
    value: 1
  },
  {
    label: 'Tickets And Supplements',
    value: 2
  }
];

const couponFixedTypeArray = [
  {
    label: 'Discount Per Ticket',
    value: 0
  },
  {
    label: 'Fixed Discount',
    value: 1
  }
];
const initialFilters = {
  sort_by: null,
  category_id: null,
  status: 2,
  expiredallow:"false"
};
const CouponForm = ({ edit, showAlert, coupon }) => {
  const {t, i18n} = useTranslation('common');

  const initialFormData = {
    coupon_code: '',
    description: '',
    coupon_type: {
      label: 'Fixed Discount',
      value: 1
    },
    amount: '',
    minimum_amount: '',
    maximum_amount: '',
    limit_usage: '',
    start_date: new Date(),
    end_date: new Date(),
    status: {
      label: 'Active',
      value: 1
    },
    coupon_ticket_limit:'0',
    coupon_fixed_type: {
      label: 'Discount Per Ticket',
      value: 0
    },
    coupon_apply_type:
    {
      label: 'Tickets Only',
      value: 0
    },
    added_tickets:[],
    event_id:''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [eventData, setEventData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [alleventData, setAllEventData] = useState([]);
  const [allticketData, setAllTicketData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [reload, setReload] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (coupon !== null) setFormData(coupon);
    return () => {
      setFormData(initialFormData);
    };
    
  }, [coupon]);

  const {
    coupon_code,
    description,
    coupon_type,
    amount,
    minimum_amount,
    maximum_amount,
    limit_usage,
    start_date,
    end_date,
    status,
    coupon_ticket_limit,
    coupon_fixed_type,
    coupon_apply_type,
    added_tickets,
    event_id
  } = formData;

  const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`,
  Accept: 'application/json'
};

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const formSubmit = async e => {
    e.preventDefault();
    const userData = localStorage.getItem('user');
    const userDataObj = JSON.parse(userData);

    let token = userDataObj !== null && userDataObj.access_token;

    if (!token) {
      return;
    }

    const data = {
      ...formData,
      coupon_type: coupon_type.value,
      coupon_fixed_type: coupon_fixed_type.value,
      coupon_apply_type:coupon_apply_type.value,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
      status: status.value
    };

    if(data.added_tickets.length==0)
    {
      showAlert(
        t('fillallfields'),
        'error'
      );
      return;
    }
  let updateaddedtickets= [];
   data.added_tickets.forEach(vl=>{
   updateaddedtickets.push(vl.value)
   })
   data.added_tickets=updateaddedtickets;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };

    setIsLoading(true);
    try {
      await axios.post(ADD_COUPON_API, data, { headers });
      showAlert(t('CouponAddedUpdated'), 'success');
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors !== undefined) {
        showAlert(t('PleaseCheckAllfields'), 'error');
      } else {
        showAlert(
          t('CouponNotAdded'),
          'error'
        );
      }
    }

    setIsLoading(false);
  };

  
  useEffect(() => {
    const getEvents = async () => {
      try {
        setIsLoading(true);
        const headers = {
          Authorization: `Bearer ${authData && authData.access_token}`
        };
        const { data } = await axios.post(
          `${EVENTS_FILTER_API}?page=${currentPage}`,
          filters,
          {
            headers
          }
        );
       setAllEventData(data);
       let catArray = data.myevents.data.map(cat => ({
        label: cat.title,
        value: cat.id
      }));
      setEventData(catArray);
      setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    getEvents();
  }, [filters, currentPage, reload]);

  const getTickets = async (id) => {
      
    try {
      const { data } = await axios.post(
        EVENT_TICKETS_ALL,
        { event_id: id },
        { headers }
      );
      setAllTicketData([]);
      if (data.status && data.tickets.length > 0) {
        setAllTicketData(data.tickets);
      }
  let catArray =data.tickets.map(cat => ({
    label: cat.title,
    value: cat.id
  }));
  setTicketData(catArray);
 
    } catch (err) {
     
    }
  };
  return (
    <>
      <div className='col-lg-12'>
        <Card>
          <form onSubmit={formSubmit}>
            <div className='row'>
              <div className='col-12 col-md-12'>
                <LabeledInput
                  type='text'
                  label={t('CouponPage.CouponCode')}
                  id='coupon_code'
                  name='coupon_code'
                  required
                  placeholder={t('CouponPage.CouponCode')}
                  value={coupon_code}
                  onChange={onChange}
                />
              </div>
              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='text'
                  label={t('CouponPage.CouponTitle')}
                  id='description'
                  name='description'
                  required
                  placeholder={t('CouponPage.CouponTitle')}
                  value={description}
                  onChange={onChange}
                />
              </div>
              <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  required={true}
                  label={t('CouponPage.CouponType')}
                  defaultValue={coupon_type}
                  options={couponTypeArray}
                  name='coupon_type'
                  isClearable={false}
                  isSearchable={false}
                  value={coupon_type}
                  handleChange={e =>
                    setFormData(state => ({
                      ...state,
                      coupon_type: e
                    }))
                  }
                />
              </div>

              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='number'
                  label={t('CouponPage.AmountPercentage')}
                  id='amount'
                  name='amount'
                  required
                  placeholder={t('CouponPage.AmountPercentage')}
                  value={amount}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                  min='0'
                />
              </div>

              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='number'
                  label={t('CouponPage.MinimumAmount')}
                  id='minimum_amount'
                  name='minimum_amount'
                  required
                  placeholder={t('CouponPage.MinimumAmount')}
                  value={minimum_amount}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                  min='0'
                />
              </div>
              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='number'
                  label={t('CouponPage.MaximumAmount')}
                  id='maximum_amount'
                  name='maximum_amount'
                  required
                  placeholder={t('CouponPage.MaximumAmount')}
                  value={maximum_amount}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                  min='0'
                />
              </div>
              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='number'
                  label={t('CouponPage.UsageLimit')}
                  id='limit_usage'
                  name='limit_usage'
                  required
                  placeholder={t('CouponPage.UsageLimit')}
                  value={limit_usage}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                  min='0'
                />
              </div>
              <div className='col-12 col-md-12'>
              <CustomSelect
                          className='custom_select-div'
                          required={true}
                          label={t('MyEventPage.SelectEvent')}
                          options={eventData}
                          name='event_id'
                          isClearable={false}
                          isSearchable={false}
                          value={event_id}
                          handleChange={e => {
                          
                            getTickets(e.value)
                            setFormData(state => ({
                              ...state,
                              event_id: e
                            }));
                          }}
                        />
              </div>
              <div className='col-12 col-md-12'>
                <CustomSelect
                  className='custom_select-div'
                  label={t('MyEventPage.SelectTicket')}
                  placeholder={t('MyEventPage.SelectTicket')}
                  name='added_tickets'
                  isSearchable={false}
                  isMulti
                  value={added_tickets}
                  options={ticketData}
                  handleChange={e => 
                  {
                    setFormData(state => ({
                      ...state,
                      added_tickets: e
                    }));
                  }}
                />
              </div>
              <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <label htmlFor='start_date' className='required'>
                  {t('CouponPage.StartDate')}
                  </label>
                  <DatePicker
                    selected={start_date}
                    onChange={date =>
                      setFormData(state => ({
                        ...state,
                        start_date: date
                      }))
                    }
                    id='start_date'
                    className='form-control w-100'
                    placeholderText='YYYY-MM-DD'
                    dateFormat='yyyy-MM-dd'
                  />
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <label htmlFor='end_date' className='required'>
                  {t('CouponPage.EndDate')}
                  </label>
                  <DatePicker
                    selected={end_date}
                    onChange={date =>
                      setFormData(state => ({
                        ...state,
                        end_date: date
                      }))
                    }
                    id='end_date'
                    className='form-control w-100'
                    placeholderText='YYYY-MM-DD'
                    dateFormat='yyyy-MM-dd'
                  />
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='number'
                  min="1"
                  label={t('CouponPage.TicketLimit')}
                  id='coupon_ticket_limit'
                  name='coupon_ticket_limit'
                  required
                  placeholder={t('CouponPage.TicketLimit')}
                  value={coupon_ticket_limit}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                />
              </div>
              {coupon_type.value==1&&<>
                <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  required={true}
                  label={t('CouponPage.FixedType')}
                  defaultValue={coupon_fixed_type}
                  options={couponFixedTypeArray}
                  name='coupon_type'
                  isClearable={false}
                  isSearchable={false}
                  value={coupon_fixed_type}
                  handleChange={e =>
                    setFormData(state => ({
                      ...state,
                      coupon_fixed_type: e
                    }))
                  }
                />
              </div>
              
              </>}
              <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  label={t('CouponPage.ApplyType')}
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={coupon_apply_type}
                  value={coupon_apply_type}
                  handleChange={e =>
                    setFormData(state => ({
                      ...state,
                      coupon_apply_type: e
                    }))
                  }
                  options={couponapplyTypeArray}
                />
              </div>
              <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  label={t('CouponPage.CouponStatus')}
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={status}
                  value={status}
                  handleChange={e =>
                    setFormData(state => ({
                      ...state,
                      status: e
                    }))
                  }
                  options={[
                    {
                      label: 'Active',
                      value: 1
                    },
                    {
                      label: 'Inactive',
                      value: 0
                    }
                  ]}
                  name='status'
                />
              </div>

              
            </div>
            {/* {isLoading && (
              
            )} */}
            {isLoading && (
              <Loader
                type='ThreeDots'
                color='#31c5f4'
                height={50}
                width={50}
                timeout={3000} //3 secs
              />
            )}
            {!isLoading && !edit && (
              <button className='btn btn-primary ml-3'>{t('CouponPage.CreateButton')}</button>
            )}

            {!isLoading && edit && (
              <button className='btn btn-primary ml-3'>{t('CouponPage.UpdateButton')}</button>
            )}
          </form>
        </Card>
      </div>
    </>
  );
};

export default CouponForm;
