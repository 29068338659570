import { faEdit, faTrash ,faEye, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import moment from 'moment';

const VoucherItem = ({ voucher, showEdit, deleteVoucher }) => {
    const { t, i18n} = useTranslation('common');
    const {
        id,
        user_id,
        title,
        tickets,
        added_tickets,
        template_type,
        template_image,
        voucher_type,
        generated_code,
        created_at,
        updated_at,
    } = voucher
    return (
        <Tr>
            <Td  style={{ display:'inline-grid'}}>
                <span
                    className='text-muted ml-2'
                    style={{ cursor: 'pointer' }}
                    onClick={() => showEdit(voucher)}
                    title={t('Voucher.EditVoucher')}
                >
                    <FontAwesomeIcon icon={faEdit} />
                </span>
                <span
                    className='text-muted ml-2'
                    style={{ cursor: 'pointer' }}
                    onClick={() => deleteVoucher(id)}
                    title={t('Voucher.DeleteVoucher')}
                >
                    <FontAwesomeIcon icon={faTrash} color='red'/>
                </span>
            </Td>
            <Td className="notranslate">{title}</Td>
            <Td>{voucher_type == 1 ? t('Voucher.VoucherTicket'):t('Voucher.VoucherOrder')}</Td>
            <Td>{generated_code == 0 ? t('No') :  t('Yes')}</Td>
            {/* <Td>{template_type == "standard" ? "Standard":"Custom"}</Td> */}
            <Td className="notranslate">{moment(created_at).format('DD-MMM-YYYY')}</Td>
        </Tr>
    )
}

export default VoucherItem
