import { faAngleRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const authUser = localStorage.getItem('user');
const authData = JSON.parse(authUser);

function Breadcrumbs(props) {
  return (
    <section className='section-pagetop bg-gray'>
      <div className='container clearfix'>
        <h4 className='title-page dark b float-xl-left mb-0'>
          <span style={{ textTransform: 'capitalize' }}>
            {props.active.split('-').join(' ')}
          </span>
        </h4>
        <nav className='float-xl-right mt-2 mt-xl-0'>
          <ol className='breadcrumb text-white'>
            <li className='breadcrumb-item'>
              <Link to='/' className='dark'>
                <FontAwesomeIcon icon={faHome} />
              </Link>
              <FontAwesomeIcon icon={faAngleRight} className='breadArrows' />
            </li>
            {authData && (
              <li className='breadcrumb-item'>
                <Link
                  to={`/dashboard/${
                    authData.role_id === 2 ? 'attendee/my-tickets' : 'organizer'
                  }`}
                  className='dark'>
                  Dashboard
                </Link>
                {props.isHome ? (
                  ''
                ) : (
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className='breadArrows'
                  />
                )}
              </li>
            )}
            {props.isHome ? (
              ''
            ) : (
              <li className='breadcrumb-item active' aria-current='page'>
                <span style={{ textTransform: 'capitalize' }}>
                  {props.active.split('-').join(' ')}
                </span>
              </li>
            )}
          </ol>
        </nav>
      </div>
    </section>
  );
}
export default Breadcrumbs;
