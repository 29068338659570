import { faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useSelector} from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const CartIcon = () => {
  const cartItems = useSelector(state => state.cart.cartItems)
  const {t, i18n} = useTranslation('common');
  const getdata=()=>{
    let sum=0;
  if(cartItems) 
  {
  cartItems.forEach(element => {
    sum+=element.ticket_qty;
  });
  }
  return sum;
  } 
  return (
    <div className='widget-header dropdown'>
      <Link
        to='/dashboard/attendee/cart'
        className='icontext badge-notification badge-notification-left'
        data-toggle='dropdown'
        data-offset='20,10'
        data-badge={getdata()}
      >
        <div className='icon-wrap icon-xs bg-primary round text-white'>
          <FontAwesomeIcon icon={faShoppingBag} />
        </div>
        {/* <div className='text-wrap'>
          <span>
            Cart <FontAwesomeIcon icon={faShoppingBag} />
          </span>
        </div> */}
      </Link>
      <div className='dropdown-menu dropdown-menu-arrow dropdown-menu-right p-3'>
        <p className='alert alert-info alert-icon'>
        <FontAwesomeIcon icon={faShoppingBag} />{t('YourCartIsEmpty')}
        </p>{' '}
      </div>
    </div>
  )
}

export default CartIcon
