import { faPlus, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../../sections/ui/Alert';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useTranslation } from 'react-i18next';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import $ from 'jquery';
import { EVENTS_FILTER_API,EVENT_TICKETS_ALL,SALES_EVENT_ALL } from '../../../../APIConfig';
import CustomSelect from '../../../../sections/ui/formfields/CustomSelect';
import moment from 'moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import DateInput from '../../../../sections/ui/formfields/LabededDateField';
  import { useAlert } from 'react-alert';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
function SalesGraph(props)
{  
  const alert = useAlert();
  const options = {
    responsive: true,
    scales: {
      y: {
          ticks: {
              precision: 0
          }
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Sales Graph',
      },
    },
  };
const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`
};
    const initialFormData = {
      group_id: {
        label: 'Group By Days',
        value: 0
      },
    };
    const group_by_options = [
      {
        label: 'Group By Days',
        value: 0
      },
      {
        label: 'Group By Hours',
        value: 1
      }
    ];
    const initialFilters = {
        sort_by: null,
        category_id: null,
        status: 2,
      };
    
    const [eventData, setEventData] = useState([]);
    const [graphData, setGraphData] = useState();
    const [ticketData, setTicketData] = useState([]);
    const [filters, setFilters] = useState(initialFilters);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [isEmpty, setisEmpty] = useState(false);
    const [reload, setReload] = useState(false);
    const [alleventData, setAllEventData] = useState([]);
    const [formData, setFormData] = useState(initialFormData);
    const {t, i18n} = useTranslation('common');
    const [allticketData, setAllTicketData] = useState([]);
    const {
        event_id,
        ticket_id,
        group_id,
        start_date,
        end_date
      } = formData;
    useEffect(() => {
        const getEvents = async () => {
          try {
            setLoading(true);
            const headers = {
              Authorization: `Bearer ${authData && authData.access_token}`
            };
            const { data } = await axios.post(
              `${EVENTS_FILTER_API}?page=${currentPage}`,
              filters,
              {
                headers
              }
            );
           setAllEventData(data);
           let catArray = data.myevents.data.map(cat => ({
            label: cat.title,
            value: cat.id,
            event_detail:cat
          }));
          setEventData(catArray);
            setLoading(false);
          } catch (err) {
            setLoading(false);
          }
        };
    
        getEvents();
      }, [filters, currentPage, reload]);

      const getTickets = async (id) => {
        try {
          const { data } = await axios.post(
            EVENT_TICKETS_ALL,
            { event_id: id },
            { headers }
          );
          setAllTicketData([]);
          if (data.status && data.tickets.length > 0) {
            setAllTicketData(data.tickets);
          }
          if(data && data.tickets)
          {
      let catArray =data.tickets.map(cat => ({
        label: cat.title,
        value: cat.id
      }));
      
      catArray.unshift({
        label: t('MyEventPage.AllTickets'),
        value: 0
      })
      setTicketData(catArray);
    }
      setFormData(state => ({
        ...state,
        ticket_id: {
          label: t('MyEventPage.AllTickets'),
          value: 0
        }
      }));
    } 
    catch (err) 
    {
    }
};

  const formSubmit = async e => {
    e.preventDefault();
    const userData = localStorage.getItem('user');
    const userDataObj = JSON.parse(userData);
    let token = userDataObj !== null && userDataObj.access_token;
    if (!token) {
      return;
    }
    if(!event_id || !ticket_id)
    {
      alert.error(t('fillallfields'));
      return;
    }
    const dataForm = {
      ...formData,
      group_id: group_id.value,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
      event_id:event_id.value,
      ticket_id:ticket_id.value
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    if(group_id.value==0)
    {
    if(moment(end_date).diff(moment(start_date),'days')<0)
    {
      alert.error(t('DashboardPage.dategreater'));
      return;
    }
   /* if(moment(end_date).diff(moment(start_date),'days')>7)
    {
      alert.error(t('DashboardPage.maxdays'));
      return;
    }*/
  }
    setLoading(true);
    try {
        const { data } = await axios.post(SALES_EVENT_ALL, dataForm, { headers });
        var allsales=data.data
        let startDate = moment(start_date);
        let endDate = moment(end_date);
        let datelabel = [];
        var datashow=[]
        if(group_id.value==0)
        {
        // for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
        //   datelabel.push(m.format('YYYY-MM-DD'));
        //  }
        for (const sale of allsales) {
          datelabel.push(sale.created);
          datashow.push(sale.total_booked);
        }
        }
        else
        {
          for (var i=0;i<24;i++) {
            datelabel.push(i);
           } 
        for(var ty=0;ty<datelabel.length;ty++)
        {
          let index = allsales.findIndex((res)=>{
            return res.created==datelabel[ty]
          })
          if(index!=-1)
          {
            datashow.push(parseFloat(allsales[index].total_booked))
          }
          else
          {
            datashow.push(parseFloat(0))
          }
        }
        }

        

    var graphlabel = ticket_id.value==0?event_id.label:event_id.label+" ("+ticket_id.label+")";
    setGraphData({
      labels:datelabel,
      datasets: [
        {
          label: graphlabel,
          data: datashow,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    })

    } catch (error) {
      const errors = error.response.data.errors;
      if (errors !== undefined) {
        alert.error(t('PleaseCheckAllfields'));
      } else {
        alert.error(t('FailedCreation'));
      }
    }
    setLoading(false);
  };
 
    return (
        <>
          <Breadcrumbs active='sales-graph' />
          <section className='section-content padding-y bg-white'>
            <div className='container'>
              <div className='row'>
                <Sidebar active='sales-graph' />
               
               
                <div className='col-lg-9 mt-4 mt-lg-0'>
                <Card>
                <header className='section-heading mb-3'>
                  <h4 className='title-section'>
                    <FontAwesomeIcon icon={faTags} className='fa-w-14 mr-2' />
                    {t('DashboardPage.SalesGraph')}
                  </h4>
                </header> 
                <form onSubmit={formSubmit}>
                <div className='box shadow-none bg-gray mb-4'>
                  <div className='row'>
 
                    <div className='col-sm-12 col-md-12 d-flex align-items-end justify-content-center'>
                    <button
                 type='submit'
                  onClick={()=>{}}
                  name='submit'
                  className='btn btn-primary btn'>
                  {t('DashboardPage.GetSales')}
                </button>
                      </div>
                      </div>
                      </div> 
               
                  <div className='row'>
                  <div className='col-sm-12 col-lg-12'>
                  <CustomSelect
                              className='custom_select-div'
                              required={true}
                              label={t('DashboardPage.GroupBy')}
                              options={group_by_options}
                              name='group_by'
                              isClearable={false}
                              isSearchable={false}
                              value={group_id}
                              handleChange={e => {
                                setFormData(state => ({
                                  ...state,
                                  group_id: e
                                }));
                              }}
                            />
                  </div>
                  <div className='col-sm-6 col-lg-6'>
                  <CustomSelect
                              className='custom_select-div'
                              required={true}
                              label={t('MyEventPage.SelectEvent')}
                              options={eventData}
                              name='event_id'
                              isClearable={false}
                              isSearchable={false}
                              value={event_id}
                              handleChange={e => {
                                setisEmpty(false);
                                getTickets(e.value)
                                setFormData(state => ({
                                  ...state,
                                  event_id: e
                                }))
                                setFormData(state => ({
                                  ...state,
                                  start_date: new Date(e.event_detail.created_at)
                                }));
                                setFormData(state => ({
                                  ...state,
                                  end_date: new Date(e.event_detail.end_date)
                                }));
                              }}
                            />
                  </div>
                  <div className='col-sm-6 col-lg-6'>
                  <CustomSelect
                              className='custom_select-div'
                              required={true}
                              label={t('MyEventPage.SelectTicket')}
                              options={ticketData}
                              name='ticket_id'
                              isClearable={false}
                              isSearchable={false}
                              value={ticket_id}
                              handleChange={e => {
                                setisEmpty(false);
                                setFormData(state => ({
                                  ...state,
                                  ticket_id: e
                                }));
                              }}
                            />
                      </div>
                      <div className='col-sm-6 col-lg-6'>
                      <DateInput
                            label={t('MyEventPage.StartDate')}
                            type='text'
                            required
                            id='start_date'
                            autoComplete='off'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                start_date: date
                              }));
                            }}
                            startDate={start_date}
                            maxDate={moment().toDate()}
                          />
                      </div>
                      {group_id.value==0?<>
                        <div className='col-12 col-md-6'>
                          <DateInput
                            label={t('MyEventPage.EndDate')}
                            type='text'
                            autoComplete='off'
                            required
                            id='end_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                end_date: date
                              }));
                            }}
                            startDate={end_date}
                            maxDate={moment().toDate()}
                          />
                        </div>
                      </>:<></>}
                    
                 </div> 
                
                </form>
                {loading ? (
                    <CustomLoader style={{ textAlign: 'center' }} />
                  ) : (
                    <> {graphData?.datasets && (
                           <div>
                           <Line options={options} data={graphData} />
                         </div> 
                    )
                      }
                    </>
                  )}
                  </Card>
                </div>
                
              </div>
              
            </div>
          </section> 
        
        </>
      );
};
export default SalesGraph;