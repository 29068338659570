import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
function FilterCollapsible(props) {
  const [open, SetOpen] = useState(true);

  const clickHandler = (e) => {
    e.preventDefault();
    SetOpen(!open);
  };
  const handleHtml = (val) => {
    return val;
  };

  return (
    <>
      <article className="card-group-item">
        <header className="card-header">
          <a
            href="#"
            className={`${props.className ? props.className : ""}`}
            onClick={clickHandler}
          >
            <FontAwesomeIcon
              icon={open ? faChevronDown : faChevronUp}
              className="icon-action"
            />
            <h6 className="title text-capitalize">{handleHtml(props.title)}</h6>
          </a>
        </header>
        <div className={`filter-content collapse ${open ? "show" : ""}`}>
          <div className="card-body">{props.children}</div>
        </div>
      </article>
    </>
  );
}

export default FilterCollapsible;
