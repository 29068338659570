import React, { useState } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleLeft,
  faClock,
  faTicketAlt,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
// import { useForm } from "react-hook-form";
import { BANNERS_API } from "../../../APIConfig";
import { Link } from "react-router-dom";
import moment from "moment";
import * as icons from "@fortawesome/free-solid-svg-icons";
import parse from 'html-react-parser';

function Homeslider(props) {
  const [bannerData, setbannerData] = useState([]);
  const iconArray = Object.values(icons);
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img
      src="/assets/uploads/arrow-angle-pointing-to-left.png"
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img
      src="/assets/uploads/arrow-angle-pointing-to-right.png"
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    />
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
  };

  React.useEffect(() => getBanners(), []);

  const getBanners = async () => {
    try {
      await axios({
        url: BANNERS_API,
        method: "GET",
      }).then(function (res) {
        setbannerData(res.data.data);

    });
    } catch {}
  };

  return (
    <>
      <Slider {...settings} className="slider-main homepage-slider">
        {bannerData.length > 0 &&
          bannerData.map((banner, index) => (
            <div key={index}>
              <div
                className="item-slide"
                style={{
                  background: `url(${banner.image})`,
                }}
              >
                <div className="content-container">
                  <div className="container">
                    <div className="row-sm">
                      <div className="col-md-12" style={{ paddingLeft: 50 }}>
                        <h2 className="text-light mb-4 notranslate">
                          {banner.title}
                        </h2>
                        {
                              banner.event_description?
                              (
                              <p className="text-light mb-4">
                              {parse(banner.event_description.replace(/\n/g, '<br/>'))}
                              </p>):(
                               <>
                               {
                              banner.subtitle ? (
                                <h5 className="text-light mb-2 notranslate">
                                  <FontAwesomeIcon
                                    icon={banner.event_location_icon?iconArray[0][banner.event_location_icon]:faMapMarkerAlt}
                                    className="fa-fw"
                                  />
                                  {banner.subtitle}
                                </h5>
                              ) : (
                                <p className="mb-4"></p>
                              ) }
                              {
                              banner.event_date ? (
                                <h5 className="text-light mb-4">
                                  <FontAwesomeIcon icon={banner.event_datetime_icon?iconArray[0][banner.event_datetime_icon]:faClock} className="fa-fw" />
                                  {moment(banner.event_date).format(
                                    "DD/MM/YYYY"
                                  )},{" "}
                                  {moment(banner.event_time, [
                                    moment.ISO_8601,
                                    "HH:mm",
                                  ]).format("HH:mm")}
                                </h5>
                              ) : (
                                <p className="mb-4" />
                              )
                              }
                              </>
                            )
                              
                        }
                        

                        <a
                          href={banner.button_url}
                          className="btn btn-light btn-lg notranslate"
                        >
                          <FontAwesomeIcon
                            icon={banner.event_button_icon?iconArray[0][banner.event_button_icon]:faTicketAlt}
                            className="fa-fw"
                          />{" "}
                          {banner.button_title}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </>
  );
}

export default Homeslider;
