import { faCopy, faEdit, faEye, faTicketAlt, faStar, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import moment from 'moment';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import { API_DOMAIN } from '../../../../Config';
import { useTranslation } from 'react-i18next';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import $ from 'jquery';
const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`
};
function EventList(props) {
  const { t, i18n } = useTranslation('common')
  const alert = useAlert();
  const showCustomers=(eventid,event)=>{
    props.getEventCustomers(eventid,event)
  }
  const sendEmailToAll =  async id => {
    if (window.confirm(t('DashboardPage.SendEmailToAllQ'))) {
    try {
      await axios.post(
        `${API_DOMAIN}api/myevents/resend-event-mail`,
        {
          event_id: id
        },
        { headers }
      );
      alert.success(t('MailSentSuccessfully'));
    } catch (error) {
      alert.error(t('SomethingWentWrong'));
    }
  }
  }
  const createDuplicateHandler = async id => {
    if (window.confirm(t('DuplicateThisEvent'))) {
      try {
        await axios.post(
          `${API_DOMAIN}api/myevents/duplicate-event`,
          {
            event_id: id
          },
          { headers }
        );
        alert.success(t('EventDuplicated'));
        props.setReload(state => !state);
      } catch (error) {
        alert.error(t('SomethingWentWrong'));
      }
    }
  };
  setTimeout(()=>{
    if ( ! $.fn.DataTable.isDataTable('#test')) {
      $('#test').DataTable({  
        "scrollX": true
    });
    }
  },5)
  return (
    <div>
 <Table id="test" className='table table-responsive-md table-striped custom-table'>
      <Thead className='thead-light'>
        <Tr>
          <Th style={{ width: 90 }}>
            <span
              className='text-muted ml-2'
              style={{ cursor: 'pointer' }}
              title='View Booking Details'>
              <FontAwesomeIcon icon={faEye} color='#fff' />
            </span>
          </Th>
          <Th>{t('Event Name')}</Th>
          <Th>{t('Category')}</Th>
          <Th>{t('PassCode')}</Th>
          <Th>{t('StartDate')}</Th>
          <Th>{t('EndDate')}</Th>
          <Th><span
            className='text-muted'
            style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faStar} />
          </span></Th>
          <Th>{t('StartTime')}</Th>
          <Th>{t('EndTime')}</Th>

        </Tr>
      </Thead>
      <Tbody>
        {props.data.length !== 0 &&
          props.data.map((event, index) => (
            <Tr key={event.id}>
              <Td>
                {
                  window.location.pathname.includes("my-upcoming-events")
                  &&
                  <>
                               <Link
                  to={`/dashboard/organizer/my-events-upcoming/edit/${event.id}`}
                  className='text-muted'>
                  <FontAwesomeIcon icon={faEdit} title={t('Edit')} />
                </Link>{' '}
                &nbsp;&nbsp;
                <Link
                  to={`/dashboard/organizer/my-events-upcoming/tickets/${event.id}`}
                  className='text-muted'>
                  <FontAwesomeIcon icon={faTicketAlt} title={t('Tickets')} />
                </Link>
                &nbsp;&nbsp;
                <br/>
                  </>
                }
                {
                  !window.location.pathname.includes("my-upcoming-events")
                  &&
                  <>
                               <Link
                  to={`/dashboard/organizer/my-events/edit/${event.id}`}
                  className='text-muted'>
                  <FontAwesomeIcon icon={faEdit} title={t('Edit')} />
                </Link>{' '}
                &nbsp;&nbsp;
                <Link
                  to={`/dashboard/organizer/my-events/tickets/${event.id}`}
                  className='text-muted'>
                  <FontAwesomeIcon icon={faTicketAlt} title={t('Tickets')} />
                </Link>
                &nbsp;&nbsp;
                <br/>
                  </>
                }
   
                <span
                  className='text-muted'
                  style={{ cursor: 'pointer' }}
                  onClick={e => createDuplicateHandler(event.id)}>
                  <FontAwesomeIcon icon={faCopy} title={t('DuplicateEvent')} />
                </span>
                <span
                  className='text-muted'
                  style={{ cursor: 'pointer' , marginLeft:"10px" }}
                  onClick={e => sendEmailToAll(event.id)}>
                  <FontAwesomeIcon icon={faMailBulk} title={t('SendEmailToAll')} />
                </span>
                {/* {event.custom_field_enabled==1 && (<> */}
                  <span
                  className='text-muted'
                  style={{ cursor: 'pointer' , marginLeft:"10px" }}
                  onClick={e => showCustomers(event.id,event)}>
                  <FontAwesomeIcon icon={faEye} title={t('ShowCustomers')} />
                </span>
                {/* </>)} */}
              </Td>
              <Td className="notranslate">
                <Link to={`/dashboard/organizer/my-events/edit/${event.id}`}>
                  {event.title}
                </Link>
              </Td>
              <Td className="notranslate">{event.category_name}</Td>
              <Td>{event.pass_code}</Td>
              <Td className="notranslate">{moment(event.start_date).format('DD-MMM-YYYY')}</Td>
              <Td className="notranslate">{moment(event.end_date).format('DD-MMM-YYYY')}</Td>
              <Td>{event.featured === 1 ? <span
                className='text-muted'
                style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faStar} />
              </span>
                :
                ''
              }</Td>
              <Td className="notranslate">
                {moment(event.start_time, [moment.ISO_8601, 'HH:mm']).format(
                  'HH:mm'
                )}
              </Td>
              <Td className="notranslate">
                {moment(event.end_time, [moment.ISO_8601, 'HH:mm']).format(
                  'HH:mm'
                )}
              </Td>


            </Tr>
          ))}
      </Tbody>
    </Table>
    </div>
   
  );
}

export default EventList;
