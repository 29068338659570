import { USER_SIGN_IN } from './../constants'
const initialState = {
    UserInfo: [],
    IsLoggedIn: false,
    CategoryData: []
}
export default function loggedReducer(state = initialState, action) {
    switch (action.type) {
        case USER_SIGN_IN:
            return {
                ...state,
                UserInfo: action.data,
                IsLoggedIn: action.data.IsLoggedIn
            }
        case 'ADD_CATEGORY':
            return {
                ...state,
                CategoryData: action.data
            }
        default:
            return state;
    }
}

