import axios from 'axios';
import { API_DOMAIN } from '../../Config';

import {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAIL
} from '../constants/countriesConstants';

export const countries = () => async dispatch => {
  try {
    dispatch({
      type: COUNTRIES_REQUEST
    });

    const { data } = await axios.get(`${API_DOMAIN}api/countries`);

    dispatch({
      type: COUNTRIES_SUCCESS,
      payload: data.data
    });
  } catch (error) {
    dispatch({
      type: COUNTRIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};
