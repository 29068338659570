import React from "react";
import {useTranslation} from 'react-i18next';

function MercItemCheckout(props) {
  const {t, i18n} = useTranslation('common')
  return (
    <div className="cart_item">
      <div className="row no-gutters">
        <div className="col-12 col-md-7">
          <div className="d-flex align-items-center">
            <figure className="media cart_item-section">
              <div className="img-wrap my-auto">
                <img src={props.image} className="img-thumbnail img-sm" />
              </div>
            </figure>
            <h6 className="title mb-2">{props.title}</h6>
          </div>
        </div>
        <div className="col-12 col-md-5">
          <div className="cart_item-sec-mobile d-flex justify-content-between shopping-cart-wrap align-items-center">
            <div className="price-wrap">
              <span className="price-new">
                <span className="text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3">
                  {t('Price')}
                </span>
                {props.price.replace('.', ',')}$
              </span>
            </div>
            <div>
              <span className="text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3">
                {t('Quantity')}
              </span>
              {props.quantity}
            </div>
            <div>
              <span className="text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3">
                {t('Size')}
              </span>
              {props.size}
            </div>
            <div className="price-wrap">               
              <span className="text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3">
                {t('Subtotal')}
              </span>
              <var className="price">100$</var>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MercItemCheckout;
