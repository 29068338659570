import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Input from '../../sections/ui/formfields/Input';
import axios from 'axios';
import { API_DOMAIN } from './../../Config';
import Alert from '../../sections/ui/Alert';
import CustomLoader from '../../sections/ui/CustomLoader';
import { useParams } from 'react-router';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router';
import {useTranslation} from "react-i18next";


const ResetPassword = () => {
  const {t, i18n} = useTranslation('common');
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { token } = useParams();
  const alert = useAlert();
  const history = useHistory();

  const resetPassword = async e => {
    e.preventDefault();
    if (!password || password.length <= 5) {
      setError(t('PasswordMinLength'));
      return;
    }
    if (!password || password !== confirmPassword) {
      setError(t('PasswordMustMatch'));
      return;
    }
    setError('');
    try {
      setLoading(true);
      await axios.post(API_DOMAIN + 'api/auth/update-password', {
        token,
        password
      });
      alert.success(t('PasswordResetSuccess'));
      history.replace('/signin');
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError(
          err.response.data ? err.response.data.message : t('PasswordNotUpdated')
        );
      } else {
        setError(t('PasswordNotUpdated'));
      }
    }
    setLoading(false);
  };

  return (
    <section
      className='section-content padding-y bg-white'
      style={{ borderTop: '1px solid #f1f3f7' }}>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='card box'>
              <div className='card-body'>
                <form className='for-form' onSubmit={resetPassword}>
                  <div className='col-12 col-md-6 mx-auto'>
                    <h1 className='text-center'>{t('ResetYourPassword')}</h1>
                    {error && <Alert type='error'>{error}</Alert>}
                    <div className={`form-group input-group mt-4 mb-4`}>
                      <div className='input-group-prepend'>
                        <span className='input-group-text border-0'>
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      </div>
                      <input
                        id='password'
                        className='form-control'
                        name='password'
                        placeholder={t('NewPassword')}
                        type='password'
                        autoComplete='off'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                    <div className={`form-group input-group mt-4 mb-4`}>
                      <div className='input-group-prepend'>
                        <span className='input-group-text border-0'>
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      </div>
                      <input
                        id='password'
                        className='form-control'
                        name='password'
                        placeholder={t('RepeatPassword')}
                        type='password'
                        autoComplete='off'
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    {loading ? (
                      <CustomLoader style={{ textAlign: 'center' }} />
                    ) : (
                      <div className='form-group'>
                        <button
                          type='submit'
                          className='btn btn-primary btn-block'>
                          {t('ResetPassword')}
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
