import { faPlus, faTags, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { Update_COUPON_Group , DELETE_COUPON_API, GET_MASS_COUPONS_API, GET_MASS_COUPONS_CSV_API} from '../../../../APIConfig';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../../sections/ui/Alert';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
// import CustomSelect from '../../../../sections/ui/formfields/CustomSelect'
import MassCouponForm from './MassCouponForm';
import MassCouponItem from './MassCouponItem';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useTranslation } from 'react-i18next';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import $ from 'jquery';
import { CSVLink } from "react-csv";
import { API_DOMAIN } from '../../../../Config';
import { useAlert } from 'react-alert';

const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`
};

const MassCoupons = props => {
  const { t, i18n} = useTranslation('common');
  const [couponCsvData,setCouponCsvData] = useState([])
  const [showCsvData,setShowCsvData] = useState(false)
  const [showCsvLoading,setShowCsvLoading] = useState(false)
  const [coupons, setCoupons] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [edit, setEdit] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [reloadCoupons, setReloadCoupons] = useState(false);
  const [showCouponCodes, setshowCouponCodes] = useState(false);
  const [showCouponCodeslist, setshowCouponCodeslist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileloading, setFileLoading] = useState(false);
  const [couponId, setCouponId] = useState(0);
  const fileInputRef = useRef(null);
  const alert = useAlert();

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (foundUser.user_detail.role_id !== 3) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  useEffect(() => {
    const fetchCoupons = async () => {
      setLoading(true);
      setReloadCoupons(false);
      try {
        const { data } = await axios.post(GET_MASS_COUPONS_API, {},{ headers });
        if (data.success) {
          setCoupons(data.data);
        } else {
          setCoupons([]);
        }
      } catch (error) {
      }
      setLoading(false);
      setTimeout(()=>{
        if ( ! $.fn.DataTable.isDataTable('#table1')) {
        $('#table1').DataTable({
          "scrollX": true
      });}
      },5)
     
    };
    fetchCoupons();
  }, [reloadCoupons, axios]);

  const showAlert = (message, error) => {
    if (error === 'error') {
      setError(true);
      setMessage(message);
    } else if (error === 'success') {
      setReloadCoupons(true);
      setShowAddForm(false);
      setError(false);
      setMessage(message);
    }
  };
  const showEdit = coupon => {
    setEdit(true);
    let couponTypeObj = { label: '', value: 1 };
    if (coupon.coupon_type === 1) {
      couponTypeObj = { label: t('FixedDiscount'), value: 1 };
    } else if (coupon.coupon_type === 2) {
      couponTypeObj = { label: t('%Discount'), value: 2 };
    } 
    let statusObj = { label: t('Active'), value: 1 };
    if (coupon.status === 2) {
      statusObj = { label: t('Inactive'), value: 2 };
    }

    let fixedobject = {
      label: 'Discount Per Ticket',
      value: 0
    }
    if (coupon.coupon_fixed_type === 1) {
      fixedobject ={
        label: 'Fixed Discount',
        value: 1
      }
    }

    let couponapplyobject={
      label: 'Tickets Only',
      value: 0
    }

    if (coupon.coupon_apply_type === 1) {
      couponapplyobject ={
        label: 'Supplements Only',
        value: 1
      }
    }

    else if(coupon.coupon_apply_type === 2)
    {
      couponapplyobject ={
        label: 'Tickets And Supplements',
        value: 2
      }
    }

    const couponData = {
      ...coupon,
      coupon_id: coupon.id,
      coupon_type: couponTypeObj,
      coupon_fixed_type:fixedobject,
      coupon_apply_type:couponapplyobject,
      start_date: new Date(coupon.start_date),
      end_date: new Date(coupon.end_date),
      status: statusObj
    };

    setCoupon(couponData);
    setShowAddForm(true);
  };

  const showcoupons = async (id,couponData) => {
    setshowCouponCodes(true);
    setShowCsvData(false)
    setCoupon(couponData);
    try {
      setTimeout(()=>{
        if ( ! $.fn.DataTable.isDataTable('#table2')) {
        $('#table2').DataTable({
          processing: true,
          serverSide: true,
          "ajax":{
              url: `${API_DOMAIN}api/mass_coupons/paginate_coupons/${id}`,
              type: "GET",
              headers:  
              {
                Authorization: `Bearer ${authData && authData.access_token}`
              },
          },
          'columnDefs': [ {
            'targets': [1,2], 
            'orderable': false,
         }],
          columns: [ 
            { data: 'couponcode' ,className:'pivoted center',
            render : function(data, type, row) {
              return '<div data-testid="td-before" class="tdBefore">'+t('CouponPage.CouponCode')+'</div>'+data
          }  },
            { data: 'totalused' ,className:'pivoted center',
            render : function(data, type, row) {
              return '<div data-testid="td-before" class="tdBefore">'+t('CouponPage.TotalUsed')+'</div>'+data
          }},
            { data: 'totalticketused' ,className:'pivoted center',
            render : function(data, type, row) {
              return '<div data-testid="td-before" class="tdBefore">'+t('CouponPage.TotalTicketUsed')+'</div>'+data
          }}
         ],
         "drawCallback": function (settings) { 
          var response = settings.json;
        },
      "createdRow": function( row, data, dataIndex ) {
      
      },
      "scrollX": true
      });
    }
      },5)
    } catch (err) {
      setLoading(false);
    }
    setShowCsvLoading(true)
    axios.post(GET_MASS_COUPONS_CSV_API, {id:id},{ headers }).then(res=>{
     let data=res.data.data
     const allheaders = [
      { label: "#", key: "no" },
      { label: t('CouponPage.CouponCode'), key: "couponcode" },
      { label: t('CouponPage.TotalUsed'), key: "coupontotalused" },
      { label: t('CouponPage.TotalTicketUsed'), key: "coupontotalticketused" }
    ];
    const alldata = [];
    for(var k=0;k<data.length;k++)
    {
      alldata.push(
        { 
          no:(k+1),
          couponcode: data[k].couponcode,
          coupontotalused: data[k].total_used?data[k].total_used:0,
          coupontotalticketused: data[k].total_ticket_used?data[k].total_ticket_used:0
       }
      )
    }
    setCouponCsvData(
      {
        data: alldata,
        headers: allheaders,
        filename: 'couponslist.csv'
      }
    )
    setShowCsvLoading(false)
    setShowCsvData(true)
    })
  }
 
  const uploadCoupon = async id => {
    setCouponId(id)
    fileInputRef.current.value = '';
    fileInputRef.current.click();
  }

  
  const handleFileSelected = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
       updateCoupon(couponId,reader.result.split("\n"))
      };
      reader.readAsText(file);
    }
  };

  const updateCoupon = async (id,coupon_data) =>
  {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const userData = localStorage.getItem('user');
    const userDataObj = JSON.parse(userData);
    let token = userDataObj !== null && userDataObj.access_token;
    if (!token) {
      return;
    }
    const data = {
      'codeslist':coupon_data,
      'couponid':id
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    setFileLoading(true);
    try {
      await axios.post(Update_COUPON_Group, data, { headers });
      alert.success(t('CouponAddedUpdated'));
      
      setshowCouponCodes(false);
      setshowCouponCodeslist(null);
      setEdit(false);
      setShowAddForm(false);
      setTimeout(()=>{
        if ( ! $.fn.DataTable.isDataTable('#table1')) {
        $('#table1').DataTable({
          "scrollX": true
      });}
      },5)
      showcoupons(id,coupon)
     
    } catch (error) {
      alert.error(t('CouponNotAdded'));
    }
    setFileLoading(false);
  }

 // delete coupon
  const deleteCoupon = async id => {
    if (window.confirm(t('DeleteThisCoupon'))) {
      try {
        await axios.post(DELETE_COUPON_API, { id: id }, { headers });
        showAlert(t('CouponDeleteSuccessfully'), 'success');
      } catch (error) {
        console.error(error.response.message);
        showAlert(
          t('CouponNotDelete'),
          'error'
        );
      }
    }
    // setReloadCoupons(true)
  };
  return (
    <>
      <Breadcrumbs active='coupons' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='masscoupons' />
            <div className='col-lg-9'>
              <Card>
                <header className='section-heading mb-3'>
                  <h4 className='title-section'>
                    <FontAwesomeIcon icon={faTags} className='fa-w-14 mr-2' />
                    {t('DashboardPage.MassCoupons')}
                  </h4>
                </header>
                {message && (
                  <div
                    className={`alert alert-${error ? 'danger' : 'success'}`}>
                    {message}
                  </div>
                )}

                
                <div className='box shadow-none bg-gray mb-4'>
                  <div className='row'>
                    {/* <div className='col-sm-12 col-md-3 mb-3 mb-lg-0'>
                      <span className='center-lg-y text-muted'>
                        {coupons.length} {t('CouponPage.ResultsFound')}
                      </span>
                    </div> */}
 
                    <div className='col-sm-12 col-md-12 d-flex align-items-end justify-content-center'>
                      {!showAddForm && !showCouponCodes && (
                        <button
                          onClick={() => {
                            setShowAddForm(prevState => !prevState);
                          
                          }}
                          className='btn btn-primary ml-3'>
                          <FontAwesomeIcon icon={faPlus} className='mr-2' /> {t('MassCoupon.newgroup')}
                        </button>
                      )}
                      {(showAddForm || showCouponCodes) && (
                        <button
                          onClick={() => {
                            setCoupon(null);
                            setshowCouponCodes(false);
                            setshowCouponCodeslist(null);
                            setEdit(false);
                            setShowAddForm(false);
                            setTimeout(()=>{
                              if ( ! $.fn.DataTable.isDataTable('#table1')) {
                              $('#table1').DataTable({
                                "scrollX": true
                            });}
                            },5)
                          }}
                          className='btn btn-success ml-3'>
                          {t('CouponPage.CancelButton')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {showAddForm && (
                  <div className='box shadow-none bg-gray mb-4'>
                    <div className='row'>
                      <MassCouponForm
                        edit={edit}
                        showAlert={showAlert}
                        coupon={coupon}
                      />
                    </div>
                  </div>
                )}
          
                {loading && <CustomLoader style={{ textAlign: 'center' }} />}
                {fileloading && <CustomLoader style={{ textAlign: 'center' }} />}
                {!loading && coupons.length <= 0 && !showAddForm && (
                  <Alert>{t('NoCouponfound')}</Alert>
                )}
                 <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                   onChange={handleFileSelected}
                 />
                {!loading && coupons.length > 0 && !showAddForm && !showCouponCodes && (
                  <div>
                  <Table id='table1' className='table table-responsive-md table-striped custom-table'>
                    <Thead className='thead-light'>
                      <Tr>
                        <Th>{t('CouponPage.Actions')}</Th>
                        <Th>{t('CouponPage.Status')}</Th>
                        <Th>{t('CouponPage.CouponCodePrefix')}</Th>
                        <Th>{t('CouponPage.Title')}</Th>
                        <Th>{t('CouponPage.Type')}</Th>
                        <Th>{t('CouponPage.Amount')}</Th>
                        <Th>{t('CouponPage.MinimumAmount')}</Th>
                        <Th>{t('CouponPage.MaximumAmount')}</Th>
                        <Th>{t('CouponPage.UsageLimit')}</Th>
                        <Th>{t('CouponPage.TicketLimit')}</Th>
                        <Th>{t('CouponPage.TotalUsed')}</Th>
                        <Th>{t('CouponPage.TotalTicketUsed')}</Th>
                        <Th>{t('CouponPage.StartDate')}</Th>
                        <Th>{t('CouponPage.EndDate')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {coupons.map(coupon => (
                        <MassCouponItem
                          showEdit={showEdit}
                          coupon={coupon}
                          key={coupon.id}
                          deleteCoupon={deleteCoupon}
                          showCoupon={showcoupons}
                          uploadCoupon={uploadCoupon}
                        />
                      ))}
                    </Tbody>
                  </Table>
                  </div>
               
                )}
                {!loading && coupons.length > 0 && !showAddForm && showCouponCodes && (
                  <div>
                     <div style={{display:'flex',justifyContent:'center',marginBottom:20,flexDirection:'column'}}>
                       {showCsvData && <>
                        <CSVLink  {...couponCsvData} className='btn btn-primary'>{t('ExporttoCSV')}</CSVLink></>}
                        <button
                          onClick={() => {
                            uploadCoupon(coupon.id)
                          }}
                          className='btn btn-primary ml-3' style={{marginTop:'10px'}}>
                          <FontAwesomeIcon icon={faDownload} className='mr-2' /> 
                          {t('importcsv')}
                        </button>
                        <div>
                        <p style={{padding:'20px',textAlign:'center',color:'red'}}>{t('uploadcsv')}</p>
                        </div>
                       
                        {showCsvLoading && <CustomLoader style={{ textAlign: 'center' }} />}
                       </div>
                  <Table id='table2' className='table table-responsive-md table-striped custom-table'>
                    <Thead className='thead-light'>
                      <Tr>
                        <Th>{t('CouponPage.CouponCode')}</Th>
                        <Th>{t('CouponPage.TotalUsed')}</Th>
                        <Th>{t('CouponPage.TotalTicketUsed')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                    </Tbody>
                  </Table>
                  </div>
               
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default MassCoupons;
