import Card from '../../../sections/ui/Card'
import countries from '../../../sections/ui/countries'
import CustomSelect from '../../../sections/ui/formfields/CustomSelect'
import LabeledInput from '../../../sections/ui/formfields/LabeledInput'
import { useTranslation } from 'react-i18next'

const BillingInfoForm = ({ hide }) => {
  const {t, i18n} = useTranslation('commom');

  if (hide) {
    return <></>
  }
  return (
    <Card title='Billing Information'>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <LabeledInput
            type='text'
            label={t('BillingInfo.FirstName')}
            id='first_name'
            name='first_name'
            required
          />
        </div>
        <div className='col-12 col-md-6'>
          <LabeledInput
            type='text'
            label={t('BillingInfo.LastName')}
            id='last_name'
            name='last_name'
            required
          />
        </div>
        <div className='col-12 col-md-12'>
          <LabeledInput
            type='email'
            label={t('BillingInfo.Email')}
            id='email_id'
            name='email_id'
            required
          />
        </div>
        <div className='col-12 col-md-6'>
          <CustomSelect
            label={t('BillingInfo.Country')}
            className='custom_select-div'
            options={countries}
            name='country'
          />
        </div>
        <div className='col-12 col-md-6'>
          <LabeledInput
            type='text'
            label={t('BillingInfo.State')}
            id='user_state'
            name='user_state'
            required
          />
        </div>
        <div className='col-12 col-md-6'>
          <LabeledInput
            type='text'
            label={t('BillingInfo.FirstName')}
            id='user-city'
            name='user-city'
            required
          />
        </div>
        <div className='col-12 col-md-6'>
          <LabeledInput
            type='text'
            label={t('BillingInfo.PostalCode')}
            id='postal_code'
            name='postal_code'
            required
          />
        </div>
        <div className='col-12 col-md-12'>
          <LabeledInput
            textArea
            type='text'
            label={t('BillingInfo.Address')}
            id='user-address'
            name='user-address'
            required
          />
        </div>
      </div>
    </Card>
  )
}

export default BillingInfoForm
