import React, { useState, useEffect } from 'react';
import Blog from '../LatestBlogs/Blog';
import axios from 'axios';
import Slider from 'react-slick';
import { BLOGS_API } from '../../../APIConfig';
import CustomLoader from '../../ui/CustomLoader';
import {useTranslation} from "react-i18next";
function Latestblogs(props) {
  const {t, i18n} = useTranslation('common');
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getBlogs = async () => {
    try {
      setLoading(true);
      await axios({
        url: BLOGS_API,
        method: 'GET'
      }).then(function (response) {
        setBlogData(response.data.data);
        setLoading(false);
      });
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <>
    {blogData.length > 0 && (
      <>
       <section className='section-content padding-y-lg bg-white'>
      <div className='container'>
        <header className='section-headin' style={{ marginBottom: 50 }}>
          <h4 className='title-section text-center text-muted text-uppercase'>
            {t('LatestBlogPost')}
          </h4>
        </header>
        {loading && <CustomLoader style={{ textAlign: 'center' }} />}
        <Slider {...props.settings} className='lastest_post-slider'>
          {!loading &&
            blogData.length > 0 &&
            blogData.map((data, index) => (
              <div key={data.id}>
                <Blog
                  image={data.image}
                  id={data.id}
                  author_id={data.author_id}
                  title={data.title}
                  seo_title={data.seo_title}
                  key={data.id}
                  slug={data.slug}
                />
              </div>
            ))}
        </Slider>
        {!loading && blogData.length <= 0 && (
          <div style={{ textAlign: 'center' }}>{t('NoBlogsFound')}</div>
        )}
      </div>
    </section>
      </>
    )}
    </>
   
  );
}
export default Latestblogs;
