// import {
//     faEnvelopeOpenText,
//     faFax,
//     faMapMarker,
//     faPhone,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../sections/ui/Breadcrumbs';
// import Card from "../../sections/ui/Card";
// import LabeledInput from "../../sections/ui/formfields/LabeledInput";
import axios from 'axios';
import parse from 'html-react-parser';
import Moment from 'moment';
import { BLOGS_BY_SLUG_API } from '../../APIConfig';
import CustomLoader from '../../sections/ui/CustomLoader';


export default function BlogArticle(props) {
  const slug = props.match.params.article;
  

  const [blogArticle, setblogArticle] = useState([]);
  const [loading, setLoading] = useState(false);

  const getBlogArticle = async () => {
    try {
      setLoading(true);
      await axios({
        url: BLOGS_BY_SLUG_API + slug,
        method: 'GET'
      }).then(function (response) {
        setblogArticle(response.data.data);
        setLoading(false);
      });
    } catch {}
    setLoading(false);
  };

  useEffect(() => {
    getBlogArticle();
  }, []);
  return (
    <>
      <Breadcrumbs active={props.match.params.article} />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 order-0 order-lg-1'>
              {loading && <CustomLoader style={{ textAlign: 'center' }} />}
              {!loading && (
                <div className='card box'>
                  <div className='card-body'>
                    <header className='section-heading mb-1'>
                      <h1 className='title-section'>{blogArticle.title}</h1>
                    </header>
                    <p className='mb-4'>
                      {/* <small className='text-muted mr-2'>
                        <a href='../blog/marketing.html'>
                          <i className='fas fa-sitemap'></i> Marketing
                        </a>
                      </small> */}
                      <small className='text-muted mr-2'>
                        <i className='far fa-clock'></i>
                        {Moment(`${blogArticle.created_at}`).format(
                          'DD-MM-YYYY'
                        )}
                      </small>
                      {/* <small className="text-muted"><i className="fas fa-book-reader"></i> 6 min read</small> */}
                    </p>
                    <article>{parse(`${blogArticle.body}`)}</article>
                    <hr />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
