import { USER_SIGN_IN } from '../constants';
import axios from 'axios';
import { CATEGORY_API } from '../../APIConfig';

export function ADD_CATEGORY(data){
    return {
        type: 'ADD_CATEGORY',
        data: data
    }
}

export const SIGNIN = (data) => {
    return {
        type: USER_SIGN_IN,
        data: data
    }
};