import axios from 'axios';
import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
// import { Link } from 'react-router-dom';
import { GET_FRONTEND_PAGES } from '../../../APIConfig';

export default function Intro_News() {
  const [Newsstate, setNewsstate] = useState('');
  const [loading, setLoading] = useState(false);
  const get_news = async () => {
    try {
      setLoading(true);
      axios.get(GET_FRONTEND_PAGES).then(response => {
        let obj = response.data.data.find(o => o.slug === 'home-page');
        setNewsstate(obj);
        setLoading(false);
      });
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    get_news();
  }, []);

  return (
    <section
      className='section-content padding-y-lg bg-white'
      style={{ minHeight: 0, paddingBottom: 1 }}>
      <div className='container'>
        {Newsstate.body !== undefined && (
          <div>{parse(`${Newsstate.body}`)}</div>
        )}
      </div>
    </section>
  );
}
