import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Dropdown from '../../sections/ui/Dropdown';
import { Link } from 'react-router-dom';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';
import parse from 'html-react-parser';
import { SETTINGS_API, EVENT_CATEGORIES_API } from '../../APIConfig';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { countries } from '../../service/actions/countriesActions';
import {useTranslation} from "react-i18next";

const user = JSON.parse(localStorage.getItem('user'));
const userRole = user && user.user_detail && user.user_detail.role_id;

function LanguageComponent()
{
    const [t, i18n] = useTranslation('common');
    return <div>
        <h1>{t('Language', {framework:'React'})}</h1>
        <button onClick={() => i18n.changeLanguage('de')}>nl</button>
        <button onClick={() => i18n.changeLanguage('en')}>en</button>
        <button onClick={() => i18n.changeLanguage('fr')}>fr</button>
    </div>
}

function Footer(props) {
  const {t, i18n} = useTranslation('common');
  const [SettingData, setSettingData] = useState([]);
  const [CategoryData, setcategoryData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => getcategory(), []);

  useEffect(() => dispatch(countries()), [dispatch]);

  const getcategory = async () => {
    try {
      await axios({
        url: EVENT_CATEGORIES_API,
        method: 'GET'
      }).then(function (res) {
        setcategoryData(res.data.data);
        // useDispatch(props.BindCategory)
        props.BindCategory.FillCategory.ADD_CATEGORY(res.data.data);
      });
    } catch {}
  };
  useEffect(() => getSettingData(), []);

  const getSettingData = async () => {
    try {
      await axios({
        url: SETTINGS_API,
        method: 'GET'
      }).then(function (res) {
        setSettingData(res.data.data);
      });
    } catch {}
  };
  let facebook = SettingData.find(o => o.key === 'social.facebook');
  let twitter = SettingData.find(o => o.key === 'social.twitter');
  let instagram = SettingData.find(o => o.key === 'social.instagram');
  let linkedin = SettingData.find(o => o.key === 'social.linkedin');
  // let address = SettingData.find(o => o.key === 'contact.address');

  let phone = SettingData.find(o => o.key === 'contact.phone');
  let email = SettingData.find(o => o.key === 'contact.email');
  let site_footer = SettingData.find(o => o.key === 'site.site_footer');

  return (
    <footer className='section-footer border-top-gray'>
      <div className='container'>
        <section className='footer-top padding-top'>
          <div className='row'>
            <aside className='col-sm-6 col-lg-3'>
              <h5 className='title text-dark'>{t('Footer.UserfulLinks')}</h5>
              <ul className='list-unstyled'>
                <li className='mb-1'>
                  <Link to='/aboutus'>{t('Footer.AboutUs')}</Link>
                </li>
                <li className='mb-1'>
                  <Link to='/howitworks'>{t('Header.HowItWorks')}</Link>
                </li>
                <li className='mb-1'>
                  <Link to='/blog'>{t('Header.Blogs')}</Link>
                </li>
                {/* <li className="mb-1">
                  <a href="/venues">Venues</a>
                </li> */}
                <li className='mb-1'>
                  <Link to='/contact'>{t('Footer.ContactUs')}</Link>
                </li>
              </ul>
            </aside>
            <aside className='col-sm-6 col-lg-3'>
              <h5 className='title text-dark'>{t('Footer.MyAccount')}</h5>
              {/* {
                 userRole
                   ? userRole === 2
                     ? '/signup/organizer'
                     : '/dashboard/organizer/my-events/add'
                   : '/signup/organizer'
              } */}
              <ul className='list-unstyled'>
                {!userRole && (
                  <li className='mb-1'>
                    <Link to='/signup/attendee'>{t('Footer.CreateAnAccount')}</Link>
                  </li>
                )}
                {userRole === 3 ? (
                  <li className='mb-1'>
                    <Link to='/dashboard/organizer/my-events/add'>
                    {t('Footer.AddEvent')}
                    </Link>
                  </li>
                ) : (
                  <li className='mb-1'>
                    <Link to='/signup/organizer'>{t('Footer.SellTicketsOnline')}</Link>
                  </li>
                )}
                {userRole && (
                  <li className='mb-1'>
                    <Link to='/dashboard/attendee/my-tickets'>{t('Footer.MyTickets')}</Link>
                  </li>
                )}
                {!userRole && (
                  <li className='mb-1'>
                    <Link to='/ForgotPassword'>{t('Footer.ForgotPassword')}</Link>
                  </li>
                )}
                {userRole && (
                  <li className='mb-1'>
                    <Link to='/dashboard/attendee/change-password'>
                    {t('Footer.UpdatePassword')}
                    </Link>
                  </li>
                )}
                {/* <li className="mb-1">
                  <a href="en/page/pricing-and-fees.html">Pricing and fees</a>
                </li> */}
              </ul>
            </aside>
            <aside className='col-sm-6 col-lg-3'>
              <h5 className='title text-dark'>{t('Footer.EventCategories')}</h5>
              <ul className='list-unstyled'>
                {CategoryData.map((cat, index) => (
                  <li className='mb-1' key={cat.id}>
                    <Link to={`/event-list/${cat.slug}`}>{cat.name}</Link>
                  </li>
                ))}
              </ul>
            </aside>
            <aside className='col-sm-6 col-lg-3'>
              <article>
                <h5 className='title text-dark'>{t('Footer.ContactUs')}</h5>
                <p>
                  <strong>{t('Footer.Phone')}: </strong>{' '}
                  {phone === undefined ? '' : phone.value} <br />
                  <strong>{t('Footer.Email')}:</strong>{' '}
                  {email == undefined ? '' : email.value}
                </p>

                <div className='btn-group white'>
                  {facebook && (
                    <a
                      className='btn btn-facebook'
                      title='Facebook'
                      target='_blank'
                      href={facebook.value}>
                      <FontAwesomeIcon icon={faFacebookF} className='fa-fw' />
                    </a>
                  )}
                  {instagram && (
                    <a
                      className='btn btn-instagram'
                      title='Instagram'
                      target='_blank'
                      href={instagram.value}
                      style={{ background: '#E1306C' }}>
                      <FontAwesomeIcon icon={faInstagram} className='fa-fw' />
                    </a>
                  )}

                  {/* <a
                    className='btn btn-youtube'
                    title='Youtube'
                    target='_blank'
                    href='https://www.youtube.com/'
                    style={{background: '#FF0000'}}
                  >
                    <FontAwesomeIcon icon={faYoutube} className='fa-fw' />
                  </a> */}
                  {linkedin && (
                    <a
                      className='btn btn-linkedin'
                      title='LinkedIn'
                      target='_blank'
                      href={linkedin.value}
                      style={{ background: '#0077b5', color: '#FFF' }}>
                      <FontAwesomeIcon icon={faLinkedinIn} className='fa-fw' />
                    </a>
                  )}

                  {twitter && (
                    <a
                      className='btn btn-twitter'
                      title='Twitter'
                      target='_blank'
                      href={twitter.value}>
                      <FontAwesomeIcon icon={faTwitter} className='fa-fw' />
                    </a>
                  )}
                </div>
                <div className='clearfix'></div>
                <h5 className='title mt-4 display-inline-block'>
                  {/* <i className='fas fa-globe'></i> Language */}
                </h5>
                <div
                  className='dropdown display-inline-block'
                  style={{ marginTop: 25, width: '100%' }}>
                  {/* <div id='google_translate_element'></div> */}
                  <LanguageComponent />
                </div>
              </article>
            </aside>
          </div>
          <br />
        </section>
        <section className='footer-bottom row'>
          <div className='col-sm-12'>
            <p className='text-center text-dark'>
              <Link to='/TermsofServices' className='text-dark'>
              {t('Footer.TermsOfServices')}
              </Link>
              <span className='text-gray'>|</span>
              <Link to='/PrivacyPolicy' className='text-dark'>
              {t('Footer.PrivacyPolicy')}
              </Link>
              <span className='text-gray'>|</span>
              <Link to='/CookiePolicy' className='text-dark'>
              {t('Footer.Cookiepolicy')}
              </Link>
              <span className='text-gray'>|</span>
              <Link to='/GDPRCompliance' className='text-dark'>
                {' '}
                {t('Footer.GDPRCompliance')}
              </Link>
            </p>
          </div>
          <div className='col-sm-12'>
            <p className='text-dark-50 text-center'>
              {site_footer === undefined ? '' : parse(`${site_footer.value}`)}
            </p>
          </div>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
