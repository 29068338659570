import { faEdit, faTrash , faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'

const CouponItem = ({ coupon, showEdit, deleteCoupon }) => {
    const { t, i18n} = useTranslation('common');
    const {
        coupon_code,
        description,
        coupon_type,
        amount,
        total_used,
        total_ticket_used,
        minimum_amount,
        maximum_amount,
        limit_usage,
        start_date,
        end_date,
        status,
        coupon_ticket_limit,
        coupon_fixed_type
    } = coupon
    return (
        <Tr>
            <Td>{coupon_code}</Td>
            <Td className="notranslate">{description}</Td>
            <Td className="notranslate">
                {coupon_type === 1 && (t('FixedTicket')+ (coupon_fixed_type==0?' (Discount Per Ticket)':' (Fixed Discount)'))}
                {coupon_type === 2 && t('%Ticket')}
                {coupon_type === 3 && t('FixedCart')}
                {coupon_type === 4 && t('%Cart')}
            </Td>
            <Td>{coupon_type === 2 ? amount+"%":"€"+amount}</Td>
            <Td>{total_used}</Td>
            <Td>{total_ticket_used}</Td>
            {/* <Td>€{minimum_amount}</Td>
            <Td>€{maximum_amount}</Td>
            <Td>{limit_usage}</Td>
            <Td>{coupon_ticket_limit=="0"?t('CouponPage.Unlimited'):coupon_ticket_limit}</Td> */}
            <Td className="notranslate">{start_date}</Td>
            {/* <Td className="notranslate">{end_date}</Td> */}
            <Td>{status === 0 ? <FontAwesomeIcon icon={faTimes} /> :  <FontAwesomeIcon icon={faCheck}/>}</Td>
            <Td>
                <span
                    className='text-muted'
                    style={{ cursor: 'pointer' }}
                    onClick={() => showEdit(coupon)}
                    title={t('CouponPage.EditCoupon')}
                >
                    <FontAwesomeIcon icon={faEdit} />
                </span>
                <span
                    className='text-muted ml-2'
                    style={{ cursor: 'pointer' }}
                    onClick={() => deleteCoupon(coupon.id)}
                    title={t('CouponPage.DeleteCoupon')}
                >
                    <FontAwesomeIcon icon={faTrash} color='red'/>
                </span>
            </Td>
        </Tr>
    )
}

export default CouponItem
