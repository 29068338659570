import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TOP_EVENTS_API } from '../../../../APIConfig';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import Alert from '../../../../sections/ui/Alert';
import TopEventItem from './TopEventItem';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useTranslation } from 'react-i18next';
import { useTable } from 'react-table'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import $ from 'jquery';
const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`
};

const TopEvents = props => {
  const {t, i18n} = useTranslation('common');
  const [topEvents, setTopEvents] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (foundUser.user_detail.role_id !== 3) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  useEffect(() => {
    const fetchTopEvents = async () => {
      setLoading(true);
      try {
        const { data } = await axios.post(TOP_EVENTS_API, {}, { headers });
        if (data.success) {
          setTopEvents(data.data);
          setError('');
          setTimeout(()=>{
            if ( ! $.fn.DataTable.isDataTable('#table1')) {
            $('#table1').DataTable({
              
              "scrollX": true
          });
        }
          },5)
        } else {
          setError(t('TopEventsPage.TopEventsnotfound'));
          setTopEvents([]);
        }
      } catch (error) {
        setError(t('TopEventsPage.TopEventsnotfound'));
      }
      setLoading(false);
    };
    fetchTopEvents();
  }, [axios]);

  return (
    <>
      <Breadcrumbs active='top-events' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='top-events' />
            <div className='col-lg-9'>
              <Card>
                <header className='section-heading mb-3'>
                  <h4 className='title-section'>
                    <FontAwesomeIcon icon={faTags} className='fa-w-14 mr-2' />
                    {t('TopEventsPage.TopEvents')}
                  </h4>
                </header>
                <div className='box shadow-none bg-gray mb-4'>
                  <div className='row'>
                    <div className='col-sm-12 col-md-3 mb-3 mb-lg-0'>
                      <span className='center-lg-y text-muted'>
                        {topEvents.length} {t('TopEventsPage.Resultfound')}
                      </span>
                    </div>
                  </div>
                </div>
                {loading && <CustomLoader style={{textAlign: 'center'}} />}
                {!loading && error && <Alert>{error}</Alert>}
                {!loading && topEvents.length > 0 && (
                  <Table id="table1" className='table table-responsive-md table-striped custom-table'>
                    <Thead className='thead-light'>
                      <Tr>
                        <Th>{t('TopEventsPage.EventName')}</Th>
                        <Th>{t('TopEventsPage.Category')}</Th>
                        <Th>{t('TopEventsPage.StartDate')}</Th>
                        <Th>{t('TopEventsPage.EndDate')}</Th>
                        <Th>{t('TopEventsPage.StartTime')}</Th>
                        <Th>{t('TopEventsPage.EndTime')}</Th>
                        <Th>{t('TopEventsPage.Edit')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {topEvents.map(event => (
                        <TopEventItem key={event.id} event={event} />
                      ))}
                    </Tbody>
                  </Table>
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default TopEvents;
