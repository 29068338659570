import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
function EventsDetailsSlider(props) {


  
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <FontAwesomeIcon
      {...props}
      icon={faAngleLeft}
      size="xs"
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    />
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <FontAwesomeIcon
      {...props}
      icon={faAngleRight}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      size="xs"
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    />
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
  };
  return (

    <>
      <Slider {...settings} className="slider-main homepage-slider">

        {         
          props.images != null ?
            props.images.map((img,key)=>(
              <div key={key}>
              <div className="item-slide">
                <div className="item-slide d-flex">
                  <img
                    className="slider-img justify-content-center align-self-center img-lazy-load b-loaded"
                    src= {img}
                    alt="DevOps Training and Certification Course"
                  />
                  <div
                    className="slider-blured-background"
                    style={{
                      backgroundImage:
                        `url(${img}})`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            ))
            : <div></div>

        }



      </Slider>
    </>
  );
}

export default EventsDetailsSlider;
