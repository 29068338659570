import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import StarRating from '../StarRating';
import Popup from '../../../sections/ui/Popup/Popup';
// import { useForm, Controller } from 'react-hook-form';
// import Input from '../../ui/formfields/Input';
import LabeledInput from '../../../sections/ui/formfields/LabeledInput';
import ReactRating from 'react-rating-stars-component';
import axios from 'axios';
import { API_DOMAIN } from '../../../Config';
import { useAlert } from 'react-alert';
import CustomLoader from '../CustomLoader';
import Loader from 'react-loader-spinner';
import { useEffect } from 'react';

function ReviewScoreBoard(props) {
  // const initialFormData = {
  //   review: 1,
  //   description: ''
  // };

  // const [errorMessage, setErrorMessage] = useState('');
  // const [successMessage, setSuccessMessage] = useState('');
  // const [formData, setFormData] = useState(initialFormData);
  // const { register, handleSubmit, errors, control } = useForm();

  const [reviewPopup, setReviewPopup] = useState(false);
  const LoggenInUser = localStorage.getItem('user');
  const [review, setReview] = useState(
    props.currentUserReview ? props.currentUserReview.review : 1
  );
  const [description, setDescription] = useState(
    props.currentUserReview && props.currentUserReview.description
      ? props.currentUserReview.description
      : ''
  );
  const [loading, setLoading] = useState(false);

  const alert = useAlert();

  // const showAlert = (message, error) => {
  //   if (error === 'error') {
  //     setErrorMessage(message);
  //   } else if (error === 'success') {
  //     setSuccessMessage(message);
  //   }
  // };

  // const { review, description } = formData;

  // const onChange = e => {
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [e.target.name]: e.target.value
  //   }));
  // };

  let star5, star4, star3, star2, star1, total, avg;
  if (props.stars) {
    total = props.stars.reduce((a, b) => a + b, 0);
    star5 = ((props.stars[0] / total) * 100).toFixed(0) + '%';
    star4 = ((props.stars[1] / total) * 100).toFixed(0) + '%';
    star3 = ((props.stars[2] / total) * 100).toFixed(0) + '%';
    star2 = ((props.stars[3] / total) * 100).toFixed(0) + '%';
    star1 = ((props.stars[4] / total) * 100).toFixed(0) + '%';
    avg = Math.round(total / 5);
  }

  const reviewPupupClick = () => {
    if (LoggenInUser) {
      setReviewPopup(!reviewPopup);
    } else {
      window.location.href = '/signin';
    }
  };

  const onFromSubmit = e => {
    e.preventDefault();
  };

  const onSubmit = async e => {
    const authData = JSON.parse(LoggenInUser);
    const headers = {
      Authorization: `Bearer ${authData && authData.access_token}`,
      accept: 'application/json'
    };
    const reviewData = {
      event_id: props.event_id,
      review,
      description
    };
    try {
      setLoading(true);
      const { data } = await axios.post(
        API_DOMAIN + 'api/review/add',
        reviewData,
        { headers }
      );
      alert.success('Your review has been submitted.');
    } catch (error) {
      alert.error('something went wrong, please try again.');
    }
    setLoading(false);
    reviewPupupClick();
    props.setReviewAdded(state => !state);
  };

  const reviewSubmitButton = loading ? (
    <Loader type='ThreeDots' color='#fff' height={20} width={50} />
  ) : (
    <div onClick={onSubmit}>Submit</div>
  );
  return (
    <>
      <div className='row'>
        <div className='col-12 col-sm-6 mb-5'>
          <h3 className='mb-1'>{props.totalReviews} review(s)</h3>
          <div className='rating-wrap'>
            {/* <StarRating rating={avg} /> */}
            <StarRating rating={props.average} />
            <div className='label-rating ml-1'>
              {props.totalReviews > 0 ? props.average : 0} out of 5 stars
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 text-sm-right'>
          <button
            type='button'
            className='btn btn-primary btn-block'
            onClick={reviewPupupClick}>
            <FontAwesomeIcon icon={faStar} /> Add your review
          </button>
          <Popup
            className='modal-lg modal-dialog-scrollable'
            openModal={reviewPopup}
            closeModal={reviewPupupClick}
            title={
              <>
                <FontAwesomeIcon
                  icon={faStar}
                  style={{ color: '#31c5f4' }}
                  className='mr-1'
                />
                Add Review
              </>
            }
            footer={reviewSubmitButton}
            // footerAction={() => {
            //   reviewPupupClick();
            // }}
          >
            <form
              id='myForm'
              name='myForm'
              onSubmit={onFromSubmit}
              className='col-xl-12 col-12'>
              <div style={{ justifyContent: 'center' }}>
                <ReactRating
                  size={30}
                  onChange={e => setReview(e)}
                  name='review'
                  value={review}
                  emptyIcon={<FontAwesomeIcon icon={faStar} />}
                  filledIcon={<FontAwesomeIcon icon={faStar} />}
                  activeColor={'#31c5f4'}
                />
              </div>
              <LabeledInput
                required
                id='description'
                name='description'
                value={description}
                onChange={e => setDescription(e.target.value)}
                info=''
                placeholder='add a comment'
              />
            </form>
          </Popup>
        </div>
      </div>
      {props.totalReviews !== 0 && (
        <div className='row mb-5 event-scorecard'>
          <div className='col-12'>
            <div className='side'>
              <div>5 stars</div>
            </div>
            <div className='middle'>
              <div className='bar-container'>
                <div className='bar' style={{ width: star5 }}></div>
              </div>
            </div>
            <div className='side right'>
              <div className='text-muted'>{star5}</div>
            </div>
            <div className='side'>
              <div>4 stars</div>
            </div>
            <div className='middle'>
              <div className='bar-container'>
                <div className='bar' style={{ width: star4 }}></div>
              </div>
            </div>
            <div className='side right'>
              <div className='text-muted'>{star4}</div>
            </div>
            <div className='side'>
              <div>3 stars</div>
            </div>
            <div className='middle'>
              <div className='bar-container'>
                <div className='bar' style={{ width: star3 }}></div>
              </div>
            </div>
            <div className='side right'>
              <div className='text-muted'>{star3}</div>
            </div>
            <div className='side'>
              <div>2 stars</div>
            </div>
            <div className='middle'>
              <div className='bar-container'>
                <div className='bar' style={{ width: star2 }}></div>
              </div>
            </div>
            <div className='side right'>
              <div className='text-muted'>{star2}</div>
            </div>
            <div className='side'>
              <div>1 star</div>
            </div>
            <div className='middle'>
              <div className='bar-container'>
                <div className='bar' style={{ width: star1 }}></div>
              </div>
            </div>
            <div className='side right'>
              <div className='text-muted'>{star1}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ReviewScoreBoard;
