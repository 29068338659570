import React from "react";
import { BACKEND_URL } from '../../../APIConfig';
function Featuredcategories(props) {
  return (
    <>
      <a href={`/event-list/${props.slug}`} className="zoom-wrap">
        <div className="card-banner mb-3">
          <div
            className="card-body zoom-in"
            style={{
              backgroundImage:
                `url('${BACKEND_URL + props.thumb}')`,
              height: "250px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          ></div>
          <div className="text-bottom">
            <h4 className="title">{props.name}</h4>
          </div>
        </div>
      </a>
    </>
  );
}
export default Featuredcategories;
