import React from 'react'
import FeaturedCateg from '../../sections/home/FeaturedCategories/FeaturedCategories'
import {useTranslation} from "react-i18next";

function HomeCategories(props) {
  const {t, i18n} = useTranslation('common');

  return (
    <section className='section-content padding-y-lg'>
      <div className='container'>
        <header className='section-headin' style={{marginBottom: 50}}>
          <h4 className='title-section text-center text-muted text-uppercase'>
            {t('FeaturedCategory')}
          </h4>
        </header>
        <div className='row-sm'>
          {props.categoryData != null &&
            props.categoryData.slice(0, 4).map((category, index) => (
              <div className='col-sm-4 col-md-4 col-lg-3' key={category.id}>
                <FeaturedCateg
                  name={category.name}
                  thumb={category.thumb}
                  slug={category.slug}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default HomeCategories
