import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_ADD_ITEM_TO_STORAGE,
  CART_RESET_ITEM,
  CART_GET_DATA_FROM_SERVER
} from '../constants/cartConstants'

export const cartReducer = (
  state = { cartItems: [], totalPrice: '0.00' },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload
      const existItem = state.cartItems.find(
        cartItem =>
          cartItem.event_id === item.event_id &&
          cartItem.ticket_id === item.ticket_id
      )

      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map(x =>
            x.event_id === existItem.event_id &&
            x.ticket_id === existItem.ticket_id
              ? item
              : x
          )
        }
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item]
        }
      }
    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          x =>
            x.event_id !== action.payload.event_id ||
            x.ticket_id !== action.payload.ticket_id
        )
      }

    case CART_RESET_ITEM:
      return { cartItems: [] }
    case CART_ADD_ITEM_TO_STORAGE:
      const updatedCartItems = state.cartItems.map(item => ({
        ...item,
        couponId: ''
      }))
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems))
      return state
    case CART_GET_DATA_FROM_SERVER:
      return {
        ...state,
        cartItems: action.payload.data.tickets,
        cartTotal: action.payload.data.ticket_total
      }
    default:
      return state
  }
}
