import React from "react";
function Input(props) {
  return (
    <div className={`form-group input-group ${props.className}`}>
      <div className="input-group-prepend">
        <span className="input-group-text border-0">{props.icon}</span>
      </div>
      <input
        id={props.id}
        className={`form-control`}
        name={props.name}
        placeholder={props.placeholder}
        type={props.type}
        {...props.register(props.name, { required: true, maxLength: 200 })}
        autoComplete="off"
        {
          ...props
        }
      />
      
    </div>
  );
}

export default Input;
