import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSitemap, faBookReader } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Blog = props => {
  return (
    <div className='card mb-4 mx-2 shadow-none blog-post-card'>
      <Link to={`/blog-article/${props.slug}`}>
        <img
          className='card-img-top'
          src={props.image}
          alt='5 Ways Event Managers Can Increase Event Registration'
        />
      </Link>
      <div className='card-body'>
        {/* <p className='card-text'>
          <small className='text-muted mr-3'>
            <Link to={`/blog-article/${props.slug}`} className='text-dark'>
              <FontAwesomeIcon icon={faSitemap} /> Marketing
            </Link>
          </small>
          <small className='text-muted'>
            <FontAwesomeIcon icon={faBookReader} /> 6 min read
          </small>
        </p> */}
        <h5 className='card-title'>
          <Link to={`/blog-article/${props.slug}`} className='text-dark'>
            {props.title}
          </Link>
        </h5>
      </div>
    </div>
  );
};

export default Blog;
