import React from "react";
// import { connect } from 'react-redux';
// import { SIGNIN } from '../../service/actions/Index'
import HomeSlider from "../../sections/home/HomeSlider/HomeSlider";
import UpCommingEvents from "../../sections/home/UpCommingEvents";
import FeaturedEvents from "../../sections/home/FeaturedEvents";
import FeaturedCateg from '../../sections/home/HomeCategories';
import Latestblogs from "../../sections/home/LatestBlogs/LatestBlogs";
import Prefooter from "../../sections/footer/PreFooter";
import Intro_News from '../../sections/home/Intro_News/Intro_News'



function Homepage(props) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  return (
    <>
      <HomeSlider />
      <Intro_News/>
      <UpCommingEvents/>
      <FeaturedCateg categoryData={props.store.StoreInfo.CategoryData} />
      <FeaturedEvents/>
      <Latestblogs settings={settings}/>
      <Prefooter />
    </>
  );
}


export default Homepage;
  