import React,{useState,useEffect} from 'react'
import TicketCounter1 from './TicketCounter1'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function TicketCategory({ ticket, event, ...props }) {
  const { t, i18n } = useTranslation('common');
  const [isTicketExpired, setisTicketExpired] = useState(false);
  const [isTicketStart, setisTicketStart] = useState(false);
  useEffect(() => {
    if(ticket.expire_date&&ticket.expire_time){
      let  expiry_date = moment(ticket.expire_date+" "+ticket.expire_time);
      let server_date = moment(event.server_date);
      if(server_date.isSameOrAfter(expiry_date))
         setisTicketExpired(true)
      else
        setisTicketExpired(false)
    }
    else
    {
      setisTicketExpired(false)
    }
  }, [isTicketExpired]);
  useEffect(() => {
     if(ticket.start_date&&ticket.start_time){
       let  starty_date = moment(ticket.start_date+" "+ticket.start_time);
       let server_date = moment(event.server_date);
       if(server_date.isBefore(starty_date))
       setisTicketStart(true)
       else
       setisTicketStart(false)
     }
     else
     {
      setisTicketStart(false)
     }
   }, [isTicketStart]);
  return (
    <>
    {(!props.isFrame || (props.isFrame && ticket.iframe_allow==1) ) && !isTicketStart &&
    <>
      <table className='table table-hover table-condensed mb-1'>
        <tbody>
          <tr
            className='bg-gray'
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <td className='border-top-white notranslate'>
              <div>
                {ticket.title}
                {(ticket.description && ticket.description!='null') ? (
                  <>
                    <span data-tip data-for={'category_info'+ticket.id}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className='ml-2 notranslate'
                        style={props.isFrame?{color:props.urlColor}:{color:"#31c5f4"}}
                      />
                    </span>
                    <ReactTooltip
                      id={'category_info'+ticket.id}
                      effect='solid'
                      className='tooltip-inner'
                      backgroundColor={props.isFrame?props.urlColor:"#31c5f4"}
                      borderColor={props.isFrame?props.urlColor:"#31c5f4"}
                      arrowColor={props.isFrame?props.urlColor:"#31c5f4"}
                    >
                      <span className="notranslate">{parse(`${ticket.description}`)}</span>
                    </ReactTooltip>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className='clearfix'></div>
              <div className='b mt-1 notranslate'>
                €{ticket.price.replace('.', ',')}
                {/* {ticket.salePrice}$
                {ticket.price ? (
                  <del className='price-old ml-1'>{ticket.price}$</del>
                ) : (
                  ''
                )} */}
              </div>
              {ticket.saleStartDate ? (
                <>
                  <div className='clearfix'></div>
                  <small className='text-success font-size-10px mt-1'>
                    Sales started on {ticket.saleStartDate}
                  </small>
                </>
              ) : (
                ''
              )}
     
        {/* {ticket.start_date ? (
                <>
                  <div className='clearfix'></div>
                  <small className='text-success font-size-14px mt-1'>
                    Sales started on {moment(
                                   ticket.start_date+
                                    ' ' +
                                    ticket.start_time
                                  ).format(
                                    'dddd DD MMM YYYY HH:mm'
                                  )} 
                  </small>
                </>
              ) : (
                ''
              )}

              {ticket.expire_date ? (
                <>
                <div className='clearfix'></div>
                <small className='text-danger font-size-14px mt-1'>
                  Sales end on {moment(
                                 ticket.expire_date+
                                  ' ' +
                                  ticket.expire_time
                                ).format(
                                  'dddd DD MMM YYYY HH:mm'
                                )} 
                </small>
              </>
              ) : (
                ''
              )} */}
            </td>
            <td className='border-top-white text-right' >
              {
                ticket.sold_out==0 && !isTicketExpired && !isTicketStart && (ticket.quantity - Number(ticket.booked_qty)) > 0 ?<TicketCounter1 max={ticket.ticket_limit} value={0} event={event} ticket={ticket} show={props.show_ticket_limit} show1={props.show_limit_purchase} {...props}/>:''
              }
              
              {/* {ticket.quantity ? (
                <span className='badge badge-info mt-2'>
                  {ticket.quantity} tickets left
                </span>
              ) : (
                ''
              )} */}
              {props.show_limit_purchase === 1 && ticket.sold_out==0 && (ticket.quantity - Number(ticket.booked_qty)) > 0 ? 
              <span className='badge badge-info ml-3 mt-2'>
                {ticket.ticket_limit} ticket(s) per attendee
              </span>
              : '' }

              { ((isTicketExpired || ticket.sold_out == 1 || ((ticket.quantity - Number(ticket.booked_qty)) <= 0))&&!isTicketStart)? 
              <span className='soldticket  ml-3 mt-2'>
               {t('MyEventPage.SoldOut')}
              </span>
              : '' }
              {/* {isTicketStart && !isTicketExpired && ticket.sold_out != 1 && ((ticket.quantity - Number(ticket.booked_qty)) != 0)? 
              <span className='soldticket  ml-3 mt-2'>
              {t('MyEventPage.SoldOut')}
              </span>
              : '' } */}
            </td>
          </tr>
        </tbody>
      </table>
    </>
    }
    </>
  )
}
export default TicketCategory
