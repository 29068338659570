import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Tr, Td } from 'react-super-responsive-table'

const TopEventItem = ({ event }) => {
  return (
    <Tr>
      <Td>
        <a className="notranslate" href={`/event-details/${event.slug}/${event.id}`} target='_blank'>
          {event.title}
        </a>
      </Td>
      <Td> {event.category_name} </Td>
      <Td> {moment(event.start_date).format('DD-MMM-YYYY')}</Td>
      <Td> {moment(event.end_date).format('DD-MMM-YYYY')}</Td>
      <Td> {event.start_time} </Td>
      <Td> {event.end_time} </Td>

      <Td>
        <Link
          to={`/dashboard/organizer/my-events/edit/${event.id}`}
          className='text-muted'>
          <FontAwesomeIcon icon={faEdit} title='edit' />
        </Link>
      </Td>
    </Tr>
  );
};

export default TopEventItem;
