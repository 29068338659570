import { faEdit, faTrash , faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import moment from 'moment';

const EventTemplateItem = ({ template, showEdit, deleteTemplate }) => {
    const { t, i18n} = useTranslation('common');
    const {
        title,
        created_at
    } = template
    return (
        <Tr>
            <Td>{title}</Td>
            <Td className="notranslate">{moment(
          created_at
        ).format('DD/MM/YYYY HH:mm')}</Td>
            <Td>
                <span
                    className='text-muted'
                    style={{ cursor: 'pointer' }}
                    onClick={() => showEdit(template)}
                    title={t('editemplate')}
                >
                    <FontAwesomeIcon icon={faEdit} />
                </span>
                <span
                    className='text-muted ml-2'
                    style={{ cursor: 'pointer' }}
                    onClick={() => deleteTemplate(template.id)}
                    title={t('deletetemplate')}
                >
                    <FontAwesomeIcon icon={faTrash} color='red'/>
                </span>
            </Td>
        </Tr>
    )
}

export default EventTemplateItem
