import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function CustomFileInput(props) {
  const onChangeHandler = () => {};
  return (
    <>
      <fieldset className='form-group'>
        <legend
          className={`col-form-label ${props.required ? 'required' : ''}`}>
          {props.label}
        </legend>
        {props.info ? (
          <small className='form-text text-muted mb-3'>
            <FontAwesomeIcon
              icon={faInfoCircle}
              className='text-primary mr-1'
            />
            {props.info}
          </small>
        ) : (
          ''
        )}
        <div className='vich-image'>
          <div className='custom-file'>
            {
              props.value ?
              <>
               <input
              type='file'
              id={props.id}
              name={props.name}
              className={`custom-file-input ${props.className}`}
              value={props.value}
              onChange={onChangeHandler}
              onChange={e => props.handleFile(e)}
              multiple={props.multiple? true: false}
              disabled={props.disabled? true:false}
            />
              </>:
              <>
                     <input
              type='file'
              id={props.id}
              name={props.name}
              className={`custom-file-input ${props.className}`}
              
              onChange={onChangeHandler}
              onChange={e => props.handleFile(e)}
              multiple={props.multiple? true: false}
              disabled={props.disabled? true:false}
            />
              </>
            }
           
            <label htmlFor={props.id} className='custom-file-label'>
              {props.filename}
            </label>
          </div>
        </div>
      </fieldset>
    </>
  );
}
export default CustomFileInput;
