import React from 'react'
// import CartItemSubTotal from './CartItemSubTotal'
import { BACKEND_URL } from '../../../../APIConfig'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import CustomLoader from '../../../ui/CustomLoader'
import Alert from '../../../ui/Alert'
import { API_DOMAIN } from '../../../../Config'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LabeledInput from '../../../../sections/ui/formfields/LabeledInput';

const authDataString = localStorage.getItem('user')
const authData = JSON.parse(authDataString)
const headers = authDataString?{
  Authorization: `Bearer ${authData && authData.access_token}`
}:{}
function CheckoutItem(props) {
  const {t, i18n} = useTranslation('common');
  const [suplement, setsuplement] = useState({})
  const [showApplyCoupon, setShowApplyCoupon] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const [customfield, setCustomField] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  useEffect(() => {
  let addedsupplements={};
  let supplement_selected=JSON.parse(JSON.stringify(props.ticket.selected_supplement));
   Object.keys(supplement_selected).forEach((key)=>{
    supplement_selected[key].forEach(item=>{
      if(Object.keys(addedsupplements).includes(item.id.toString()))
      {
        if((item.type==1 || item.type==3)&&item.value)
        {
          addedsupplements[item.id]["quantity"]+=1;
          if(item.type==3)
           addedsupplements[item.id]["values_other"].push(item.value)
        }
        
        if(item.type==2 && item.value.length!=0) 
        {
          item.value.forEach(newitem=>{
           let ind = addedsupplements[item.id]["value"].findIndex(val=>{
            return val.title==newitem.title;
          })
            if(ind!=-1)
            {
              addedsupplements[item.id]["value"][ind]["quantity"]+=1;
            }
            else
            {
              newitem["quantity"]=1;
              addedsupplements[item.id]["value"].push(newitem)
            }
          }) 
        }
      }
      else
      {
        if((item.type==1 || item.type==3)&&item.value)
        {
        item["quantity"]=1;
        if(item.type==3 && item.value)
          item["values_other"]=[item.value]
        else if(item.type==3)
          item["values_other"]=[]
        addedsupplements[item.id]=item
        }


        if(item.type==2 && item.value.length!=0) 
        {
          item.value.forEach(item=>{
            item["quantity"]=1;
          })
          addedsupplements[item.id]=item
        }
       
      }
    })
  })
  setsuplement(addedsupplements)
},[props])
  const applyCouponHandler = async ticketId => {
    if (!couponCode) {
      setError(t('EnterCouponCode'))
      return
    }
    setLoading(true)
    try {
      const { data } = await axios.post(
        `${API_DOMAIN}api/verify_coupon`,
        { ticket_id: ticketId, coupon_code: couponCode, ticket_qty: props.quantity,supplements:JSON.stringify(props.ticket.selected_supplement)},
        { headers }
      )
      if (data.status) {
        let coupondata = data.coupon_detail
        props.ticket["selected_supplement"]=JSON.parse(JSON.stringify(props.ticket.selected_supplement))
        props.couponApply(
          props.event.id,
          props.ticket.id,
          props.quantity,
          props.event,
          props.ticket,
          data.coupon_detail.id,
          coupondata
        )
        setSuccess(
          `Coupon Applied ${data.coupon_detail.coupon_code}, €${data.coupon_detail.amount} off`
        )
        setError('')
      } else {
        setError(t(data.message.replace(/ /g, '')))
      }
    } catch (error) {
      setError(t('SomethingWentWrong'))
    }
    if(props.coupon_code !== ""){
      setShowApplyCoupon(true);
    }
    setLoading(false)
  }

  const applyCouponHandler2 = async ticketId => {
    // if (!couponCode) {
    //   setError('Please enter a coupon code')
    //   return
    // }
    setLoading(true)
    props.couponApply(
      props.event.id,
      props.ticket.id,
      props.quantity,
      props.event,
      props.ticket,
      0
    )
    setLoading(false)
  }
  if(props.ticket.discountDetail !== null){
     //setShowApplyCoupon(true);
  }
  return (
    <div className='cart_item'>
      <div className='row no-gutters'>
        <div className='col-12 col-md-8'>
          <a href={props.isFrame?props.link.replace("event-details","event-frame-details"):props.link} style={props.isFrame?{color:props.urlColor}:{color:"#31c5f4"}}>
            <h6 className='title mb-2'>{props.title}</h6>
          </a>
          {/* <p className='dlist-inline small'>{props.option}</p> */}
          <figure className='media cart_item-section'>
            <div className='img-wrap my-auto'>
              <img
                src={BACKEND_URL + props.image}
                className='img-thumbnail img-sm2'
              />
            </div>
            <figcaption className='media-body my-auto '>
              <dl className='dlist-inline small'>
                <dt className='mr-2'>{t('When')}</dt>
                <dd>{props.when}</dd>
              </dl>
              <dl className='dlist-inline small'>
                <dt className='mr-2'>{t('Where')}</dt>
                <dd>{props.where}</dd>
              </dl>
              <dl className='dlist-inline small'>
                <dt className='mr-2'>{t('Header.Organizer')}</dt>
                <dd>{props.organizer}</dd>
              </dl>
              <dl className='dlist-inline small'>
                <dt className='mr-2'>{t('Header.TicketTitle')}</dt>
                <dd>{props.ticket.title}</dd>
              </dl>
            </figcaption>
          </figure>
          <div className='mt-4'>
            {loading && <CustomLoader />}
            {props.ticket.discountDetail === null ? 
            !loading && showApplyCoupon && !success && (
              <>
                <div className='row'>
                  <div className='col-md-6'>
                    <input
                      style={{ height: 30 }}
                      type='text'
                      placeholder='Coupon Code'
                      className='form-control'
                      value={couponCode}
                      onChange={e => setCouponCode(e.target.value)}
                    />
                  </div>
                  
                  <div className='col-md-6'>
                    <span
                      className='btn btn-primary btn-sm'
                      style={props.isFrame?{backgroundColor:props.buttonColor,borderColor:props.buttonColor,cursor: 'pointer',color:'white'}:{ cursor: 'pointer' }}
                      onClick={() => applyCouponHandler(props.ticketId)}
                    >
                      {t('Apply')}
                    </span>
                    <span
                      className='ml-3'
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => {
                        setError('')
                        setShowApplyCoupon(false)
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </div>
                </div>
               
                <div style={{ marginTop: 20 }}>
                  {error && <Alert type='error'>{error}</Alert>}
                </div>
              </>
            )
          : 
          (
            <>
              <div className='row'>
                <div className='col-md-6'>
                  <input
                    style={{ height: 30 }}
                    type='text'
                    placeholder='Coupon Code'
                    className='form-control'
                    value={props.ticket.discountDetail.coupon_applied}
                    onChange={e => setCouponCode(e.target.value)}
                  />
                </div>
                <div className='col-md-6'>
                  {/* <span
                    className='btn btn-primary btn-sm'
                    style={{ cursor: 'pointer' }}
                    onClick={() => applyCouponHandler(props.ticketId)}
                  >
                    Apply
                  </span> */}
                  <span
                    className='ml-3'
                    style={{ cursor: 'pointer', color: 'red' }}
                    onClick={() => applyCouponHandler2(props.ticketId)}
                    // onClick={() => {
                    //   setError('')
                    //   setShowApplyCoupon(false)
                    // }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                {error && <Alert type='error'>{error}</Alert>}
              </div>
            </>
          )
          }
            {success && <Alert type='success'>{success}</Alert>}
            {props.ticket.discountDetail === null ? 
            !loading && !showApplyCoupon && (
              <span
                className='btn btn-warning btn-sm'
                style={props.isFrame?{backgroundColor:props.buttonColor,borderColor:props.buttonColor,cursor: 'pointer',color:'white'}:{ cursor: 'pointer' }}
                onClick={() => setShowApplyCoupon(true)}
              >
                {t('HaveCoupon')}
              </span>
            )
          :
          <>
          </>}
          {props.event.custom_field_enabled==1 && props.event.custom_allow==true &&
          (<>
                 <div className='row' style={{marginTop:'20px'}}>
                  <div className='col-md-12'>
                  <LabeledInput
                type='text'
                label={props.event.custom_field_label}
                id='title'
                name='title'
                required={props.event.custom_field_required==1}
                placeholder={props.event.custom_field_label}
                onChange={e => {props.updateCustomValue(props.index,e.target.value)}}
              />
                  </div>
                  </div>
          </>)}
   
          </div>
        </div>
        <div className='col-12 col-md-4'>
          <div className='cart_item-sec-mobile d-flex justify-content-between shopping-cart-wrap align-items-center'>
            <div className='custom-flex price-wrap'>
              <span className='price-new'>
                <span className='custom-font text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                  {t('Price')}
                </span>
                €{props.price.toString().replace('.', ',')}
              </span>
            </div>
            <div style={props.isFrame?{textAlign:'center'}:{}} className='custom-flex'>
              <span className='custom-font text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                {t('Quantity')}
              </span>
              {props.quantity}
            </div>
            {/* <div>
              <span className='text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                Quantity
              </span>
              €{props.tax}
            </div> */}
            <div className='price-wrap custom-flex' style={props.isFrame?{marginTop:'5px'}:{}}>
              <span className='custom-font text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                {t('Subtotal')}
              </span>
              <var className='price'  style={props.isFrame?{color:props.urlColor}:{}}>
                {/* <CartItemSubTotal
                  quantity={props.quantity}
                  price={props.price}
                /> */}
                €{props.subTotal.toString().replace('.', ',')}
              </var>
            </div>
          </div>
          {props.ticket.freeticket!=0 && (<>
            <div className='cart_item-sec-mobile d-flex justify-content-between shopping-cart-wrap align-items-center'>
            <div className='custom-flex price-wrap'>
              <span className='price-new'>
                <span className='custom-font text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                  {t('Price')}
                </span>
                €0
              </span>
            </div>
            <div style={props.isFrame?{textAlign:'center'}:{}} className='custom-flex'>
              <span className='custom-font text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                {t('Quantity')}
              </span>
              {props.ticket.freeticket}
            </div>
            {/* <div>
              <span className='text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                Quantity
              </span>
              €{props.tax}
            </div> */}
            <div className='custom-flex price-wrap' style={props.isFrame?{marginTop:'5px'}:{}}>
              <span className='custom-font text-muted font-weight-bold cart_item-sec-mobile-label d-block d-md-none mb-2 mt-3'>
                {t('Subtotal')}
              </span>
              <var className='price'  style={props.isFrame?{color:props.urlColor}:{}}>
                {/* <CartItemSubTotal
                  quantity={props.quantity}
                  price={props.price}
                /> */}
                {t('Free')}
              </var>
            </div>
          </div>
          </>)}
         
        </div>
        <div className='table-responsive' style={{marginTop:'15px'}}>
        {
   Object.keys(suplement).length>0 &&<>
  
<table className='table table-hover table-condensed mb-1' >
<tbody className='my-body'>
  <tr
    style={{
      width: '100%',
      backgroundColor: props.isFrame?props.buttonColor:'#31c5f4',
      color: 'white'
    }}>
    <td className='my-row'>
      <h3 className='custom-font-size'>{t('TicketSuppliments')}</h3>
    </td>
  </tr>
</tbody>
</table>
<table className='table table-hover table-condensed mb-1'>
          <thead>
            <tr>
              <th className='border-top-white'>{t('SupplementTitle')}</th>
              <th>{t('TitleQty')}</th>
              <th>{t('Subtotal')}</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(suplement).map(item=>(
                <>
                {
                  (suplement[item].type==1 || suplement[item].type==3) &&
                  <tr className='bg-gray'>
                  <td className='border-top-white'>
                     <div style={{paddingLeft:'20px'}}>{suplement[item].title} {suplement[item].type==3? "( "+suplement[item].values_other.join(",") +" )":''}</div>
                   </td>
                  
                   <td className='border-top-white'>
                     <div style={{paddingLeft:'15px'}}>{suplement[item].quantity}</div>
                   </td>
                     <td className='border-top-white'>
                     <div style={{paddingLeft:'15px'}}>€{suplement[item].price*suplement[item].quantity}</div>
                   </td>
                 </tr>
                }
                 {
                  suplement[item].type==2 &&
                 suplement[item].value.map(newitem=>(
                  <tr className='bg-gray'>
                  <td className='border-top-white'>
                     <div style={{paddingLeft:'20px'}}>{suplement[item].title} ({newitem.title})</div>
                   </td>
                  
                   <td className='border-top-white'>
                     <div style={{paddingLeft:'15px'}}>{newitem.quantity}</div>
                   </td>
                     <td className='border-top-white'>
                     <div style={{paddingLeft:'15px'}}>€{newitem.price*newitem.quantity}</div>
                   </td>
                 </tr>
                 ))
                  
                }
                
                </>
               
              ))
            }
            
          </tbody>
        </table>
   </>
}
</div>
      </div>
    </div>
  )
}
export default CheckoutItem
