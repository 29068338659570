import { faPlus, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../../sections/ui/Alert';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { EVENTS_FILTER_API,SCAN_EVENT_ALL } from '../../../../APIConfig';
import CustomSelect from '../../../../sections/ui/formfields/CustomSelect';
import { useAlert } from 'react-alert';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
function ScanGraph(props)
{  
    const alert = useAlert();
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Scan Graph',
          },
        },
      };
  const authDataString = localStorage.getItem('user');
  const authData = JSON.parse(authDataString);
  const headers = {
    Authorization: `Bearer ${authData && authData.access_token}`
  };
      const initialFormData = {
      };
      const initialFilters = {
          sort_by: null,
          category_id: null,
          status: 2,
        };
      
      const [eventData, setEventData] = useState([]);
      const [graphData, setGraphData] = useState();
      const [filters, setFilters] = useState(initialFilters);
      const [currentPage, setCurrentPage] = useState(1);
      const [loading, setLoading] = useState(false);
      const [isEmpty, setisEmpty] = useState(false);
      const [reload, setReload] = useState(false);
      const [alleventData, setAllEventData] = useState([]);
      const [formData, setFormData] = useState(initialFormData);
      const {t, i18n} = useTranslation('common');
      const {
          event_id
        } = formData;
      useEffect(() => {
          const getEvents = async () => {
            try {
              setLoading(true);
              const headers = {
                Authorization: `Bearer ${authData && authData.access_token}`
              };
              const { data } = await axios.post(
                `${EVENTS_FILTER_API}?page=${currentPage}`,
                filters,
                {
                  headers
                }
              );
             setAllEventData(data);
             let catArray = data.myevents.data.map(cat => ({
              label: cat.title,
              value: cat.id
            }));
            setEventData(catArray);
              setLoading(false);
            } catch (err) {
              setLoading(false);
            }
          };
      
          getEvents();
        }, [filters, currentPage, reload]);
  
  
    const formSubmit = async e => {
      e.preventDefault();
      const userData = localStorage.getItem('user');
      const userDataObj = JSON.parse(userData);
      let token = userDataObj !== null && userDataObj.access_token;
      if (!token) {
        return;
      }
      if(!event_id)
      {
        alert.error(t('fillallfields'));
        return;
      }
      const dataForm = {
        ...formData,
        event_id:event_id.value
      };
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
   
      setLoading(true);
      try {
          const { data } = await axios.post(SCAN_EVENT_ALL, dataForm, { headers });
         let scandata=data.data;
         let graphlabels=[];
         let bookedlist=[];
         let bookedlistfree=[];
         let checkedlist=[];
         for(var rat=0;rat<scandata.length;rat++)
         {
          graphlabels.push(scandata[rat].ticket_name)
          if(scandata[rat].total_booked==null)
          bookedlist.push(0)
          else
          bookedlist.push(parseInt(scandata[rat].total_booked))

          if(scandata[rat].total_booked_free==null)
          bookedlistfree.push(0)
          else
          bookedlistfree.push(parseInt(scandata[rat].total_booked_free))

          if(scandata[rat].total_checked_in==null)
          checkedlist.push(0)
          else
          checkedlist.push(parseInt(scandata[rat].total_checked_in))
         }

       setGraphData({
        labels:graphlabels,
        datasets: [
          {
            label: 'Total Booked',
            data: bookedlist,
            backgroundColor: 'rgba(255, 206, 86, 1)',
          },
          {
            label: 'Total Booked Free',
            data: bookedlistfree,
            backgroundColor: 'rgba(246, 115, 6, 1)',
          },
          {
            label: 'Total Checked-In',
            data: checkedlist,
            backgroundColor: 'rgba(53, 162, 235, 1)',
          },
        ],
      })
  
      } catch (error) {
        const errors = error.response.data.errors;
        if (errors !== undefined) {
          alert.error(t('PleaseCheckAllfields'));
        } else {
          alert.error(t('FailedCreation'));
        }
      }
      setLoading(false);
    };
   
      return (
          <>
            <Breadcrumbs active='scan-detail' />
            <section className='section-content padding-y bg-white'>
              <div className='container'>
                <div className='row'>
                  <Sidebar active='scan-detail' />
                  <div className='col-lg-9 mt-4 mt-lg-0'>
                  <Card>
                  <header className='section-heading mb-3'>
                    <h4 className='title-section'>
                      <FontAwesomeIcon icon={faTags} className='fa-w-14 mr-2' />
                      {t('DashboardPage.ScanDetail')}
                    </h4>
                  </header> 
                  <form onSubmit={formSubmit}>
                  <div className='box shadow-none bg-gray mb-4'>
                    <div className='row'>
   
                      <div className='col-sm-12 col-md-12 d-flex align-items-end justify-content-center'>
                      <button
                   type='submit'
                    onClick={()=>{}}
                    name='submit'
                    className='btn btn-primary btn'>
                    {t('CategoryPage.Submit')}
                  </button>
                        </div>
                        </div>
                        </div> 
                 
                    <div className='row'>
                    
                    <div className='col-sm-6 col-lg-6'>
                    <CustomSelect
                                className='custom_select-div'
                                required={true}
                                label={t('MyEventPage.SelectEvent')}
                                options={eventData}
                                name='event_id'
                                isClearable={false}
                                isSearchable={false}
                                value={event_id}
                                handleChange={e => {
                                  setisEmpty(false);
                                  setFormData(state => ({
                                    ...state,
                                    event_id: e
                                  }));
                                }}
                              />
                    </div>
                   </div> 
                  
                  </form>
                  {loading ? (
                      <CustomLoader style={{ textAlign: 'center' }} />
                    ) : (
                      <> {graphData?.datasets && (
                             <div>
                             <Bar options={options} data={graphData} />
                           </div> 
                      )
                        }
                      </>
                    )}
                    </Card>
                  </div>
                  
                </div>
                
              </div>
            </section> 
          
          </>
        );
};
export default ScanGraph;