import React from 'react';
import SingleReview from '../../ui/Review/SingleReview';

export default function ReviewList(props) {
  return (
    <div>
      {props.reviews !== null ? (
        props.reviews.map(
          (data, key) =>
            data.user_detail && (
              <SingleReview
                key={data.id}
                userimage={data.user_detail && data.user_detail.avatar}
                userName={data.user_detail && data.user_detail.name}
                rating={data.review}
                description={data.description}
                CreatedDate={data.created_at}
              />
            )
        )
      ) : (
        <div></div>
      )}
    </div>
  );
}
