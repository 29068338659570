import { faPlus, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../../sections/ui/Alert';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { EVENTS_FILTER_API,EVENT_ALL_GET,EVENT_TICKETS_ALL } from '../../../../APIConfig';
import CustomSelect from '../../../../sections/ui/formfields/CustomSelect';
import { useAlert } from 'react-alert';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
//import Geocode from "react-geocode";
//Geocode.setApiKey("AIzaSyDjQt0s6dy2W-klX7H8hIqv_sjCv_t-boM");
ChartJS.register(ArcElement, Tooltip, Legend); 

function EventGraph(props)
{  
    const alert = useAlert();
  const authDataString = localStorage.getItem('user');
  const authData = JSON.parse(authDataString);
  const headers = {
    Authorization: `Bearer ${authData && authData.access_token}`
  };
      const initialFormData = {
      };
      const initialFilters = {
          sort_by: null,
          category_id: null,
          status: 2,
        };
  
      const [graphData, setGraphData] = useState();
      const [filters, setFilters] = useState(initialFilters);
      const [currentPage, setCurrentPage] = useState(1);
      const [loading, setLoading] = useState(false);
      const [isEmpty, setisEmpty] = useState(false);
      const [reload, setReload] = useState(false);
      const [formData, setFormData] = useState(initialFormData);
      const {t, i18n} = useTranslation('common');
      const [allticketData, setAllTicketData] = useState([]);
      const [alleventData, setAllEventData] = useState([]);
      const [eventData, setEventData] = useState([]);
      const [ticketData, setTicketData] = useState([]);
      const {
        event_id,
        ticket_id,
      } = formData;
      useEffect(() => {
        const getEvents = async () => {
          try {
            setLoading(true);
            const headers = {
              Authorization: `Bearer ${authData && authData.access_token}`
            };
            const { data } = await axios.post(
              `${EVENTS_FILTER_API}?page=${currentPage}`,
              filters,
              {
                headers
              }
            );
           setAllEventData(data);
           let catArray = data.myevents.data.map(cat => ({
            label: cat.title,
            value: cat.id
          }));
          setEventData(catArray);
            setLoading(false);
          
          } catch (err) {
            setLoading(false);
          }
        };
    
        getEvents();
        }, [filters, currentPage, reload]);

        const getTickets = async (id) => {
          try {
            const { data } = await axios.post(
              EVENT_TICKETS_ALL,
              { event_id: id },
              { headers }
            );
            setAllTicketData([]);
            if (data.status && data.tickets.length > 0) {
              setAllTicketData(data.tickets);
            }
            if(data && data.tickets)
            {
        let catArray =data.tickets.map(cat => ({
          label: cat.title,
          value: cat.id
        }));
        
        catArray.unshift({
          label: t('MyEventPage.AllTickets'),
          value: 0
        })
        setTicketData(catArray);
      }
        setFormData(state => ({
          ...state,
          ticket_id: {
            label: t('MyEventPage.AllTickets'),
            value: 0
          }
        }));
      } 
      catch (err) 
      {
      }
  };
  
    const formSubmit = async e => {
      e.preventDefault();
      const userData = localStorage.getItem('user');
      const userDataObj = JSON.parse(userData);
      let token = userDataObj !== null && userDataObj.access_token;
      if (!token) {
        return;
      }
      if(!event_id || !ticket_id)
      {
        alert.error(t('fillallfields'));
        return;
      }
      const dataForm = {
        ...formData,
        event_id:event_id.value,
        ticket_id:ticket_id.value
      };
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
   
      setLoading(true);
      try {
          const { data } = await axios.post(EVENT_ALL_GET, dataForm, { headers });
         let scandata=data.data;
         let graphlabels=[];
         let bookedlist=[];
         let backgroundColor=[];
         for(var rat=0;rat<scandata.length;rat++)
         {
        if(scandata[rat].postal_code)
        {
         // let postaldata = null
        //try{
        //postaldata = await Geocode.fromAddress(scandata[t].postal_code);
        graphlabels.push(scandata[rat].postal_code)
        bookedlist.push(parseInt(scandata[rat].total_booked))
        backgroundColor.push(randomrgba())
        //}
        //catch(e){}
        }
        }
       setGraphData({
        labels: graphlabels,
        datasets: [
          {
            label: "Regional Graph",
            data: bookedlist,
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            borderWidth: 1,
          },
        ],
      })
  
      } catch (error) {
        const errors = error.response.data.errors;
        if (errors !== undefined) {
          alert.error(t('PleaseCheckAllfields'));
        } else {
          alert.error(t('FailedCreation'));
        }
      }
      setLoading(false);
    };

    const randomrgba = ()=>
    {
var randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
var r = randomBetween(0, 255);
var g = randomBetween(0, 255);
var b = randomBetween(0, 255);
var rgba = `rgba(${r},${g},${b},1)`;
return rgba;
    }
   
      return (
          <>
            <Breadcrumbs active='region' />
            <section className='section-content padding-y bg-white'>
              <div className='container'>
                <div className='row'>
                  <Sidebar active='region' />
                  <div className='col-lg-9 mt-4 mt-lg-0'>
                <Card>
                <header className='section-heading mb-3'>
                  <h4 className='title-section'>
                    <FontAwesomeIcon icon={faTags} className='fa-w-14 mr-2' />
                    {t('DashboardPage.Region')}
                  </h4>
                </header> 
                <form onSubmit={formSubmit}>
                <div className='box shadow-none bg-gray mb-4'>
                  <div className='row'>
 
                    <div className='col-sm-12 col-md-12 d-flex align-items-end justify-content-center'>
                    <button
                 type='submit'
                  onClick={()=>{}}
                  name='submit'
                  className='btn btn-primary btn'>
                  {t('CategoryPage.Submit')}
                </button>
                      </div>
                      </div>
                      </div> 
               
                  <div className='row'>
                
                  <div className='col-sm-6 col-lg-6'>
                  <CustomSelect
                              className='custom_select-div'
                              required={true}
                              label={t('MyEventPage.SelectEvent')}
                              options={eventData}
                              name='event_id'
                              isClearable={false}
                              isSearchable={false}
                              value={event_id}
                              handleChange={e => {
                                setisEmpty(false);
                                getTickets(e.value)
                                setFormData(state => ({
                                  ...state,
                                  event_id: e
                                }));
                              }}
                            />
                  </div>
                  <div className='col-sm-6 col-lg-6'>
                  <CustomSelect
                              className='custom_select-div'
                              required={true}
                              label={t('MyEventPage.SelectTicket')}
                              options={ticketData}
                              name='ticket_id'
                              isClearable={false}
                              isSearchable={false}
                              value={ticket_id}
                              handleChange={e => {
                                setisEmpty(false);
                                setFormData(state => ({
                                  ...state,
                                  ticket_id: e
                                }));
                              }}
                            />
                      </div>
                 
                    
                 </div> 
                
                </form>
                {loading ? (
                    <CustomLoader style={{ textAlign: 'center' }} />
                  ) : (
                    <> {graphData?.datasets && (
                           <div >
                           <Doughnut data={graphData} width={480} height={480} options={{ maintainAspectRatio: false }} />
                         </div> 
                    )
                      }
                    </>
                  )}
                  </Card>
                </div>
                  
                </div>
                
              </div>
            </section> 
          
          </>
        );
};
export default EventGraph;