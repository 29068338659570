import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next"; 
function Popup(props) {
  const {t, i18n} = useTranslation('common')
  // useEffect(() => {
  //  document.querySelector("body").classList.toggle("overflow-hidden");
  // }, []);
  if (props.openModal) {
    return (
      <>
        {props.openModal ? (
          <div
            className="modal-backdrop fade show"
            onClick={props.closeModal}
          ></div>
        ) : (
          ""
        )}
        <div
          className={`modal fade ${props.openModal ? "show" : ""}`}
          style={{ display: props.openModal ? "block" : "none" }}
          onClick={props.closeModal}
        >
          <div
            className={`modal-dialog modal-dialog-centered ${props.className}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-content">
              {props.title ? (
                <div className="modal-header">
                  <h4 className="modal-title">{props.title}</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={props.closeModal}
                  >
                    ×
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="modal-body">{props.children}</div>
              {props.footer ? (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary custom-font"
                    style={props.isFrame?{backgroundColor:props.buttonColor,borderColor:props.buttonColor}:{}}
                    onClick={props.closeModal}
                  >
                    {t('EventDetailPage.CloseButton')}
                  </button>
                  {props.footer !== "close" ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={props.footerAction}
                    style={props.isFrame?{backgroundColor:props.buttonColor,borderColor:props.buttonColor}:{}}>
                      {props.footer}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return false;
  }
}
export default Popup;
