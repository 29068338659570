import { faPlus, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { DELETE_COUPON_API, GET_COUPONS_API } from '../../../../APIConfig';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../../sections/ui/Alert';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import Card from '../../../../sections/ui/Card';
// import CustomSelect from '../../../../sections/ui/formfields/CustomSelect'
import CouponForm from './CouponForm';
import CouponItem from './CouponItem';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useTranslation } from 'react-i18next';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import $ from 'jquery';
const authDataString = localStorage.getItem('user');
const authData = JSON.parse(authDataString);
const headers = {
  Authorization: `Bearer ${authData && authData.access_token}`
};

const Coupons = props => {
  const { t, i18n} = useTranslation('common');

  const [coupons, setCoupons] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [edit, setEdit] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [reloadCoupons, setReloadCoupons] = useState(false);

  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (foundUser.user_detail.role_id !== 3) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

  useEffect(() => {
    const fetchCoupons = async () => {
      setLoading(true);
      setReloadCoupons(false);
      try {
        const { data } = await axios.get(GET_COUPONS_API, { headers });
        if (data.success) {
          setCoupons(data.data);
        } else {
          setCoupons([]);
        }
      } catch (error) {

      }
      setLoading(false);
      setTimeout(()=>{
        if ( ! $.fn.DataTable.isDataTable('#table1')) {
        $('#table1').DataTable({
          "scrollX": true
      });}
      },5)
    };
    fetchCoupons();
  }, [reloadCoupons, axios]);

  const showAlert = (message, error) => {
    if (error === 'error') {
      setError(true);
      setMessage(message);
    } else if (error === 'success') {
      setReloadCoupons(true);
      setShowAddForm(false);
      setError(false);
      setMessage(message);
    }
  };
  const showEdit = coupon => {
    setEdit(true);
    let couponTypeObj = { label: '', value: 1 };
    if (coupon.coupon_type === 1) {
      couponTypeObj = { label: t('FixedDiscount'), value: 1 };
    } else if (coupon.coupon_type === 2) {
      couponTypeObj = { label: t('%Discount'), value: 2 };
    } 
    let statusObj = { label: t('Active'), value: 1 };
    if (coupon.status === 2) {
      statusObj = { label: t('Inactive'), value: 2 };
    }

    let fixedobject = {
      label: 'Discount Per Ticket',
      value: 0
    }
    if (coupon.coupon_fixed_type === 1) {
      fixedobject ={
        label: 'Fixed Discount',
        value: 1
      }
    }

    let couponapplyobject={
      label: 'Tickets Only',
      value: 0
    }

    if (coupon.coupon_apply_type === 1) {
      couponapplyobject ={
        label: 'Supplements Only',
        value: 1
      }
    }

    else if(coupon.coupon_apply_type === 2)
    {
      couponapplyobject ={
        label: 'Tickets And Supplements',
        value: 2
      }
    }
    
    const couponData = {
      ...coupon,
      coupon_id: coupon.id,
      coupon_type: couponTypeObj,
      coupon_fixed_type:fixedobject,
      coupon_apply_type:couponapplyobject,
      start_date: new Date(coupon.start_date),
      end_date: new Date(coupon.end_date),
      status: statusObj
    };

    setCoupon(couponData);
    setShowAddForm(true);
  };

  // delete coupon
  const deleteCoupon = async id => {
    if (window.confirm(t('DeleteThisCoupon'))) {
      try {
        await axios.post(DELETE_COUPON_API, { id: id }, { headers });
        showAlert(t('CouponDeleteSuccessfully'), 'success');
      } catch (error) {
        console.error(error.response.message);
        showAlert(
          t('CouponNotDelete'),
          'error'
        );
      }
    }
    // setReloadCoupons(true)
  };
  return (
    <>
      <Breadcrumbs active='coupons' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='coupons' />
            <div className='col-lg-9'>
              <Card>
                <header className='section-heading mb-3'>
                  <h4 className='title-section'>
                    <FontAwesomeIcon icon={faTags} className='fa-w-14 mr-2' />
                    {t('CouponPage.PageTitle')}
                  </h4>
                </header>
                {message && (
                  <div
                    className={`alert alert-${error ? 'danger' : 'success'}`}>
                    {message}
                  </div>
                )}

                
                <div className='box shadow-none bg-gray mb-4'>
                  <div className='row'>
                    {/* <div className='col-sm-12 col-md-3 mb-3 mb-lg-0'>
                      <span className='center-lg-y text-muted'>
                        {coupons.length} {t('CouponPage.ResultsFound')}
                      </span>
                    </div> */}
 
                    <div className='col-sm-12 col-md-12 d-flex align-items-end justify-content-center'>
                      {!showAddForm && (
                        <button
                          onClick={() => {
                            setShowAddForm(prevState => !prevState);
                          
                          }}
                          className='btn btn-primary ml-3'>
                          <FontAwesomeIcon icon={faPlus} className='mr-2' /> {t('CouponPage.AddNewButton')}
                        </button>
                      )}
                      {showAddForm && (
                        <button
                          onClick={() => {
                            setCoupon(null);
                            setEdit(false);
                            setShowAddForm(prevState => !prevState);
                            setTimeout(()=>{
                              if ( ! $.fn.DataTable.isDataTable('#table1')) {
                              $('#table1').DataTable({
                                "scrollX": true
                            });}
                            },5)
                          }}
                          className='btn btn-success ml-3'>
                          {t('CouponPage.CancelButton')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {showAddForm && (
                  <div className='box shadow-none bg-gray mb-4'>
                    <div className='row'>
                      <CouponForm
                        edit={edit}
                        showAlert={showAlert}
                        coupon={coupon}
                      />
                    </div>
                  </div>
                )}

                {loading && <CustomLoader style={{ textAlign: 'center' }} />}
                {!loading && coupons.length <= 0 && (
                  <Alert>{t('NoCouponfound')}</Alert>
                )}
                {!loading && coupons.length > 0 && !showAddForm && (
                  <div>
   <Table id='table1' className='table table-responsive-md table-striped custom-table'>
                    <Thead className='thead-light'>
                      <Tr>
                        <Th>{t('CouponPage.CouponCode')}</Th>
                        <Th>{t('CouponPage.Title')}</Th>
                        <Th>{t('CouponPage.Type')}</Th>
                        <Th>{t('CouponPage.Amount')}</Th>
                        <Th>{t('CouponPage.TotalUsed')}</Th>
                        <Th>{t('CouponPage.TotalTicketUsed')}</Th>
                        {/* <Th>{t('CouponPage.MinimumAmount')}</Th>
                        <Th>{t('CouponPage.MaximumAmount')}</Th>
                        <Th>{t('CouponPage.UsageLimit')}</Th>
                        <Th>{t('CouponPage.TicketLimit')}</Th> */}
                        <Th>{t('CouponPage.StartDate')}</Th>
                        {/* <Th>{t('CouponPage.EndDate')}</Th> */}
                        <Th>{t('CouponPage.Status')}</Th>
                        <Th>{t('CouponPage.Actions')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {coupons.map(coupon => (
                        <CouponItem
                          showEdit={showEdit}
                          coupon={coupon}
                          key={coupon.id}
                          deleteCoupon={deleteCoupon}
                        />
                      ))}
                    </Tbody>
                  </Table>
                  </div>
               
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Coupons;
